import React, { useState } from 'react';
import {
  Box,
  Flex,
  VStack,
  HStack,
  Text,
  IconButton,
  useColorModeValue,
  Button,
  Portal,
  Heading,
  Icon,
  Image,
  Avatar,
  Input,
  Checkbox,
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { CreateTourFormData } from './tour-drawer';
import TourForm from './tour-form';
import { Map as MapIcon, Home, MessageCircle, Share2, MapPin, LogOut, Users, Send, ArrowRight, Camera, Compass } from 'lucide-react';
import { format, addDays } from 'date-fns';
import { isDarkColor } from '../helpers/color-util';
import { RadarMapEmbed, type Place } from './radar-map-embed';
import { useGetMediaQuery, useCreateTourMutation, useEditTourMutation } from '../graphql/generated';
import Banner from './shared/banner';

interface TourCanvasProps {
  isOpen: boolean;
  onClose: () => void;
  initialData?: CreateTourFormData;
  isEditing?: boolean;
  locationsData: any;
  locationsLoading: boolean;
  isPremiumUser: boolean;
}

type SelectedLocation = Place & {
  title?: string;
  description?: string;
};

const TourCanvas: React.FC<TourCanvasProps> = ({
  isOpen,
  onClose,
  initialData,
  isEditing = false,
  locationsData,
  locationsLoading,
  isPremiumUser,
}) => {
  const [formData, setFormData] = useState<CreateTourFormData>(initialData || {
    id: undefined,
    title: "",
    description: "",
    slug: "",
    color: "#6B46C1",
    startDate: format(new Date(), "yyyy-MM-dd'T'HH:mm"),
    endDate: format(addDays(new Date(), 1), "yyyy-MM-dd'T'HH:mm"),
    address: "",
    radius: 100,
    paymentFee: 0,
    isPublic: false,
    allowReviews: true,
    allowRetries: true,
    allowInvites: true,
    locations: [],
    backgroundMediaId: undefined,
    logoMediaId: undefined,
    image: "",
  });

  const [activeView, setActiveView] = useState<'map' | 'list' | 'message' | 'invite'>('map');
  const [selectedLocation, setSelectedLocation] = useState<SelectedLocation | null>(null);
  const [errors, setErrors] = useState<Partial<CreateTourFormData>>({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [previewPage, setPreviewPage] = useState<'join' | 'app' | 'results'>('app');
  const [createTour] = useCreateTourMutation();
  const [editTour] = useEditTourMutation();
  const [showBanner, setShowBanner] = useState(false);
  const [bannerProps, setBannerProps] = useState<{
    type: "success" | "error" | "info";
    title: string;
    message: string;
  }>({
    type: "success",
    title: "",
    message: ""
  });

  const getChangedFields = (
    current: CreateTourFormData,
    initial: CreateTourFormData
  ): Partial<CreateTourFormData> => {
    const changes: any = {};
    const fieldsToCompare: (keyof CreateTourFormData)[] = [
      'title',
      'description',
      'backgroundMediaId',
      'logoMediaId',
      'paymentFee',
      'slug',
      'image',
      'color',
      'startDate',
      'endDate',
      'address',
      'radius',
      'isPublic',
      'allowReviews',
      'allowRetries',
      'allowInvites',
      'locations'
    ];

    fieldsToCompare.forEach((key) => {
      if (JSON.stringify(current[key]) !== JSON.stringify(initial[key])) {
        changes[key] = current[key];
      }
    });

    return changes;
  };

  const handleSubmit = async () => {
    try {
      const inputData = {
        id: formData.id,
        title: formData.title,
        description: formData.description,
        backgroundMediaId: formData.backgroundMediaId,
        logoMediaId: formData.logoMediaId,
        slug: formData.slug,
        color: formData.color,
        startDate: new Date(formData.startDate).toISOString(),
        endDate: new Date(formData.endDate).toISOString(),
        address: formData.address || "Virtual",
        radius: formData.radius || 0,
        paymentFee: formData.paymentFee || 0,
        isPublic: formData.isPublic,
        allowReviews: formData.allowReviews,
        allowRetries: formData.allowRetries,
        allowInvites: formData.allowInvites,
        locations: formData.locations
      };

      if (isEditing) {
        const defaultInitialData = {
          id: undefined,
          title: "",
          description: "",
          slug: "",
          color: "#6B46C1",
          startDate: format(new Date(), "yyyy-MM-dd'T'HH:mm"),
          endDate: format(addDays(new Date(), 1), "yyyy-MM-dd'T'HH:mm"),
          address: "",
          radius: 100,
          paymentFee: 0,
          isPublic: false,
          allowReviews: true,
          allowRetries: true,
          allowInvites: true,
          locations: [],
          backgroundMediaId: undefined,
          logoMediaId: undefined,
          image: "",
        };
        const changedFields = getChangedFields(inputData, initialData || defaultInitialData);
        if (Object.keys(changedFields).length === 0) {
          setBannerProps({
            type: "info",
            title: "No Changes",
            message: "No changes were made to the tour"
          });
          setShowBanner(true);
          return;
        }
        const { data } = await editTour({
          variables: {
            input: {
              id: formData.id,
              ...changedFields,
            }
          },
        });
        if (data?.registerTour) {
          setBannerProps({
            type: "success",
            title: "Success",
            message: "Tour has been updated successfully"
          });
          setShowBanner(true);
        }
      } else {
        const { data } = await createTour({
          variables: { input: inputData },
        });
        if (data?.registerTour) {
          setBannerProps({
            type: "success",
            title: "Success",
            message: "New tour has been created successfully"
          });
          setShowBanner(true);
        }
      }

      onClose();
    } catch (error) {
      console.error('Error submitting form:', error);
      setBannerProps({
        type: "error",
        title: "Error",
        message: error instanceof Error ? error.message : "An error occurred while saving the tour"
      });
      setShowBanner(true);
    }
  };

  const { data: logoData } = useGetMediaQuery({
    variables: { id: formData.logoMediaId || '' },
    skip: !formData.logoMediaId
  });

  const bgColor = useColorModeValue('gray.50', 'gray.900');
  const previewBg = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const headerBg = useColorModeValue('white', 'gray.800');

  if (!isOpen) return null;


  const renderMapView = () => {
    const validCoordinates = locationsData?.locations
      .filter((c: any) => formData.locations.includes(c.id))
      .map((location: any) => ({
        latitude: location.latitude,
        longitude: location.longitude,
        name: location.title,
        address: location.address,
        description: location.description,
        ...location
      }))
      .filter((coords: any) => coords.latitude && coords.longitude);

    const firstLocation = validCoordinates?.[0];

    return (
      <Box position="relative" h="100%" bg="gray.50">
        <Box 
          position="absolute" 
          top={0} 
          left={0} 
          right={0} 
          bottom={50} // Leave more space for location card
          borderRadius="lg"
          overflow="hidden"
          border="1px solid"
          borderColor="gray.200"
        >
          <RadarMapEmbed
            initialLocation={firstLocation ? { lat: firstLocation.latitude, lng: firstLocation.longitude } : undefined}
            onSelectPlace={(place: Place) => {
              setSelectedLocation({
                ...place,
                title: place.name
              });
            }}
            mode="detail"
          />
        </Box>

        {/* Location Card */}
        {selectedLocation && (
          <Box
            position="absolute"
            bottom={90} // Adjust to be above nav items
            left={4}
            right={4}
            bg="white"
            borderTopRadius="xl"
            boxShadow="xl"
            p={4}
            zIndex={1}
          >
            <VStack align="stretch" spacing={2}>
              <HStack spacing={4}>
                <Box
                  w="40px"
                  h="40px"
                  borderRadius="full"
                  bg={`${formData.color}15`}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Icon as={MapPin} color={formData.color} boxSize={6} />
                </Box>
                <VStack align="start" spacing={0}>
                  <Text fontWeight="bold" fontSize="lg">{selectedLocation.title || selectedLocation.name}</Text>
                </VStack>
              </HStack>
              
              {selectedLocation.description && (
                <Text fontSize="sm" color="gray.800" noOfLines={2}>
                  {selectedLocation.description}
                </Text>
              )}

              <HStack spacing={2} color="gray.800">
                <Icon as={MapPin} boxSize={4} />
                <Text fontSize="sm" noOfLines={1}>{selectedLocation.address}</Text>
              </HStack>

              <HStack spacing={2} w="100%">
                <Button
                  size="sm"
                  flex={1}
                  variant="outline"
                  style={{
                    borderColor: formData.color,
                    color: formData.color,
                    fontSize: '12px'
                  }}
                  leftIcon={<Compass size={16} />}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  Directions
                </Button>
                <Button
                  size="sm"
                  flex={1}
                  style={{ backgroundColor: formData.color, fontSize: '12px' }}
                  color="white"
                  leftIcon={<Camera size={16} />}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  Take Photos
                </Button>
              </HStack>
            </VStack>
          </Box>
        )}
      </Box>
    );
  };

  const renderLocationsView = () => {
    const selectedLocations = locationsData?.locations
      .filter((c: any) => formData.locations.includes(c.id));

    if (!selectedLocations?.length) {
      return (
        <VStack 
          spacing={4} 
          p={4} 
          align="center" 
          justify="center" 
          h="100%"
          bg="white"
        >
          <Icon as={MapPin} boxSize={12} color={formData.color} opacity={0.5} />
          <VStack spacing={2}>
            <Text fontSize="lg" fontWeight="semibold" color="gray.800" textAlign="center">
              No Locations Added Yet
            </Text>
            <Text fontSize="sm" color="gray.600" textAlign="center">
              Add some delicious spots to your food tour to get started
            </Text>
          </VStack>
        </VStack>
      );
    }

    return (
      <VStack spacing={4} p={4} align="stretch" maxH="calc(100vh - 50px)" overflowY="auto">
        {selectedLocations.map((location: any) => (
          <Box
            key={location.id}
            w="100%"
            borderWidth="1px"
            borderColor={formData.color}
            borderRadius="lg"
            overflow="hidden"
            bg="white"
            cursor="pointer"
            onClick={() => {
              setSelectedLocation(location);
              setActiveView('map');
            }}
            _hover={{ transform: 'scale(1.02)', shadow: 'md' }}
            transition="all 0.2s"
            position="relative"
          >
            <Box p={4}>
              <HStack spacing={4} mb={2}>
                <Box
                  w="40px"
                  h="40px"
                  borderRadius="full"
                  bg={`${formData.color}15`}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Icon as={MapPin} color={formData.color} boxSize={6} />
                </Box>
                <VStack align="start" spacing={0}>
                  <Text fontWeight="bold" fontSize="lg">{location.title}</Text>
                </VStack>
              </HStack>
              
              {location.description && (
                <Text fontSize="sm" color="gray.800" noOfLines={2}>
                  {location.description}
                </Text>
              )}

              <VStack align="stretch" spacing={2} mb={4}>
                <HStack spacing={2} color="gray.800">
                  <Icon as={MapPin} boxSize={4} />
                  <Text fontSize="sm" noOfLines={1}>{location.address}</Text>
                </HStack>
              </VStack>

              <HStack spacing={2}>
                <Button
                  size="sm"
                  variant="outline"
                  flex="1"
                  fontWeight="medium"
                  style={{
                    borderColor: formData.color,
                    color: formData.color,
                    backgroundColor: `${formData.color}10`
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedLocation(location);
                    setActiveView('map');
                  }}
                >
                  View Details
                </Button>
              </HStack>
            </Box>
          </Box>
        ))}
      </VStack>
    );
  };

  const renderChatView = () => (
    <VStack spacing={4} align="stretch" h="100%" w="100%" bg="gray.50">
      {/* Chat Messages */}
      <VStack spacing={4} align="stretch" flex={1} overflowY="auto" p={2}>
        {/* Creator Message */}
        <HStack alignSelf="flex-start" maxW="100%">
          <Avatar size="sm" name="Tour Creator" bg={formData.color} color="white" />
          <Box bg="white" p={3} borderRadius="lg" shadow="sm">
            <Text fontSize="sm" fontWeight="medium" color="black">
               Welcome to {formData.title || "the tour"}! Let me know if you have any questions.
            </Text>
            <Text fontSize="xs" color="gray.600" mt={1}>
              10:30 AM
            </Text>
          </Box>
        </HStack>

        {/* Participant Message */}
        <HStack alignSelf="flex-end" maxW="100%">
          <Box bg={formData.color} p={3} borderRadius="lg" shadow="sm">
            <Text fontSize="sm" fontWeight="medium" color="white">
              Thanks! I'm excited to explore all the locations.
            </Text>
            <Text fontSize="xs" color="whiteAlpha.800" mt={1}>
              10:32 AM
            </Text>
          </Box>
        </HStack>

        {/* Creator Message */}
        <HStack alignSelf="flex-start" maxW="100%">
          <Avatar size="sm" name="Tour Creator" bg={formData.color} color="white" />
          <Box bg="white" p={3} borderRadius="lg" shadow="sm">
            <Text fontSize="sm" fontWeight="medium" color="black">
              Great! Don't forget to take photos at each stop. Have fun! 📸
            </Text>
            <Text fontSize="xs" color="gray.500" mt={1}>
              10:33 AM
            </Text>
          </Box>
        </HStack>
      </VStack>

      {/* Message Input */}
      <HStack p={4} bg="white" w="100%" borderTop="1px" borderColor="gray.200" mb={isEditing ? 10 : 8}>
        <Box
          flex={1}
          p={3}
          borderRadius="full"
          bg="gray.100"
          color="gray.500"
          fontSize="sm"
          maxW="100%"
        >
          Type a message...
        </Box>
        <IconButton
          aria-label="Send message"
          icon={<Icon as={MessageCircle} />}
          colorScheme="gray"
          variant="ghost"
          mt={2}
          isDisabled
        />
      </HStack>
    </VStack>
  );

  const renderShareView = () => (
    <><VStack spacing={4} p={4} align="stretch" maxH="calc(100vh - 200px)" overflowY="auto">
          <Box
              borderWidth="2px"
              borderColor="gray.200"
              borderRadius="lg"
              overflow="hidden"
              shadow="lg"
          >
              {/* Header */}
              <Box
                  p={4}
                  style={{
                      backgroundColor: formData.color,
                      color: isDarkColor(formData.color) ? 'white' : 'black',
                  }}
                  borderTopRadius="lg"
              >
                  <HStack spacing={2}>
                      <Icon as={Users} boxSize={6} />
                      <Text fontSize="lg" fontWeight="bold">Invite Friends</Text>
                  </HStack>
              </Box>

              {/* Content */}
              <Box p={4} space-y={4}>
                  <Text fontSize="sm" color="gray.600" mb={4}>
                      Share the delicious adventure with your friends! Invite them to join the food tour.
                  </Text>

                  {/* Email Invite Section */}
                  <VStack align="stretch" spacing={2} mb={4}>
                      <Text fontSize="sm" fontWeight="medium" color="gray.700">
                          Send Email Invite
                      </Text>
                      <HStack spacing={2}>
                          <Input
                              type="email"
                              placeholder="Enter email address..."
                              flex="1"
                              size="md"
                              borderColor="gray.300"
                              isDisabled />
                          <Button
                              style={{
                                  backgroundColor: formData.color,
                                  color: isDarkColor(formData.color) ? 'white' : 'black'
                              }}
                              leftIcon={<Icon as={Send} boxSize={4} />}
                          >
                              Send
                          </Button>
                      </HStack>
                  </VStack>

                  {/* Share Link Section */}
                  <VStack align="stretch" spacing={2}>
                      <Text fontSize="sm" fontWeight="medium" color="gray.700">
                          Share Link
                      </Text>
                      <Button
                          variant="outline"
                          w="100%"
                          style={{ borderColor: formData.color, color: formData.color }}
                          leftIcon={<Icon as={Share2} boxSize={4} />}
                      >
                          Generate Share Link
                      </Button>
                  </VStack>
              </Box>
          </Box>

          {/* Share memories text */}

      </VStack>
     </>
  );

  const navItems = [
    { tab: 'map', icon: MapIcon, label: 'Map' },
    { tab: 'list', icon: Home, label: 'Locations' },
    { tab: 'message', icon: MessageCircle, label: 'Chat' },
  ];

  if (formData.allowInvites) {
    navItems.push({ tab: 'invite', icon: Share2, label: 'Share' });
  }

  const renderContent = () => {
    switch (activeView) {
      case 'map':
        return renderMapView();
      case 'list':
        return renderLocationsView();
      case 'message':
        return renderChatView();
      case 'invite':
        return renderShareView();
      default:
        return null;
    }
  };

  const renderJoinPage = () => (
    <div className="h-full w-full flex flex-col">
      {/* Header */}
      <Box
        style={{ backgroundColor: formData.color }}
        className="flex-none w-full shadow-sm backdrop-blur-lg"
        p={4}
      >
        <HStack spacing={2} mt={6}>
          {logoData?.media && (
            <Image
              src={logoData.media}
              alt="Tour Logo"
              boxSize="32px"
              borderRadius="full"
            />
          )}
          <Text
            fontSize="lg"
            fontWeight="bold"
            align={logoData?.media ? "center" : "left"}
            color={isDarkColor(formData.color) ? "white" : "black"}
          >
            {formData.title || "Join Tour"}
          </Text>
        </HStack>
      </Box>

      {/* Main Content */}
      <Box flex="1" overflowY="auto" p={4} bg="gray.50">
        <Box h={12}/>
        <VStack spacing={4}>
          {/* Tour Alert */}
          <Box
            w="full"
            p={4}
            mb={-4}
            borderRadius="xl"
            style={{
              backgroundColor: `${formData.color}10`,
              borderColor: `${formData.color}20`,
              borderTopLeftRadius: '20px',
              borderTopRightRadius: '20px',
              borderBottomLeftRadius: '0px',
              borderBottomRightRadius: '0px',
            }}
          >
            <VStack spacing={2} align="center">
              <Text fontSize="sm" fontWeight="semibold" textAlign="center">
                Begin your adventure with {formData.title || "the tour"}!
              </Text>
            </VStack>
          </Box>

          {/* Form */}
          <Box
            w="full"
            bg="white"
            p={4}
            style={{
              borderTopLeftRadius: '0px',
              borderTopRightRadius: '0px',
              borderBottomLeftRadius: '20px',
              borderBottomRightRadius: '20px',
            }}
            mb={28}
            shadow="sm"
            border="1px"
            borderColor="gray.100"
          >
            <VStack spacing={4} >
              <Box w="full">
                <Text fontSize="sm" fontWeight="medium" mb={2}>
                  Email
                </Text>
                <Input
                  placeholder="your@email.com"
                  bg="gray.50"
                  isDisabled
                />
              </Box>
              
              <HStack w="full" spacing={3} p={4} bg="gray.50" borderRadius="md">
                <Checkbox isDisabled defaultChecked />
                <Text fontSize="sm" color="gray.800">
                  Receive email updates about this tour
                </Text>
              </HStack>
              <Button
                w="full"
                h="12"
                style={{
                  backgroundColor: formData.color,
                  color: isDarkColor(formData.color) ? "white" : "black"
                }}
                isDisabled
              >
                <HStack spacing={2}>
                  <Text>Join</Text>
                  <ArrowRight />
                </HStack>
              </Button>
            </VStack>
          </Box>
        </VStack>
      </Box>
    </div>
  );

  const renderPreviewContent = () => {
    if (previewPage === 'join') {
      return renderJoinPage();
    }
    
    // Default app view
    return (
      <>
        {/* Header */}
        <Box
          style={{ backgroundColor: formData.color || '#6B46C1' }}
          className="sticky top-0 z-10 shadow-sm backdrop-blur-lg"
        >
          <Box py={3} px={4}>
            <Flex justify="space-between" align="center">
              <HStack spacing={3}>
                {formData.logoMediaId && (
                  <Image
                    src={logoData?.media}
                    alt="Tour Logo"
                    mt={4}
                    boxSize="28px"
                    borderRadius="full"
                    objectFit="cover"
                  />
                )}
                <Text
                  mt={4}
                  mb={0}
                  align={formData.logoMediaId ? "center" : "left"}
                  fontSize="md"
                  fontWeight="bold"
                  color={isDarkColor(formData.color || '#6B46C1') ? "white" : "black"}
                >
                  {formData.title || "Your Tour Title"}
                </Text>
              </HStack>
              <IconButton
                aria-label="Logout"
                icon={<LogOut size={16} />}
                mt={4}
                variant="ghost"
                style={{ color: isDarkColor(formData.color || '#6B46C1') ? "white" : "black" }}
                onClick={() => {
                  return;
                }}
              />
            </Flex>
          </Box>
        </Box>

        {/* Main Content Area */}
        <Box
          position="relative"
          h="calc(100% - 120px)"
          overflow="hidden"
        >
          {renderContent()}
        </Box>

        {/* Bottom Navigation */}
        <HStack
          position="absolute"
          bottom={0}
          left={0}
          right={0}
          justify="space-around"
          p={3}
          style={{
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            backdropFilter: 'blur(8px)',
          }}
          borderTop="1px solid"
          borderColor="gray.200"
          shadow="lg"
        >
          {navItems.map(({ tab, icon: TabIcon, label }) => (
            <VStack
              key={tab}
              spacing={0.5}
              cursor="pointer"
              onClick={() => setActiveView(tab as any)}
              color={activeView === tab ? formData.color : 'gray.800'}
              style={{
                backgroundColor: activeView === tab ? 'rgba(0, 0, 0, 0.05)' : 'transparent'
              }}
              p={2}
              borderRadius="md"
              position="relative"
              width="25%"
            >
              <Box position="relative">
                <TabIcon size={20} />
              </Box>
              <Text fontSize="xs">{label}</Text>
            </VStack>
          ))}
        </HStack>
      </>
    );
  };

  return (
    <Portal>
      <Banner
        isVisible={showBanner}
        onClose={() => setShowBanner(false)}
        title={bannerProps.title}
        message={bannerProps.message}
        type={bannerProps.type}
      />
      <Box
        position="fixed"
        top={0}
        left={0}
        right={0}
        bottom={0}
        bg={bgColor}
        zIndex={1400}
        overflow="hidden"
      >
        {/* Header */}
        <Flex
          justify="space-between"
          align="center"
          px={6}
          py={4}
          borderBottom="1px solid"
          borderColor={borderColor}
          bg={headerBg}
        >
          <HStack spacing={4}>
            <IconButton
              aria-label="Back"
              icon={<ChevronLeftIcon boxSize={6} />}
              variant="ghost"
              onClick={onClose}
            />
            <Heading size="md">
              {isEditing ? "Update Tour" : "Register New Tour"}
            </Heading>
          </HStack>
          <HStack spacing={4}>
            <Button
              bg={formData.color}
              color={isDarkColor(formData.color) ? "white" : "black"}
              isDisabled={!isFormValid}
              onClick={handleSubmit}
              borderRadius="md"
              size="lg"
              fontWeight="bold"
              boxShadow="0px 4px 10px rgba(0, 0, 0, 0.15)"
              _hover={{
                bg: "#4a00f7",
                transform: "translateY(-2px)",
                boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.2)"
              }}
              _active={{
                bg: "purple.700",
                transform: "translateY(1px)"
              }}
            >
              Publish 🚀 
            </Button>
             
          </HStack>
        </Flex>

        {/* Main Content */}
        <Flex h="calc(100vh - 73px)" overflow="hidden">
          {/* Left Panel - Form */}
          <Box
            flex="1"
            p={4}
            mt={2}
            overflowY="auto"
            borderRight="1px solid"
            borderColor={borderColor}
          >
            <TourForm
              formData={formData}
              setFormData={setFormData}
              errors={errors}
              setErrors={setErrors}
              locationsData={locationsData}
              locationsLoading={locationsLoading}
              setIsFormValid={setIsFormValid}
              isPremiumUser={isPremiumUser}
              isEditing={isEditing}
            />
          </Box>

          {/* Right Panel - Preview */}
          <Box
            w="420px"
            p={6}
            bg={previewBg}
            overflowY="auto"
          >
            <VStack spacing={6} align="stretch">
              <Text fontSize="lg" fontWeight="bold">Preview</Text>
              
              {/* iPhone Frame with Navigation Buttons */}
              <Box position="relative">
                <Box
                  mx="auto"
                  w="320px"
                  h="650px"
                  bg="black"
                  borderRadius="40px"
                  position="relative"
                  padding="12px"
                  boxShadow="xl"
                >
                  {/* Notch */}
                  <Box
                    position="absolute"
                    top={2}
                    left="50%"
                    transform="translateX(-50%)"
                    w="150px"
                    h="25px"
                    bg="black"
                    borderBottomRadius="20px"
                    zIndex={2}
                  />
                  
                  {/* Screen */}
                  <Box
                    w="100%"
                    h="100%"
                    bg="white"
                    borderRadius="30px"
                    overflow="hidden"
                    position="relative"
                  >
                    {renderPreviewContent()}
                  </Box>
                </Box>

                {/* Navigation Controls */}
                <br/>
                <HStack 
                  position="absolute" 
                  bottom="-12" 
                  left="50%" 
                  transform="translateX(-50%)"
                  spacing={4}
                  bg="white"
                  p={2}
                  px={4}
                  borderRadius="full"
                  boxShadow="0px 4px 12px rgba(0, 0, 0, 0.08)"
                  border="1px solid"
                  borderColor="gray.100"
                  style={{
                    backdropFilter: 'blur(8px)',
                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                  }}
                >
                  <IconButton
                    aria-label="Previous"
                    icon={<ChevronLeftIcon boxSize={5} />}
                    variant="ghost"
                    size="sm"
                    color={formData.color}
                    onClick={() => {
                      const pages: ('join' | 'app' | 'results')[] = ['join', 'app', 'results'];
                      const currentIndex = pages.indexOf(previewPage);
                      const prevIndex = (currentIndex - 1 + pages.length) % pages.length;
                      setPreviewPage(pages[prevIndex]);
                    }}
                    _hover={{ bg: `${formData.color}10` }}
                    style={{
                      transition: 'all 0.2s',
                    }}
                  />
                  <HStack spacing={1}>
                    {['join', 'app', 'results'].map((page) => (
                      <Box
                        key={page}
                        w="6px"
                        h="6px"
                        borderRadius="full"
                        bg={previewPage === page ? formData.color : 'gray.200'}
                        transition="all 0.2s"
                        cursor="pointer"
                        onClick={() => setPreviewPage(page as any)}
                        _hover={{ transform: 'scale(1.2)' }}
                      />
                    ))}
                  </HStack>
                  <IconButton
                    aria-label="Next"
                    icon={<ChevronRightIcon boxSize={5} />}
                    variant="ghost"
                    size="sm"
                    color={formData.color}
                    onClick={() => {
                      const pages: ('join' | 'app' | 'results')[] = ['join', 'app', 'results'];
                      const currentIndex = pages.indexOf(previewPage);
                      const nextIndex = (currentIndex + 1) % pages.length;
                      setPreviewPage(pages[nextIndex]);
                    }}
                    _hover={{ bg: `${formData.color}10` }}
                    style={{
                      transition: 'all 0.2s',
                    }}
                  />
                </HStack>
              </Box>
            </VStack>
          </Box>
        </Flex>
      </Box>
    </Portal>
  );
};

export default TourCanvas;
