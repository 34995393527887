import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
  Text,
  Flex,
  Icon,
  useColorModeValue,
  VStack,
  Spinner,
} from "@chakra-ui/react";
import { MapPin } from "lucide-react";
import { RadarMapEmbed } from "./radar-map-embed";

type Location = {
  id: string;
  title: string;
  address: string;
  latitude?: number;
  longitude?: number;
}

type MapModalProps = {
  isOpen: boolean;
  onClose: () => void;
  locations: Location[];
}

const MapModal: React.FC<MapModalProps> = ({ isOpen, onClose, locations }) => {
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const mapBgColor = useColorModeValue('gray.50', 'gray.700');

  // Find first location with coordinates to center the map
  const firstLocationWithCoords = locations.find(loc => loc.latitude && loc.longitude);
  const initialLocation = firstLocationWithCoords 
    ? { lat: firstLocationWithCoords.latitude!, lng: firstLocationWithCoords.longitude! }
    : undefined;

  return (
    <Modal 
      isOpen={isOpen} 
      onClose={onClose}
      size="5xl"
      isCentered
      motionPreset="slideInBottom"
    >
      <ModalOverlay 
        bg="blackAlpha.300"
        backdropFilter="blur(10px)"
      />
      <ModalContent
        bg={bgColor}
        borderRadius="xl"
        boxShadow="xl"
        maxWidth="90vw"
      >
        <ModalHeader 
          borderBottom="1px" 
          borderColor={borderColor}
          py={4}
          px={6}
        >
          <Flex align="center">
            <Icon as={MapPin} mr={3} color="purple.500" />
            <Text fontSize="xl">Tour Locations</Text>
          </Flex>
        </ModalHeader>
        <ModalCloseButton size="lg" />
        
        <ModalBody py={6} px={6}>
          <VStack spacing={4} align="stretch">
            {isLoading ? (
              <Flex justify="center" align="center" height="60vh">
                <Spinner size="xl" color="purple.500" thickness="4px" />
              </Flex>
            ) : error ? (
              <Flex 
                direction="column" 
                align="center" 
                justify="center" 
                height="60vh"
                bg={mapBgColor}
                borderRadius="lg"
                p={8}
              >
                <Icon as={MapPin} boxSize={12} color="red.500" mb={4} />
                <Text color="red.500" fontSize="lg" textAlign="center">
                  {error}
                </Text>
              </Flex>
            ) : (
              <Box 
                height="60vh" 
                width="100%" 
                borderRadius="lg"
                overflow="hidden"
                borderWidth="1px"
                borderColor={borderColor}
              >
                <RadarMapEmbed
                  initialLocation={initialLocation}
                  mode="detail"
                  onSelectPlace={() => {}}
                />
              </Box>
            )}
          </VStack>
        </ModalBody>

        <ModalFooter 
          borderTop="1px" 
          borderColor={borderColor}
          py={4}
          px={6}
        >
          <Button
            colorScheme="purple"
            size="lg"
            onClick={onClose}
          >
            Close Map
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default MapModal;
