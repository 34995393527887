import { useState } from "react"
import { useSendMessageToParticipantMutation } from "../graphql/generated"

type UseMessageInputProps = {
  tourId: string
  participantId: string
  participantEmail?: string
  onMessageSent?: () => void
}

export const useMessageInput = ({
  tourId,
  participantId,
  participantEmail,
  onMessageSent
}: UseMessageInputProps) => {
  const [message, setMessage] = useState("")

  const [sendToParticipant, { loading: sendingToParticipant }] = useSendMessageToParticipantMutation()

  const sendMessage = async () => {
    if (!message.trim()) return

    try {
        await sendToParticipant({
          variables: {
            tourId,
            participantEmail: participantEmail ?? "",
            content: message.trim()
          }
        })
      
      setMessage("")
      onMessageSent?.()
    } catch (error) {
      console.error("Failed to send message:", error)
    }
  }

  return {
    message,
    setMessage,
    sendMessage,
    isSending: sendingToParticipant
  }
} 
