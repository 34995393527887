import React, { useCallback, useEffect, useRef, useState } from 'react';
import { VStack, FormControl, FormLabel, Input, Button, FormErrorMessage, Heading, Textarea, HStack, Box, Text, useColorModeValue, Spinner, InputGroup, InputRightElement, Popover, PopoverTrigger, PopoverContent, PopoverArrow, PopoverBody, IconButton, Portal, SimpleGrid, ButtonGroup, useBreakpointValue, List, ListItem, useToast } from '@chakra-ui/react';
import ReactSelect from 'react-select';
import { CreateLocationInput} from '../graphql/generated';
import { AddIcon, DeleteIcon, CheckIcon, RepeatIcon } from '@chakra-ui/icons';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { Wand2 } from 'lucide-react';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import env from '../env';
import { useGenerateTextMutation, useGetGenerationsTodayQuery } from '../graphql/generated';
import { debounce } from 'lodash';
import Radar from 'radar-sdk-js';

// Initialize Radar SDK
Radar.initialize(env.REACT_APP_RADAR_TOKEN);

type RadarAutocompleteAddress = {
  formattedAddress?: string;
  placeLabel?: string;
  latitude?: number;
  longitude?: number;
};

type RadarAutocompleteResponse = {
  addresses: RadarAutocompleteAddress[];
};

type RadarAddress = {
  formattedAddress: string;
  placeLabel?: string;
  latitude: number;
  longitude: number;
};

const DEFAULT_LOCATION = {
  latitude: 40.7128,
  longitude: -74.0060 // New York City coordinates
};

const useRadarSearch = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState<RadarAddress[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [userLocation, setUserLocation] = useState(DEFAULT_LOCATION);
  const toast = useToast();

  useEffect(() => {
    // Request user's location
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          });
        },
        (error) => {
          console.warn("Geolocation error:", error);
          toast({
            title: "Location access denied",
            description: "Using New York City as default location",
            status: "info",
            duration: 5000,
            isClosable: true,
          });
        }
      );
    }
  }, [toast]);

  const searchPlaces = async (query: string) => {
    if (!query) {
      setResults([]);
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const result = await Radar.autocomplete({
        query,
        near: userLocation,
        layers: ['address', 'place'],
        limit: 10
      }) as RadarAutocompleteResponse;

      if (result.addresses) {
        const validAddresses = result.addresses
          .filter((addr) => 
            typeof addr.formattedAddress === 'string' &&
            typeof addr.latitude === 'number' &&
            typeof addr.longitude === 'number'
          )
          .map((addr): RadarAddress => ({
            formattedAddress: addr.formattedAddress as string,
            placeLabel: addr.placeLabel,
            latitude: addr.latitude as number,
            longitude: addr.longitude as number
          }));

        setResults(validAddresses);
      }
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : "Failed to fetch places";
      setError(errorMessage);
      toast({
        title: "Search Error",
        description: errorMessage,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return { searchPlaces, results, isLoading, error };
};

const useTextGeneration = (
  name: keyof CreateLocationInput,
  location: any,
  handleInputChange: (field: string, value: any) => void,
  hasRemainingCredits: boolean
) => {
  const [generateText] = useGenerateTextMutation();
  const [generatedText, setGeneratedText] = useState<string>("");
  const [editableText, setEditableText] = useState<string>("");
  const [isGenerating, setIsGenerating] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const toast = useToast();

  const generatePrompt = (action: string) => {
    const currentText = location[name] as string;
    const fieldType = name === 'title' ? 'title' : 'description';

    switch (action) {
      case 'generate':
        return `Generate a creative and engaging food location ${fieldType} that captures the unique aspects of a culinary destination.`;
      case 'longer':
        return `Make this food location ${fieldType} longer and more detailed while maintaining its essence: "${currentText}"`;
      case 'shorter':
        return `Make this food location ${fieldType} more concise while keeping the main points: "${currentText}"`;
      case 'rewrite':
        return `Rewrite this food location ${fieldType} in a different style while keeping the same meaning: "${currentText}"`;
      case 'improve':
        return `Improve this food location ${fieldType} by making it more engaging and appetizing: "${currentText}"`;
      default:
        return '';
    }
  };

  const handleGenerate = async (action: string) => {
    if (!hasRemainingCredits) {
      toast({
        title: "No credits remaining",
        description: "You've used all your AI generation credits for today.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      setIsGenerating(true);
      const response = await generateText({
        variables: {
          input: generatePrompt(action)
        }
      });

      if (response.data?.generateText) {
        const text = response.data.generateText;
        setGeneratedText(text);
        setEditableText(text);
        setIsPopoverOpen(false);
      }
    } catch (error) {
      if (error instanceof Error) {
        const message = error.message.replace(/^ApolloError: |^Error: /, "");
        toast({
          title: "Generation failed",
          description: message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } finally {
      setIsGenerating(false);
    }
  };

  const acceptGeneratedText = () => {
    handleInputChange(name, editableText);
    setGeneratedText("");
    setEditableText("");
  };

  return {
    generatedText,
    editableText,
    setEditableText,
    isGenerating,
    handleGenerate,
    acceptGeneratedText,
    setGeneratedText,
    isPopoverOpen,
    setIsPopoverOpen
  };
};

type AddressSearchBoxProps = {
  value: string;
  onChange: (value: string) => void;
};

const AddressSearchBox = ({ value, onChange }: AddressSearchBoxProps) => {
  const [inputValue, setInputValue] = useState(value);
  const [isOpen, setIsOpen] = useState(false);
  const { searchPlaces, results, isLoading, error } = useRadarSearch();
  const inputRef = useRef<HTMLInputElement>(null);
  const listRef = useRef<HTMLDivElement>(null);

  const debouncedSearch = useCallback(
    debounce((query: string) => {
      searchPlaces(query);
    }, 300),
    [searchPlaces]
  );

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    setIsOpen(true);
    debouncedSearch(newValue);
  };

  const handleSelectPlace = (address: RadarAddress) => {
    onChange(address.formattedAddress);
    setInputValue(address.formattedAddress);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        listRef.current &&
        !listRef.current.contains(event.target as Node) &&
        !inputRef.current?.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <Box position="relative" width="100%">
      <InputGroup>
        <Input
          ref={inputRef}
          value={inputValue}
          onChange={handleInputChange}
          placeholder="Search for a place"
          bg="white"
          borderColor="gray.300"
          _hover={{ borderColor: "gray.400" }}
          _focus={{ borderColor: "purple.500", boxShadow: "0 0 0 1px var(--chakra-colors-purple-500)" }}
          onFocus={() => setIsOpen(true)}
        />
        {isLoading && (
          <InputRightElement>
            <Spinner size="sm" color="purple.500" />
          </InputRightElement>
        )}
      </InputGroup>

      {isOpen && (results.length > 0 || isLoading || error) && (
        <Portal>
          <Box
            ref={listRef}
            position="fixed"
            width={inputRef.current?.getBoundingClientRect().width}
            left={inputRef.current?.getBoundingClientRect().left}
            top={inputRef.current?.getBoundingClientRect().bottom}
            mt={2}
            maxH="300px"
            overflowY="auto"
            bg="white"
            borderRadius="md"
            boxShadow="xl"
            borderWidth={1}
            borderColor="gray.200"
            zIndex={9999}
          >
            {error ? (
              <Text p={4} color="red.500">
                {error}
              </Text>
            ) : (
              <List spacing={0}>
                {results.map((address) => (
                  <ListItem
                    key={`${address.latitude}-${address.longitude}`}
                    p={3}
                    cursor="pointer"
                    _hover={{ bg: "purple.50" }}
                    onClick={() => handleSelectPlace(address)}
                  >
                    <Text fontWeight="medium">
                      {address.placeLabel || address.formattedAddress.split(',')[0]}
                    </Text>
                    <Text fontSize="sm" color="gray.600">
                      {address.formattedAddress}
                    </Text>
                  </ListItem>
                ))}
              </List>
            )}
          </Box>
        </Portal>
      )}
    </Box>
  );
};

type LocationFormProps = {
  location: any;
  handleInputChange: (field: string, value: any) => void;
  errors: any;
  handleQuizChange?: (index: number, field: string, value: any) => void;
  addQuizQuestion?: () => void;
  removeQuizQuestion?: (index: number) => void;
  addBasicGame?: () => void;
  removeBasicGame?: (index: number) => void;
  handleBasicGameChange?: (index: number, field: string, value: any) => void;
  setIsFormValid: (isValid: boolean) => void;
  isEditing?: boolean;
};

const LocationForm = ({ 
  location, 
  handleInputChange, 
  errors, 
  handleQuizChange, 
  addQuizQuestion, 
  removeQuizQuestion, 
  addBasicGame, 
  removeBasicGame, 
  handleBasicGameChange,
  setIsFormValid,
  isEditing = false
}: LocationFormProps) => {
  const validateForm = useCallback(() => {
    const isValid = 
      !!location.title &&
      !!location.description &&
      !!location.address &&
      (location.gameType === 'QUIZ' 
        ? location.quiz && location.quiz.length > 0 && location.quiz.every((q: any) => 
            q.question && q.answers.length > 0 && q.correctAnswers.length > 0
          )
        : location.gameType === 'BASIC'
          ? location.basic && location.basic.length > 0 && location.basic.every((b: any) =>
              b.text && b.content && b.expectedContent
            )
          : true
      );
    
    setIsFormValid(isValid);
  }, [location, setIsFormValid]);

  useEffect(() => {
    // Validate form when component mounts or location data changes
    validateForm();
  }, [location, validateForm]);

  const { data: generationsData } = useGetGenerationsTodayQuery({
    pollInterval: 30000,
  });

  const hasRemainingCredits = (generationsData?.generationsToday.remaining ?? 0) > 0;

  const titleGeneration = useTextGeneration('title', location, handleInputChange, hasRemainingCredits);
  const descriptionGeneration = useTextGeneration('description', location, handleInputChange, hasRemainingCredits);

  return (
    <Box
      borderWidth={1}
      borderRadius="lg"
      p={6}
      bg={useColorModeValue('white', 'gray.800')}
      shadow="md"
      maxHeight="calc(100vh - 200px)"
      overflowY="auto"
    >
      <VStack spacing={6} align="stretch">
        <FormControl isInvalid={!!errors.title} isRequired>
          <FormLabel fontWeight="bold">Name</FormLabel>
          <InputGroup>
            <Input
              value={location.title}
              onChange={(e) => handleInputChange('title', e.target.value)}
              placeholder="Enter location name"
              bg="white"
              borderColor="gray.300"
              pr="40px"
            />
            <InputRightElement>
              <Box position="relative">
                <Popover
                  placement={useBreakpointValue({ base: "bottom", md: "right" })}
                  autoFocus={false}
                  closeOnBlur={true}
                  strategy="fixed"
                  gutter={8}
                  isLazy
                  lazyBehavior="keepMounted"
                  isOpen={titleGeneration.isPopoverOpen}
                  onClose={() => titleGeneration.setIsPopoverOpen(false)}
                >
                  <PopoverTrigger>
                    <IconButton
                      aria-label="Generate text"
                      icon={<Wand2 size={16} />}
                      size="sm"
                      variant="ghost"
                      colorScheme="purple"
                      isLoading={titleGeneration.isGenerating}
                      onClick={() => titleGeneration.setIsPopoverOpen(true)}
                      isDisabled={!hasRemainingCredits}
                      title={!hasRemainingCredits ? "No AI credits remaining" : "Generate text"}
                    />
                  </PopoverTrigger>
                  <Portal>
                    <Box
                      position="relative"
                      zIndex={2000}
                      sx={{
                        ".chakra-popover__content": {
                          zIndex: 2000,
                        },
                        ".chakra-popover__arrow": {
                          zIndex: 2000,
                        }
                      }}
                    >
                      <PopoverContent
                        width={useBreakpointValue({ base: "calc(100vw - 32px)", md: "300px" })}
                        maxWidth={useBreakpointValue({ base: "calc(100vw - 32px)", md: "300px" })}
                        borderRadius="xl"
                        shadow="xl"
                        _focus={{ outline: "none" }}
                        bg="white"
                        border="1px solid"
                        borderColor="gray.200"
                      >
                        <PopoverArrow bg="white" borderColor="gray.200" />
                        <PopoverBody p={4}>
                          <VStack spacing={2} align="stretch">
                            <Text fontSize="sm" fontWeight="bold" color="purple.600" mb={1}>
                              AI Text Generation
                            </Text>
                            <Button
                              size="sm"
                              leftIcon={<Wand2 size={14} />}
                              onClick={() => titleGeneration.handleGenerate('generate')}
                              isLoading={titleGeneration.isGenerating}
                              colorScheme="purple"
                              width="100%"
                            >
                              Generate New
                            </Button>
                            {location.title && (
                              <SimpleGrid columns={2} spacing={2}>
                                <Button
                                  size="sm"
                                  onClick={() => titleGeneration.handleGenerate('longer')}
                                  isLoading={titleGeneration.isGenerating}
                                  variant="outline"
                                  colorScheme="purple"
                                >
                                  Longer
                                </Button>
                                <Button
                                  size="sm"
                                  onClick={() => titleGeneration.handleGenerate('shorter')}
                                  isLoading={titleGeneration.isGenerating}
                                  variant="outline"
                                  colorScheme="purple"
                                >
                                  Shorter
                                </Button>
                                <Button
                                  size="sm"
                                  onClick={() => titleGeneration.handleGenerate('rewrite')}
                                  isLoading={titleGeneration.isGenerating}
                                  variant="outline"
                                  colorScheme="purple"
                                >
                                  Rewrite
                                </Button>
                                <Button
                                  size="sm"
                                  onClick={() => titleGeneration.handleGenerate('improve')}
                                  isLoading={titleGeneration.isGenerating}
                                  variant="outline"
                                  colorScheme="purple"
                                >
                                  Improve
                                </Button>
                              </SimpleGrid>
                            )}
                          </VStack>
                        </PopoverBody>
                      </PopoverContent>
                    </Box>
                  </Portal>
                </Popover>
              </Box>
            </InputRightElement>
          </InputGroup>
          {titleGeneration.generatedText && (
            <Box mt={2} p={3} borderRadius="md" borderWidth={1} borderColor="purple.200">
              <Input
                value={titleGeneration.editableText}
                onChange={(e) => titleGeneration.setEditableText(e.target.value)}
                fontSize="sm"
                mb={2}
                bg="white"
                _hover={{ bg: "white" }}
                _focus={{ bg: "white", borderColor: "purple.300" }}
              />
              <ButtonGroup size="sm" spacing={2}>
                <Button
                  colorScheme="purple"
                  leftIcon={<CheckIcon />}
                  onClick={titleGeneration.acceptGeneratedText}
                >
                  Accept
                </Button>
                <Button
                  variant="ghost"
                  onClick={() => {
                    titleGeneration.setGeneratedText("");
                    titleGeneration.setEditableText("");
                  }}
                >
                  Dismiss
                </Button>
                <Button
                  variant="ghost"
                  onClick={() => {
                    titleGeneration.setEditableText(titleGeneration.generatedText);
                  }}
                  leftIcon={<RepeatIcon />}
                >
                  Reset
                </Button>
              </ButtonGroup>
            </Box>
          )}
          {errors.title && <FormErrorMessage>{errors.title}</FormErrorMessage>}
        </FormControl>

        <FormControl isInvalid={!!errors.description} isRequired>
          <FormLabel fontWeight="bold">Description</FormLabel>
          <InputGroup>
            <Textarea
              value={location.description}
              onChange={(e) => handleInputChange('description', e.target.value)}
              placeholder="Enter location description"
              bg="white"
              borderColor="gray.300"
              minHeight="100px"
              pr="40px"
            />
            <InputRightElement top="0" h="40px">
              <Box position="relative">
                <Popover
                  placement={useBreakpointValue({ base: "bottom", md: "right" })}
                  autoFocus={false}
                  closeOnBlur={true}
                  strategy="fixed"
                  gutter={8}
                  isLazy
                  lazyBehavior="keepMounted"
                  isOpen={descriptionGeneration.isPopoverOpen}
                  onClose={() => descriptionGeneration.setIsPopoverOpen(false)}
                >
                  <PopoverTrigger>
                    <IconButton
                      aria-label="Generate text"
                      icon={<Wand2 size={16} />}
                      size="sm"
                      variant="ghost"
                      colorScheme="purple"
                      isLoading={descriptionGeneration.isGenerating}
                      onClick={() => descriptionGeneration.setIsPopoverOpen(true)}
                      isDisabled={!hasRemainingCredits}
                      title={hasRemainingCredits ? "Generate text" : "No AI credits remaining"}
                    />
                  </PopoverTrigger>
                  <Portal>
                    <Box
                      position="relative"
                      zIndex={2000}
                      sx={{
                        ".chakra-popover__content": {
                          zIndex: 2000,
                        },
                        ".chakra-popover__arrow": {
                          zIndex: 2000,
                        }
                      }}
                    >
                      <PopoverContent
                        width={useBreakpointValue({ base: "calc(100vw - 32px)", md: "300px" })}
                        maxWidth={useBreakpointValue({ base: "calc(100vw - 32px)", md: "300px" })}
                        borderRadius="xl"
                        shadow="xl"
                        _focus={{ outline: "none" }}
                        bg="white"
                        border="1px solid"
                        borderColor="gray.200"
                      >
                        <PopoverArrow bg="white" borderColor="gray.200" />
                        <PopoverBody p={4}>
                          <VStack spacing={2} align="stretch">
                            <Text fontSize="sm" fontWeight="bold" color="purple.600" mb={1}>
                              AI Text Generation
                            </Text>
                            <Button
                              size="sm"
                              leftIcon={<Wand2 size={14} />}
                              onClick={() => descriptionGeneration.handleGenerate('generate')}
                              isLoading={descriptionGeneration.isGenerating}
                              colorScheme="purple"
                              width="100%"
                            >
                              Generate New
                            </Button>
                            {location.description && (
                              <SimpleGrid columns={2} spacing={2}>
                                <Button
                                  size="sm"
                                  onClick={() => descriptionGeneration.handleGenerate('longer')}
                                  isLoading={descriptionGeneration.isGenerating}
                                  variant="outline"
                                  colorScheme="purple"
                                >
                                  Longer
                                </Button>
                                <Button
                                  size="sm"
                                  onClick={() => descriptionGeneration.handleGenerate('shorter')}
                                  isLoading={descriptionGeneration.isGenerating}
                                  variant="outline"
                                  colorScheme="purple"
                                >
                                  Shorter
                                </Button>
                                <Button
                                  size="sm"
                                  onClick={() => descriptionGeneration.handleGenerate('rewrite')}
                                  isLoading={descriptionGeneration.isGenerating}
                                  variant="outline"
                                  colorScheme="purple"
                                >
                                  Rewrite
                                </Button>
                                <Button
                                  size="sm"
                                  onClick={() => descriptionGeneration.handleGenerate('improve')}
                                  isLoading={descriptionGeneration.isGenerating}
                                  variant="outline"
                                  colorScheme="purple"
                                >
                                  Improve
                                </Button>
                              </SimpleGrid>
                            )}
                          </VStack>
                        </PopoverBody>
                      </PopoverContent>
                    </Box>
                  </Portal>
                </Popover>
              </Box>
            </InputRightElement>
          </InputGroup>
          {descriptionGeneration.generatedText && (
            <Box mt={2} p={3} borderRadius="md" borderWidth={1} borderColor="purple.200">
              <Textarea
                value={descriptionGeneration.editableText}
                onChange={(e) => descriptionGeneration.setEditableText(e.target.value)}
                fontSize="sm"
                mb={2}
                minHeight="100px"
                bg="white"
                _hover={{ bg: "white" }}
                _focus={{ bg: "white", borderColor: "purple.300" }}
              />
              <ButtonGroup size="sm" spacing={2}>
                <Button
                  colorScheme="purple"
                  leftIcon={<CheckIcon />}
                  onClick={descriptionGeneration.acceptGeneratedText}
                >
                  Accept
                </Button>
                <Button
                  variant="ghost"
                  onClick={() => {
                    descriptionGeneration.setGeneratedText("");
                    descriptionGeneration.setEditableText("");
                  }}
                >
                  Dismiss
                </Button>
                <Button
                  variant="ghost"
                  onClick={() => {
                    descriptionGeneration.setEditableText(descriptionGeneration.generatedText);
                  }}
                  leftIcon={<RepeatIcon />}
                >
                  Reset
                </Button>
              </ButtonGroup>
            </Box>
          )}
          {errors.description && <FormErrorMessage>{errors.description}</FormErrorMessage>}
        </FormControl>

          <FormControl 
            isInvalid={!!errors.address} 
            isRequired 
            flex={1} 
            position="relative"
          >
            <FormLabel fontWeight="bold">Address</FormLabel>
            <Box position="relative">
              <AddressSearchBox
                value={location.address}
                onChange={(result: string) => handleInputChange('address', result)}
              />
            </Box>
            {errors.address && <FormErrorMessage>{errors.address}</FormErrorMessage>}
          </FormControl>

        {location.gameType === 'QUIZ' && location.quiz && (
          <VStack spacing={6} align="stretch">
            <Heading size="md" fontWeight="bold">Quiz Questions</Heading>
            {location.quiz.map((q: any, index: number) => (
              <Box key={index} borderWidth="1px" borderRadius="md" p={4} bg="white">
                <HStack justifyContent="space-between" mb={4}>
                  <Heading size="sm" fontWeight="bold">Question {index + 1}</Heading>
                  <Button size="sm" colorScheme="red" onClick={() => removeQuizQuestion?.(index)}>
                    <DeleteIcon />
                  </Button>
                </HStack>

                <VStack spacing={4} align="stretch">
                  <FormControl isInvalid={!!errors[`quiz${index}` as keyof CreateLocationInput]} isRequired>
                    <FormLabel fontWeight="bold">Question</FormLabel>
                    <Input
                      value={q.question}
                      onChange={(e) => handleQuizChange?.(index, 'question', e.target.value)}
                      placeholder="Enter question"
                      bg="white"
                      borderColor="gray.300"
                    />
                    {errors[`quiz${index}` as keyof CreateLocationInput] && (
                      <FormErrorMessage>{errors[`quiz${index}` as keyof CreateLocationInput]}</FormErrorMessage>
                    )}
                  </FormControl>

                  <Text fontWeight="bold">Answers</Text>
                  {q.answers.map((answer: any, answerIndex: number) => (
                    <FormControl key={answerIndex} isRequired>
                      <Input
                        value={answer}
                        onChange={(e) =>
                          handleQuizChange?.(
                            index,
                            'answers',
                            q.answers.map((a: any, i: number) => (i === answerIndex ? e.target.value : a))
                          )
                        }
                        placeholder={`Answer ${answerIndex + 1}`}
                        bg="white"
                        borderColor="gray.300"
                      />
                    </FormControl>
                  ))}

                  <FormControl isRequired>
                    <FormLabel fontWeight="bold">Correct Answers</FormLabel>
                    <ReactSelect
                      isMulti
                      options={q.answers.map((answer: any, i: number) => ({ value: answer, label: answer }))}
                      value={q.correctAnswers.map((answer: any) => ({ value: answer, label: answer }))}
                      onChange={(selectedOptions) => {
                        const selectedAnswers = selectedOptions.map(option => option.value);
                        handleQuizChange?.(index, 'correctAnswers', selectedAnswers);
                      }}
                      placeholder="Select correct answers"
                      styles={{
                        control: (base) => ({
                          ...base,
                          backgroundColor: 'white',
                          borderColor: 'var(--chakra-colors-gray-300)',
                        }),
                      }}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel fontWeight="bold">Hint (optional)</FormLabel>
                    <Input
                      value={q.hint || ''}
                      onChange={(e) => handleQuizChange?.(index, 'hint', e.target.value)}
                      placeholder="Enter hint"
                      bg="white"
                      borderColor="gray.300"
                    />
                  </FormControl>
                </VStack>
              </Box>
            ))}
            <Button
              onClick={addQuizQuestion}
              leftIcon={<AddIcon />}
              variant="outline"
              colorScheme="blue"
            >
              Add Question
            </Button>
          </VStack>
        )}
        {location.gameType === 'BASIC' && location.basic && (
          // text, content, expectedContent
          <VStack spacing={6} align="stretch">
            <Heading size="md" fontWeight="bold">Basic Game</Heading>
            {location.basic.map((b: any, index: number) => (
              <Box key={index} borderWidth="1px" borderRadius="md" p={4} bg="white">
                <HStack justifyContent="space-between" mb={4}>
                  <Heading size="sm" fontWeight="bold">Basic Game {index + 1}</Heading>
                  <Button size="sm" colorScheme="red" onClick={() => removeBasicGame?.(index)}>
                    <DeleteIcon />
                  </Button>
                </HStack>
                <VStack spacing={4} align="stretch">
                  <FormControl isInvalid={!!errors[`basic${index}` as keyof CreateLocationInput]} isRequired>
                    <FormLabel fontWeight="bold">Text</FormLabel>
                    <Input
                      value={b.text}
                      onChange={(e) => handleBasicGameChange?.(index, 'text', e.target.value)}
                      placeholder="Enter text"
                      bg="white"
                      borderColor="gray.300"
                    />
                    {errors[`basic${index}` as keyof CreateLocationInput] && (
                      <FormErrorMessage>{errors[`basic${index}` as keyof CreateLocationInput]}</FormErrorMessage>
                    )}
                  </FormControl>

                  <FormControl isInvalid={!!errors[`basic${index}` as keyof CreateLocationInput]} isRequired>
                    <FormLabel fontWeight="bold">Content</FormLabel>
                    <Input
                      value={b.content}
                      onChange={(e) => handleBasicGameChange?.(index, 'content', e.target.value)}
                      placeholder="Enter content"
                      bg="white"
                      borderColor="gray.300"
                    />
                    {errors[`basic${index}` as keyof CreateLocationInput] && (
                      <FormErrorMessage>{errors[`basic${index}` as keyof CreateLocationInput]}</FormErrorMessage>
                    )}
                  </FormControl>

                  <FormControl isInvalid={!!errors[`basic${index}` as keyof CreateLocationInput]} isRequired>
                    <FormLabel fontWeight="bold">Expected Content</FormLabel>
                    <Input
                      value={b.expectedContent}
                      onChange={(e) => handleBasicGameChange?.(index, 'expectedContent', e.target.value)}
                      placeholder="Enter expected content"
                      bg="white"
                      borderColor="gray.300"
                    />
                    {errors[`basic${index}` as keyof CreateLocationInput] && (
                      <FormErrorMessage>{errors[`basic${index}` as keyof CreateLocationInput]}</FormErrorMessage>
                    )}
                  </FormControl>
                </VStack>
              </Box>
            ))}
            <Button
              onClick={addBasicGame}
              leftIcon={<AddIcon />}
              variant="outline"
              colorScheme="blue"
            >
              Add Basic Game
            </Button>
          </VStack>
        )}
      </VStack>
    </Box>
  );
};

export default LocationForm;
