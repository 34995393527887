import React, { useEffect, useRef, useState } from "react";
import { 
  VStack, 
  Heading, 
  Text, 
  Flex, 
  Icon, 
  Box, 
  Button, 
  Link,
  Input,
  SimpleGrid,
  Spinner,
  useColorModeValue
} from "@chakra-ui/react";
import { Camera, MapPin } from "lucide-react";
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { isDarkColor } from "../helpers/color-util";

type LocationPreviewProps = {
  location: {
    title: string;
    description: string;
    address: string;
  };
  color: string;
  onUploadMedia: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const LocationDetails: React.FC<{ location: LocationPreviewProps["location"] }> = ({
  location
}) => (
  <VStack spacing={4} align="stretch">
    <Heading size="md" color="orange.800" textAlign="left">
      {location.title || "Location Title"}
    </Heading>
    <Text color="gray.700" fontSize="sm" lineHeight="tall">
      {location.description || "Location Description"}
    </Text>
    <Flex 
      alignItems="center" 
      bg="blue.50" 
      p={3} 
      borderRadius="md"
      _dark={{ bg: "blue.900" }}
    >
      <Icon as={MapPin} boxSize={5} color="blue.500" mr={3} />
      <Link 
        href={`https://maps.google.com/?q=${encodeURIComponent(location.address)}`}
        isExternal
        display="flex"
        alignItems="center"
        color="blue.700"
        fontSize="sm"
        fontWeight="medium"
        _hover={{ color: "blue.500", textDecoration: "none" }}
      >
        {location.address}
      </Link>
    </Flex>
  </VStack>
);

const PhotoUploadSection: React.FC<{
  color: string;
  onUploadMedia: (event: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({ color, onUploadMedia }) => (
  <VStack spacing={4} align="stretch" w="100%">
    <Input
      type="file"
      accept="image/*"
      onChange={onUploadMedia}
      display="none"
      id="photo-upload-preview"
    />
    <Button
      as="label"
      htmlFor="photo-upload-preview"
      style={{ 
        backgroundColor: color || "#805AD5",
        color: isDarkColor(color) ? "white" : "black" 
      }}
      leftIcon={<Camera />}
      size="md"
      width="100%"
      height="48px"
      _hover={{ 
        transform: "translateY(-2px)",
        boxShadow: "lg" 
      }}
      transition="all 0.2s"
    >
      Take Photo (0/2)
    </Button>
    <SimpleGrid 
      columns={{ base: 2, sm: 3 }} 
      spacing={3} 
      w="100%"
    >
      {/* Photo previews will go here */}
    </SimpleGrid>
  </VStack>
);

const LocationPreview: React.FC<LocationPreviewProps> = ({ 
  location, 
  color, 
  onUploadMedia 
}) => {
  const mapContainer = useRef<HTMLDivElement>(null);
  const map = useRef<mapboxgl.Map | null>(null);
  const [coordinates, setCoordinates] = useState<[number, number] | null>(null);
  const [isMapLoading, setIsMapLoading] = useState(true);

  useEffect(() => {
    if (!process.env.REACT_APP_MAPBOX_TOKEN) {
      console.error("Mapbox token is missing");
      setIsMapLoading(false);
      return;
    }

    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

    const geocodeAddress = async (address: string) => {
      try {
        const response = await fetch(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
           address
          )}.json?access_token=${mapboxgl.accessToken}`
        );
        
        if (!response.ok) throw new Error(`Geocoding failed: ${response.statusText}`);

        const data = await response.json();
        if (data.features?.length > 0) {
          setCoordinates(data.features[0].center);
        }
      } catch (error) {
        console.error('Error geocoding address:', error);
      } finally {
        setIsMapLoading(false);
      }
    };

    if (location?.address) {
      geocodeAddress(location.address);
    }
  }, [location]);

  useEffect(() => {
    if (!coordinates || !mapContainer.current) return;

    // Store resize handler reference
    let resizeHandler: (() => void) | null = null;

    try {
      if (map.current) map.current.remove();

      const newMap = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: coordinates,
        zoom: 14,
        interactive: true,
        attributionControl: false,
        maxZoom: 18,
        minZoom: 12,
        dragRotate: false
      });

      map.current = newMap;

      newMap.on('load', () => setIsMapLoading(false));

      const nav = new mapboxgl.NavigationControl({
        showCompass: false,
        visualizePitch: false
      });
      newMap.addControl(nav, 'bottom-right');

      const marker = new mapboxgl.Marker({ 
        color: color || '#805AD5',
        scale: 0.8
      })
        .setLngLat(coordinates)
        .addTo(newMap);

      const popup = new mapboxgl.Popup({ 
        offset: 25,
        closeButton: false,
        className: 'custom-popup'
      })
        .setHTML(`
          <div style="
            padding: 12px;
            font-family: system-ui, -apple-system, sans-serif;
          ">
            <h3 style="
              font-weight: 600;
              font-size: 14px;
              margin-bottom: 4px;
              color: #2D3748;
            ">${location.title}</h3>
            <p style="
              color: #4A5568;
              font-size: 12px;
            ">${location.address}</p>
          </div>
        `);

      marker.setPopup(popup);

      // Define resize handler
      resizeHandler = () => {
        if (newMap) {
          newMap.resize();
          newMap.setZoom(window.innerWidth < 768 ? 13 : 14);
        }
      };

      // Add resize listener
      window.addEventListener('resize', resizeHandler);
      resizeHandler(); // Initial resize
    } catch (error) {
      console.error('Error initializing map:', error);
      setIsMapLoading(false);
    }

    // Cleanup function
    return () => {
      // Remove resize listener if it exists
      if (resizeHandler) {
        window.removeEventListener('resize', resizeHandler);
      }
      // Remove map if it exists
      if (map.current) {
        map.current.remove();
        map.current = null;
      }
    };
  }, [coordinates, location, color]);

  return (
    <VStack spacing={6} align="stretch" width="100%">
      <Box
        position="relative"
        height={{ base: "250px", sm: "300px", md: "400px" }}
        width="100%"
        borderRadius={{ base: "none", md: "lg" }}
        overflow="hidden"
        boxShadow={{ base: "none", md: "base" }}
      >
        {isMapLoading && (
          <Flex
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            bg="gray.100"
            justify="center"
            align="center"
            zIndex={1}
          >
            <Spinner 
              size="xl" 
              color={color || "purple.500"} 
              thickness="4px"
              speed="0.65s"
            />
          </Flex>
        )}
        <Box 
          ref={mapContainer} 
          style={{ width: '100%', height: '100%' }}
          className="map-container"
          sx={{
            ".mapboxgl-ctrl-bottom-right": {
              mb: { base: 2, md: 4 },
              mr: { base: 2, md: 4 }
            },
            ".mapboxgl-popup-content": {
              p: 0,
              borderRadius: "md",
              boxShadow: "lg"
            }
          }}
        />
      </Box>

      <Box px={6} pb={6}>
        <Flex 
          direction={{ base: "column", md: "row" }} 
          gap={6}
          bg={useColorModeValue("white", "gray.800")}
          borderRadius="xl"
          overflow="hidden"
          boxShadow="sm"
        >
          <Box 
            flex={1} 
            p={6}
            bg={useColorModeValue("gray.50", "gray.700")}
          >
            <LocationDetails location={location} />
          </Box>

          <Box 
            flex={1} 
            p={6}
            borderLeft={{ base: "none", md: "1px" }}
            borderTop={{ base: "1px", md: "none" }}
            borderColor={useColorModeValue("gray.200", "gray.600")}
          >
            <PhotoUploadSection 
              color={color}
              onUploadMedia={onUploadMedia}
            />
          </Box>
        </Flex>
      </Box>
    </VStack>
  );
};

export default LocationPreview;
