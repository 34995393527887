import { useState, useEffect } from 'react';
import {
    Box, Button, FormControl, Heading, Text,
    Flex, Stack, Avatar, Link,
    useColorModeValue, PinInput, PinInputField, HStack
} from '@chakra-ui/react';
import { FaLock } from 'react-icons/fa';
import { useAuth } from '../../context/auth';
import { useNavigate, useLocation } from 'react-router-dom';
import { useVerifyMutation } from '../../graphql/generated';
import { z } from 'zod';
import Banner from '../../components/shared/banner';

const VerifySchema = z.object({
    code: z.string().min(6, "Verification code must be at least 6 characters long"),
});

type VerifyFormData = {
    code: string;
    email: string;
    username: string;
    isFirstTime: boolean;
    redirectUrl?: string;
}

const VerifyPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { user, setUser } = useAuth();
    const [verifyFormData, setVerifyFormData] = useState<VerifyFormData>({
        code: '',
        email: user?.email || '',
        username: user?.username || '',
        isFirstTime: user?.firstTimeAt ? true : false,
        redirectUrl: '',
    });
    const [error, setError] = useState<string>('');
    const [showSuccessBanner, setShowSuccessBanner] = useState(false);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const token = params.get('token');
        const email = decodeURIComponent(params.get('email') || '');
        const isFirstTime = params.get('isFirstTime') === 'true';
        const redirectUrl = params.get('redirectUrl');

        if (token && email) {
            handleVerification({ 
                ...verifyFormData, 
                code: token, 
                email, 
                isFirstTime,
                redirectUrl: redirectUrl || undefined 
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const handleVerification = (verifyData: VerifyFormData) => {
        verifyMutation({ variables: { input: verifyData } });
    };
    
    useEffect(() => {
        if (!verifyFormData.email && !verifyFormData.username) {
            navigate('/login');
        }
    }, [verifyFormData, navigate]);

    const [verifyMutation] = useVerifyMutation({
        onCompleted: (data) => {
            setUser({
                ...data.verify.user,
                isAuth: true,
                id: data.verify.user.id || undefined,
                email: data.verify.user.email || undefined,
                username: data.verify.user.username || undefined,
                firstName: data.verify.user.firstName || undefined,
                lastName: data.verify.user.lastName || undefined,
                profilePicture: data.verify.user.profilePicture || undefined,
                firstTimeAt: data.verify.user.firstLoggedInAt || undefined,
                onboardingStep: data.verify.user.onboardingStep || undefined,
                onboardingCompletedAt: data.verify.user.onboardingCompletedAt || undefined,
            });
            
            const redirectUrl = verifyFormData.redirectUrl;
            if (redirectUrl) {
                window.localStorage.setItem('redirectUrl', redirectUrl);
                navigate(redirectUrl);
            } else {
                navigate('/home');
            }
            setShowSuccessBanner(true);
        },
        onError: (error) => {
            console.error('Verify mutation error:', error);
            setError(error.message);
            navigate('/login', { state: { error: error.message } });
        },
    });

    const handleChange = (value: string) => {
        setVerifyFormData({ ...verifyFormData, code: value });
        setError('');
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        try {
            VerifySchema.parse({ code: verifyFormData.code });
            setError('');
            handleVerification(verifyFormData);
        } catch (error) {
            if (error instanceof z.ZodError) {
                setError(error.errors[0].message);
            }
        }
    };

    const bgGradient = useColorModeValue(
        "linear(to-b, purple.50, pink.50)",
        "linear(to-b, gray.900, gray.800)"
    );
    const cardBg = useColorModeValue("white", "gray.800");
    const borderColor = useColorModeValue("purple.200", "purple.700");

    return (
        <>
            <Banner
                title="Verification successful!"
                message="Verification successful!"
                type="success"
                isVisible={showSuccessBanner}
                onClose={() => setShowSuccessBanner(false)}
            />
            <Banner
                title={error}
                message={error}
                type="error"
                isVisible={!!error}
                onClose={() => setError('')}
            />
            <Flex
                flexDirection="column"
                width="100wh"
                height="100vh"
                bgGradient={bgGradient}
                justifyContent="center"
                alignItems="center"
            >
                <Stack
                    flexDir="column"
                    mb="2"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Avatar bg="purple.500" icon={<FaLock fontSize="1.5rem" />} />
                    <Heading color="purple.400">Verify Your Account</Heading>
                    <Text fontSize="md" color="gray.600" textAlign="center">
                        Enter the verification code sent to your email
                    </Text>
                    <Box minW={{ base: "90%", md: "468px" }}>
                        <form onSubmit={handleSubmit}>
                            <Stack
                                spacing={4}
                                p="1rem"
                                backgroundColor={cardBg}
                                boxShadow="md"
                                borderRadius="xl"
                                borderWidth="1px"
                                borderColor={borderColor}
                            >
                                <FormControl isInvalid={!!error}>
                                    <HStack justify="center">
                                        <PinInput
                                            type="alphanumeric"
                                            value={verifyFormData.code}
                                            onChange={handleChange}
                                            placeholder="0"
                                            size="lg"
                                        >
                                            <PinInputField bg={cardBg} placeholder="0" />
                                            <PinInputField bg={cardBg} placeholder="0" />
                                            <PinInputField bg={cardBg} placeholder="0" />
                                            <PinInputField bg={cardBg} placeholder="0" />
                                            <PinInputField bg={cardBg} placeholder="0" />
                                            <PinInputField bg={cardBg} placeholder="0" />
                                        </PinInput>
                                    </HStack>
                                    {error && <Text color="red.500" fontSize="sm" mt={1} textAlign="center">{error}</Text>}
                                </FormControl>
                                <Button
                                    borderRadius="md"
                                    type="submit"
                                    variant="solid"
                                    colorScheme="purple"
                                    width="full"
                                    leftIcon={<FaLock />}
                                >
                                    Verify
                                </Button>
                            </Stack>
                        </form>
                    </Box>
                </Stack>
                <Box mt={4}>
                    <Text fontSize="sm" color="gray.600">
                        Didn't receive the code?{" "}
                        <Link color="purple.500" href="#" onClick={() => { }}>
                            Resend
                        </Link>
                    </Text>
                </Box>
            </Flex>
        </>
    );
};

export default VerifyPage;
