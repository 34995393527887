import { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Text,
  useColorModeValue,
  SimpleGrid,
  VStack,
  InputGroup,
  Input,
  InputLeftElement,
  InputRightElement,
  IconButton,
  Card,
  CardBody,
  Skeleton,
} from "@chakra-ui/react";
import { MapPinIcon, PlusIcon, SearchIcon, XIcon } from "lucide-react";
import LocationDrawer from "../../components/location-drawer";
import { GetLocationsDocument } from "../../graphql/generated";
import TourListSkeleton from "../../components/shared/tour-list-skeleton";
import { useNavigate } from "react-router-dom";
import { useSmartPolling } from "../../hooks/useSmartPolling";

const LocationsPage = () => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const { data, loading } = useSmartPolling(GetLocationsDocument, {}, {
    baseInterval: 5000, // Poll every 5 seconds
    maxInterval: 30000, // Max interval of 30 seconds
    onError: (error) => {
      console.error('Error polling locations:', error);
    }
  });
  const navigate = useNavigate();

  const bgColor = useColorModeValue("gray.50", "gray.800");
  const cardBgColor = useColorModeValue("white", "gray.700");
  const borderColor = useColorModeValue("purple.200", "purple.600");
  const textColor = useColorModeValue("purple.800", "white");
  const secondaryTextColor = useColorModeValue("gray.800", "gray.400");
  const searchBgColor = useColorModeValue("white", "gray.700");
  const searchBorderColor = useColorModeValue("gray.300", "gray.600");
  const searchIconColor = useColorModeValue("gray.400", "gray.500");

  const filteredLocations = data?.locations?.filter((location: any) =>
    location.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    location?.description?.toLowerCase().includes(searchTerm.toLowerCase())
  ) || [];

  const handleLocationClick = (locationId: string) => {
    navigate(`/home/locations/${locationId}`);
  };

  if (loading) return (
    <Box p={4} bg={bgColor} minH="100vh">
      <Flex mb={6} gap={2}>
        <Skeleton height="40px" flex={1} borderRadius="md" />
        <Skeleton height="40px" width="40px" borderRadius="md" />
      </Flex>
      <TourListSkeleton />
    </Box>
  );

  return (
    <Box p={4} bg={bgColor} minH="100vh">
      <Box mb={8}>
        <VStack spacing={4} align="stretch">
          <Flex 
            justify="space-between" 
            align="center" 
            wrap={{ base: "wrap", md: "nowrap" }}
            gap={4}
          >
            <Heading 
              size={{ base: "lg", md: "xl" }}
              bgGradient="linear(to-r, purple.600, pink.500)"
              bgClip="text"
            >
              My Locations
            </Heading>
            <Button
              onClick={() => setDrawerOpen(true)}
              colorScheme="purple"
              size={{ base: "sm", md: "md" }}
              leftIcon={<Icon as={PlusIcon} />}
              bgGradient="linear(to-r, purple.400, purple.600)"
              _hover={{
                bgGradient: "linear(to-r, purple.500, purple.700)",
                transform: "translateY(-1px)",
                shadow: "md"
              }}
              data-tour="add-location-button"
            >
              Add New Location
            </Button>
          </Flex>

          <InputGroup size={{ base: "md", md: "lg" }}>
            <InputLeftElement pointerEvents="none">
              <Icon as={SearchIcon} color={searchIconColor} />
            </InputLeftElement>
            <Input
              placeholder="Search locations by name or description..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              bg={searchBgColor}
              borderColor={searchBorderColor}
              _hover={{ borderColor: "purple.400" }}
              _focus={{ 
                borderColor: "purple.400", 
                boxShadow: "0 0 0 1px var(--chakra-colors-purple-400)" 
              }}
              borderRadius="lg"
              fontSize={{ base: "sm", md: "md" }}
            />
            {searchTerm && (
              <InputRightElement>
                <IconButton
                  size="sm"
                  onClick={() => setSearchTerm("")}
                  icon={<Icon as={XIcon} />}
                  variant="ghost"
                  colorScheme="purple"
                  aria-label="Clear search"
                />
              </InputRightElement>
            )}
          </InputGroup>
        </VStack>
      </Box>

      {filteredLocations.length === 0 ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          minH="60vh"
          p={8}
          textAlign="center"
        >
          <Icon as={MapPinIcon} boxSize={12} color="gray.400" mb={4} />
          <Text fontSize="xl" fontWeight="medium" color="gray.600" mb={2}>
            No Locations Yet
          </Text>
          <Text color="gray.500" mb={4}>
            Add your first food spot to start creating amazing tours
          </Text>
        </Box>
      ) : (
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
          {filteredLocations.map((location: any) => {
            return (
              <Card
                key={location.id}
                bg={cardBgColor}
                borderRadius="xl"
                boxShadow="lg"
                overflow="hidden"
                transition="all 0.3s"
                _hover={{ 
                  transform: "translateY(-4px)", 
                  shadow: "xl",
                  borderColor: "purple.400" 
                }}
                cursor="pointer"
                onClick={() => handleLocationClick(location.id)}
                position="relative"
                borderWidth="1px"
                borderColor={borderColor}
              >
                <CardBody p={6}>
                  <VStack align="stretch" spacing={4}>
                    <VStack align="start" spacing={2}>
                      <Text 
                        fontWeight="bold" 
                        fontSize={{ base: "lg", md: "xl" }}
                        color={textColor}
                        noOfLines={1}
                      >
                        {location.title}
                      </Text>
                    </VStack>

                    <Text 
                      color={secondaryTextColor} 
                      fontSize="sm"
                      noOfLines={2}
                    >
                      {location.description}
                    </Text>
                  </VStack>
                </CardBody>
              </Card>
            );
          })}
        </SimpleGrid>
      )}

      <LocationDrawer
        isOpen={isDrawerOpen}
        onClose={() => setDrawerOpen(false)}
      />
    </Box>
  );
};

export default LocationsPage; 
