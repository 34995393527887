import { Box } from "@chakra-ui/react";
import { useEffect, useRef, useCallback } from "react";
import Radar from "radar-sdk-js";
import type { RadarMapOptions } from "radar-sdk-js/dist/types";
import "radar-sdk-js/dist/radar.css";
import env from "../env";

export type Place = {
  latitude: number;
  longitude: number;
  name: string;
  address?: string;
};

type RadarMapEmbedProps = {
  onSelectPlace: (place: Place) => void;
  initialLocation?: { lat: number; lng: number };
  categories?: string[];
  radius?: number;
  mode?: 'detail' | 'create';
};

const parseRadarPlace = (p: any): Place => ({
  latitude: p.location?.coordinates[1] ?? 0,
  longitude: p.location?.coordinates[0] ?? 0,
  name: p.name ?? "Unknown",
  address: p.formattedAddress ?? ""
});

export const RadarMapEmbed = ({
  onSelectPlace,
  initialLocation,
  categories = ["restaurant"],
  radius = 10000,
  mode = 'create'
}: RadarMapEmbedProps) => {
  const mapContainer = useRef<HTMLDivElement>(null);
  const map = useRef<any>(null);
  const markersRef = useRef<any[]>([]);
  const isInitialized = useRef(false);

  const clearMarkers = useCallback(() => {
    if (markersRef.current.length > 0) {
      markersRef.current.forEach(marker => marker.remove());
      markersRef.current = [];
    }
  }, []);

  const addMarker = useCallback((place: Place, isDetail: boolean = false) => {
    if (!map.current) return;

    const marker = Radar.ui.marker({
      color: "#9F7AEA",
      draggable: false,
      popup: isDetail ? {
        html: `
          <div style="padding: 8px">
            <strong>${place.name}</strong>
            ${place.address ? `<p>${place.address}</p>` : ''}
          </div>
        `
      } : {
        html: `
          <div style="padding: 8px">
            <strong>${place.name}</strong>
            ${place.address ? `<p>${place.address}</p>` : ''}
            <button 
              data-place='${JSON.stringify(place)}'
              onclick="window.dispatchEvent(new CustomEvent('selectPlace', { detail: this.dataset.place }))"
              style="background: #9F7AEA; color: white; padding: 4px 8px; border-radius: 4px; border: none; margin-top: 8px"
            >
              Select Location
            </button>
          </div>
        `
      }
    });

    marker.setLngLat([place.longitude, place.latitude]).addTo(map.current);
    markersRef.current.push(marker);
  }, []);

  const initializeMap = useCallback(async () => {
    if (!mapContainer.current || map.current || isInitialized.current) return;

    try {
      // Initialize Radar with publishable key
      Radar.initialize(env.REACT_APP_RADAR_TOKEN);

      const center = initialLocation 
        ? [initialLocation.lng, initialLocation.lat] as [number, number]
        : [-74.006, 40.7128] as [number, number];

      const mapOptions: RadarMapOptions = {
        container: mapContainer.current,
        style: "radar-default-v1",
        center,
        zoom: mode === 'detail' ? 15 : 12,
        showZoomControls: true,
      };

      // Create the map
      map.current = Radar.ui.map(mapOptions);

      // Wait for the map to load before adding markers
      map.current.on('load', () => {
        if (mode === 'detail' && initialLocation) {
          addMarker({
            latitude: initialLocation.lat,
            longitude: initialLocation.lng,
            name: "Selected Location",
            address: ""
          }, true);
        }
      });

      isInitialized.current = true;
    } catch (error) {
      console.error('Error initializing Radar map:', error);
      throw error;
    }
  }, [initialLocation, mode, addMarker]);

  useEffect(() => {
    const initMap = async () => {
      try {
        await initializeMap();
      } catch (error) {
        console.error('Failed to initialize map:', error);
      }
    };

    initMap();

    return () => {
      clearMarkers();
      if (map.current) {
        map.current.remove();
        map.current = null;
      }
      isInitialized.current = false;
    };
  }, [initializeMap, clearMarkers]);

  return (
    <Box position="relative" h="100%" minH="300px">
      <div
        ref={mapContainer}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          borderRadius: "8px",
        }}
      />
    </Box>
  );
};
