import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  Upload: { input: any; output: any; }
};

export type BeginAuthResponse = {
  __typename?: 'BeginAuthResponse';
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type CreateLocationInput = {
  address: Scalars['String']['input'];
  description: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type Event = {
  __typename?: 'Event';
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  link?: Maybe<Scalars['String']['output']>;
  participant?: Maybe<TourParticipant>;
  tourId?: Maybe<Scalars['ID']['output']>;
  tourTitle?: Maybe<Scalars['String']['output']>;
  type: EventType;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type EventFilter = {
  onlyParticipant?: InputMaybe<Scalars['Boolean']['input']>;
  participantId?: InputMaybe<Scalars['ID']['input']>;
  tourId?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<EventType>;
};

export enum EventType {
  FeedbackSubmitted = 'FEEDBACK_SUBMITTED',
  InviteBounced = 'INVITE_BOUNCED',
  InviteClicked = 'INVITE_CLICKED',
  InviteDelivered = 'INVITE_DELIVERED',
  InviteOpened = 'INVITE_OPENED',
  InviteResent = 'INVITE_RESENT',
  InviteSent = 'INVITE_SENT',
  InviteSpam = 'INVITE_SPAM',
  LocationCreated = 'LOCATION_CREATED',
  LocationDeleted = 'LOCATION_DELETED',
  LocationUpdated = 'LOCATION_UPDATED',
  MessageClicked = 'MESSAGE_CLICKED',
  MessageOpened = 'MESSAGE_OPENED',
  MessageSent = 'MESSAGE_SENT',
  ParticipantJoined = 'PARTICIPANT_JOINED',
  ParticipantLeft = 'PARTICIPANT_LEFT',
  PaymentFailed = 'PAYMENT_FAILED',
  PaymentSuccessful = 'PAYMENT_SUCCESSFUL',
  ResponseSubmitted = 'RESPONSE_SUBMITTED',
  TourCompleted = 'TOUR_COMPLETED',
  TourDeleted = 'TOUR_DELETED',
  TourStarted = 'TOUR_STARTED',
  VoteSubmitted = 'VOTE_SUBMITTED'
}

export type Feedback = {
  __typename?: 'Feedback';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  participantEmail: Scalars['String']['output'];
  rating: Scalars['Int']['output'];
  text: Scalars['String']['output'];
};

export type GenerationsCount = {
  __typename?: 'GenerationsCount';
  limit: Scalars['Int']['output'];
  remaining: Scalars['Int']['output'];
  resetAt: Scalars['DateTime']['output'];
};

export enum InviteStatus {
  Accepted = 'ACCEPTED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export type JoinTourInput = {
  email: Scalars['String']['input'];
  emailNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
};

export type Location = {
  __typename?: 'Location';
  address: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type LoginInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type LogoutResponse = {
  __typename?: 'LogoutResponse';
  success: Scalars['Boolean']['output'];
};

export type Media = {
  __typename?: 'Media';
  data?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  mimeType?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type Message = {
  __typename?: 'Message';
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  creatorEmail: Scalars['String']['output'];
  creatorId: Scalars['ID']['output'];
  creatorUsername: Scalars['String']['output'];
  from: UserType;
  id: Scalars['ID']['output'];
  participantEmail: Scalars['String']['output'];
  participantId: Scalars['ID']['output'];
  participantName?: Maybe<Scalars['String']['output']>;
  to: UserType;
  tourId: Scalars['ID']['output'];
  tourTitle: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type MessageResponse = {
  __typename?: 'MessageResponse';
  success: Scalars['Boolean']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptInvite: TourParticipant;
  blockParticipant: Scalars['Boolean']['output'];
  bulkInvite: Array<TourInvite>;
  createBillingPortalSession: Scalars['String']['output'];
  createLocation: Location;
  generateShortLink: Scalars['String']['output'];
  generateText: Scalars['String']['output'];
  getConnectAccountLink: Scalars['String']['output'];
  joinTour: TourParticipant;
  login: BeginAuthResponse;
  logout: LogoutResponse;
  markAsRead: Scalars['Boolean']['output'];
  markMessagesAsRead: MessageResponse;
  recordResponse: RecordResponsePayload;
  recordVote: Scalars['Boolean']['output'];
  registerTour: Tour;
  removeTour: Scalars['Boolean']['output'];
  sendMessage: MessageResponse;
  sendMessageToCreator: MessageResponse;
  sendMessageToParticipant: MessageResponse;
  sendParticipantInvite: Array<TourInvite>;
  signup: BeginAuthResponse;
  submitFeedback: Scalars['Boolean']['output'];
  submitVote: Scalars['Boolean']['output'];
  updateLocation: Location;
  updateParticipantNotifications: Scalars['Boolean']['output'];
  updateProfile: User;
  uploadMedia: Array<Media>;
  verify: VerifiedAuthResponse;
};


export type MutationAcceptInviteArgs = {
  inviteId: Scalars['ID']['input'];
};


export type MutationBlockParticipantArgs = {
  participantId: Scalars['ID']['input'];
  tourId: Scalars['ID']['input'];
};


export type MutationBulkInviteArgs = {
  file: Scalars['Upload']['input'];
  tourId: Scalars['ID']['input'];
};


export type MutationCreateLocationArgs = {
  input: CreateLocationInput;
};


export type MutationGenerateShortLinkArgs = {
  link: Scalars['String']['input'];
  participantId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationGenerateTextArgs = {
  input: Scalars['String']['input'];
};


export type MutationJoinTourArgs = {
  input: JoinTourInput;
  tourId: Scalars['ID']['input'];
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationMarkAsReadArgs = {
  notificationId: Scalars['ID']['input'];
};


export type MutationMarkMessagesAsReadArgs = {
  participantId: Scalars['ID']['input'];
  tourId: Scalars['ID']['input'];
};


export type MutationRecordResponseArgs = {
  input: RecordResponseInput;
};


export type MutationRecordVoteArgs = {
  locationId: Scalars['ID']['input'];
  participantId: Scalars['ID']['input'];
};


export type MutationRegisterTourArgs = {
  input: RegisterTourInput;
};


export type MutationRemoveTourArgs = {
  tourId: Scalars['ID']['input'];
};


export type MutationSendMessageArgs = {
  content: Scalars['String']['input'];
  tourId: Scalars['ID']['input'];
};


export type MutationSendMessageToCreatorArgs = {
  content: Scalars['String']['input'];
  participantId: Scalars['ID']['input'];
  tourId: Scalars['ID']['input'];
};


export type MutationSendMessageToParticipantArgs = {
  content: Scalars['String']['input'];
  participantEmail: Scalars['String']['input'];
  tourId: Scalars['ID']['input'];
};


export type MutationSendParticipantInviteArgs = {
  emails: Array<Scalars['String']['input']>;
  participantId?: InputMaybe<Scalars['ID']['input']>;
  tourId: Scalars['ID']['input'];
};


export type MutationSignupArgs = {
  input: SignupInput;
};


export type MutationSubmitFeedbackArgs = {
  feedback: Scalars['String']['input'];
  participantId: Scalars['ID']['input'];
  rating: Scalars['Int']['input'];
};


export type MutationSubmitVoteArgs = {
  locationId: Scalars['ID']['input'];
};


export type MutationUpdateLocationArgs = {
  id: Scalars['ID']['input'];
  input: UpdateLocationInput;
};


export type MutationUpdateParticipantNotificationsArgs = {
  emailNotifications: Scalars['Boolean']['input'];
  participantId: Scalars['ID']['input'];
};


export type MutationUpdateProfileArgs = {
  input: UpdateProfileInput;
};


export type MutationUploadMediaArgs = {
  files: Array<UploadMediaInput>;
};


export type MutationVerifyArgs = {
  input: VerifyInput;
};

export type Notification = {
  __typename?: 'Notification';
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  isRead?: Maybe<Scalars['Boolean']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  tourParticipantId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type Participant = {
  __typename?: 'Participant';
  email: Scalars['String']['output'];
  emailNotifications?: Maybe<Scalars['Boolean']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isBlocked?: Maybe<Scalars['Boolean']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  progress?: Maybe<Scalars['Int']['output']>;
  startedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ParticipantTour = {
  __typename?: 'ParticipantTour';
  address?: Maybe<Scalars['String']['output']>;
  allowInvites?: Maybe<Scalars['Boolean']['output']>;
  allowRetries?: Maybe<Scalars['Boolean']['output']>;
  allowReviews?: Maybe<Scalars['Boolean']['output']>;
  color?: Maybe<Scalars['String']['output']>;
  creatorName?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  isPublic?: Maybe<Scalars['Boolean']['output']>;
  locations?: Maybe<Array<Maybe<Location>>>;
  logo?: Maybe<Scalars['String']['output']>;
  paymentFee?: Maybe<Scalars['Int']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  title: Scalars['String']['output'];
};

export type ParticipantsInput = {
  includeBlocked?: InputMaybe<Scalars['Boolean']['input']>;
  includeOrExclude?: InputMaybe<TourIncludeOrExclude>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  participantId?: InputMaybe<Scalars['ID']['input']>;
  tourId?: InputMaybe<Scalars['ID']['input']>;
};

export type Query = {
  __typename?: 'Query';
  events: Array<Event>;
  feedback: Array<Feedback>;
  generationsToday: GenerationsCount;
  location?: Maybe<Location>;
  locations: Array<Location>;
  me?: Maybe<User>;
  media: Scalars['String']['output'];
  message: Array<Message>;
  notifications: Array<Notification>;
  participantStatus: TourParticipantStatus;
  participants: Array<Participant>;
  publicTour?: Maybe<ParticipantTour>;
  stats: Stats;
  submissions: Array<Submission>;
  tour?: Maybe<Tour>;
  tourParticipantsAndLocations: Array<TourDetails>;
  tours: Array<Tour>;
  unreadMessages: Array<Message>;
  votes: Array<Vote>;
};


export type QueryEventsArgs = {
  filter?: InputMaybe<EventFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFeedbackArgs = {
  tourId: Scalars['ID']['input'];
};


export type QueryLocationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryLocationsArgs = {
  participantEmail?: InputMaybe<Scalars['String']['input']>;
  tourId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryMediaArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMessageArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  participantId: Scalars['ID']['input'];
};


export type QueryNotificationsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  notRead?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryParticipantStatusArgs = {
  participantId: Scalars['ID']['input'];
  tourId: Scalars['ID']['input'];
};


export type QueryParticipantsArgs = {
  input?: InputMaybe<ParticipantsInput>;
};


export type QueryPublicTourArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySubmissionsArgs = {
  tourId: Scalars['ID']['input'];
};


export type QueryTourArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTourParticipantsAndLocationsArgs = {
  tourId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryToursArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryUnreadMessagesArgs = {
  participantId: Scalars['ID']['input'];
  tourId: Scalars['ID']['input'];
};


export type QueryVotesArgs = {
  tourId: Scalars['ID']['input'];
};

export type RecordResponseInput = {
  locationId: Scalars['ID']['input'];
  participantId: Scalars['ID']['input'];
  responses: Array<Scalars['String']['input']>;
  tourId: Scalars['ID']['input'];
};

export type RecordResponsePayload = {
  __typename?: 'RecordResponsePayload';
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type RegisterTourInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  allowInvites?: InputMaybe<Scalars['Boolean']['input']>;
  allowRetries?: InputMaybe<Scalars['Boolean']['input']>;
  allowReviews?: InputMaybe<Scalars['Boolean']['input']>;
  backgroundMediaId?: InputMaybe<Scalars['ID']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  isVirtual?: InputMaybe<Scalars['Boolean']['input']>;
  locations?: InputMaybe<Array<Scalars['String']['input']>>;
  logoMediaId?: InputMaybe<Scalars['ID']['input']>;
  paymentFee?: InputMaybe<Scalars['Int']['input']>;
  radius?: InputMaybe<Scalars['Float']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type SignupInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  mediaId?: InputMaybe<Scalars['String']['input']>;
  profilePicture?: InputMaybe<Scalars['String']['input']>;
  username: Scalars['String']['input'];
};

export type Stats = {
  __typename?: 'Stats';
  activeTours: Scalars['Int']['output'];
  completionRate: Scalars['Int']['output'];
  totalParticipants: Scalars['Int']['output'];
};

export type Submission = {
  __typename?: 'Submission';
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  locationName: Scalars['String']['output'];
  media: Array<Media>;
  participantEmail: Scalars['String']['output'];
  participantName?: Maybe<Scalars['String']['output']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  eventAdded: Event;
  messageAdded: Message;
  notificationAdded: Notification;
};

export type Tour = {
  __typename?: 'Tour';
  address: Scalars['String']['output'];
  allowInvites: Scalars['Boolean']['output'];
  allowRetries: Scalars['Boolean']['output'];
  allowReviews: Scalars['Boolean']['output'];
  backgroundMediaId?: Maybe<Scalars['ID']['output']>;
  color?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  endDate: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  isPublic: Scalars['Boolean']['output'];
  locationCount: Scalars['Int']['output'];
  logoMediaId?: Maybe<Scalars['ID']['output']>;
  participantCount: Scalars['Int']['output'];
  paymentFee?: Maybe<Scalars['Int']['output']>;
  progress: Scalars['Int']['output'];
  radius: Scalars['Float']['output'];
  slug: Scalars['String']['output'];
  startDate: Scalars['DateTime']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type TourDetails = {
  __typename?: 'TourDetails';
  allowInvites: Scalars['Boolean']['output'];
  allowRetries: Scalars['Boolean']['output'];
  allowReviews: Scalars['Boolean']['output'];
  backgroundMediaId?: Maybe<Scalars['ID']['output']>;
  color?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  endDate: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  isPublic: Scalars['Boolean']['output'];
  locations: Array<Location>;
  logoMediaId?: Maybe<Scalars['ID']['output']>;
  participants: Array<TourParticipant>;
  paymentFee?: Maybe<Scalars['Int']['output']>;
  progress: Scalars['Int']['output'];
  slug: Scalars['String']['output'];
  startDate: Scalars['DateTime']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export enum TourIncludeOrExclude {
  Exclude = 'EXCLUDE',
  Include = 'INCLUDE'
}

export type TourInvite = {
  __typename?: 'TourInvite';
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  expiresAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  status: InviteStatus;
  updatedAt: Scalars['DateTime']['output'];
};

export type TourParticipant = {
  __typename?: 'TourParticipant';
  email: Scalars['String']['output'];
  emailNotifications: Scalars['Boolean']['output'];
  finishedAt?: Maybe<Scalars['DateTime']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isBlocked: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
  progress: Scalars['Int']['output'];
  startedAt: Scalars['DateTime']['output'];
  tour: ParticipantTour;
};

export type TourParticipantStatus = {
  __typename?: 'TourParticipantStatus';
  emailMessageNotifications: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isBlocked: Scalars['Boolean']['output'];
};

export type UpdateLocationInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateProfileInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  emailFeedbackNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  emailJoinNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  emailLocationNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  emailMessageNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  emailPaymentNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  emailPaymentReminderNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  emailReportNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  isFirstTime?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mediaId?: InputMaybe<Scalars['String']['input']>;
  onboardingCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  onboardingStep?: InputMaybe<Scalars['Int']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type UploadMediaInput = {
  docType: Scalars['String']['input'];
  file: Scalars['Upload']['input'];
};

export type User = {
  __typename?: 'User';
  createdTours?: Maybe<Array<Maybe<Tour>>>;
  email?: Maybe<Scalars['String']['output']>;
  emailFeedbackNotifications?: Maybe<Scalars['Boolean']['output']>;
  emailJoinNotifications?: Maybe<Scalars['Boolean']['output']>;
  emailLocationNotifications?: Maybe<Scalars['Boolean']['output']>;
  emailMessageNotifications?: Maybe<Scalars['Boolean']['output']>;
  emailPaymentNotifications?: Maybe<Scalars['Boolean']['output']>;
  emailPaymentReminderNotifications?: Maybe<Scalars['Boolean']['output']>;
  emailReportNotifications?: Maybe<Scalars['Boolean']['output']>;
  firstLoggedInAt?: Maybe<Scalars['DateTime']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  mediaId?: Maybe<Scalars['String']['output']>;
  onboardingCompletedAt?: Maybe<Scalars['DateTime']['output']>;
  onboardingStep?: Maybe<Scalars['Int']['output']>;
  participants?: Maybe<Array<Maybe<TourParticipant>>>;
  profilePicture?: Maybe<Scalars['String']['output']>;
  userType?: Maybe<UserType>;
  username?: Maybe<Scalars['String']['output']>;
};

export enum UserType {
  Basic = 'BASIC',
  Business = 'BUSINESS',
  Creator = 'CREATOR',
  Free = 'FREE',
  Participant = 'PARTICIPANT',
  Pro = 'PRO'
}

export type VerifiedAuthResponse = {
  __typename?: 'VerifiedAuthResponse';
  redirectUrl?: Maybe<Scalars['String']['output']>;
  token: Scalars['String']['output'];
  user: User;
};

export type VerifyInput = {
  code: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  isFirstTime?: InputMaybe<Scalars['Boolean']['input']>;
  redirectUrl?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type Vote = {
  __typename?: 'Vote';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  locationName: Scalars['String']['output'];
  locationPoints: Scalars['Int']['output'];
};

export type LoginMutationVariables = Exact<{
  input: LoginInput;
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'BeginAuthResponse', message?: string | null, success?: boolean | null } };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', logout: { __typename?: 'LogoutResponse', success: boolean } };

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = { __typename?: 'Query', me?: { __typename?: 'User', id?: string | null, email?: string | null, username?: string | null, firstName?: string | null, userType?: UserType | null, lastName?: string | null, profilePicture?: string | null, firstLoggedInAt?: any | null, onboardingStep?: number | null, onboardingCompletedAt?: any | null, emailMessageNotifications?: boolean | null, emailFeedbackNotifications?: boolean | null, emailJoinNotifications?: boolean | null, emailLocationNotifications?: boolean | null, emailPaymentNotifications?: boolean | null, emailReportNotifications?: boolean | null, emailPaymentReminderNotifications?: boolean | null } | null };

export type SignupMutationVariables = Exact<{
  input: SignupInput;
}>;


export type SignupMutation = { __typename?: 'Mutation', signup: { __typename?: 'BeginAuthResponse', message?: string | null, success?: boolean | null } };

export type UpdateProfileMutationVariables = Exact<{
  input: UpdateProfileInput;
}>;


export type UpdateProfileMutation = { __typename?: 'Mutation', updateProfile: { __typename?: 'User', id?: string | null, username?: string | null, email?: string | null, mediaId?: string | null, firstName?: string | null, lastName?: string | null, userType?: UserType | null, profilePicture?: string | null, onboardingStep?: number | null, onboardingCompletedAt?: any | null, firstLoggedInAt?: any | null, emailMessageNotifications?: boolean | null, emailFeedbackNotifications?: boolean | null, emailJoinNotifications?: boolean | null, emailLocationNotifications?: boolean | null, emailPaymentNotifications?: boolean | null, emailReportNotifications?: boolean | null, emailPaymentReminderNotifications?: boolean | null } };

export type VerifyMutationVariables = Exact<{
  input: VerifyInput;
}>;


export type VerifyMutation = { __typename?: 'Mutation', verify: { __typename?: 'VerifiedAuthResponse', token: string, user: { __typename?: 'User', id?: string | null, firstLoggedInAt?: any | null, onboardingStep?: number | null, onboardingCompletedAt?: any | null, email?: string | null, username?: string | null, firstName?: string | null, lastName?: string | null, profilePicture?: string | null } } };

export type GenerateShortLinkMutationVariables = Exact<{
  link: Scalars['String']['input'];
}>;


export type GenerateShortLinkMutation = { __typename?: 'Mutation', generateShortLink: string };

export type GenerateTextMutationVariables = Exact<{
  input: Scalars['String']['input'];
}>;


export type GenerateTextMutation = { __typename?: 'Mutation', generateText: string };

export type GetGenerationsTodayQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGenerationsTodayQuery = { __typename?: 'Query', generationsToday: { __typename?: 'GenerationsCount', remaining: number, limit: number, resetAt: any } };

export type GetMediaQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetMediaQuery = { __typename?: 'Query', media: string };

export type GetSubmissionsQueryVariables = Exact<{
  tourId: Scalars['ID']['input'];
}>;


export type GetSubmissionsQuery = { __typename?: 'Query', submissions: Array<{ __typename?: 'Submission', participantEmail: string, locationName: string, media: Array<{ __typename?: 'Media', id: string, url?: string | null, data?: string | null }> }> };

export type UploadMediaMutationVariables = Exact<{
  files: Array<UploadMediaInput> | UploadMediaInput;
}>;


export type UploadMediaMutation = { __typename?: 'Mutation', uploadMedia: Array<{ __typename?: 'Media', id: string }> };

export type GetMessagesThreadQueryVariables = Exact<{
  participantId: Scalars['ID']['input'];
  code?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetMessagesThreadQuery = { __typename?: 'Query', message: Array<{ __typename?: 'Message', id: string, content: string, createdAt: any, updatedAt: any, tourTitle: string, participantId: string, participantEmail: string, participantName?: string | null, from: UserType, to: UserType, creatorId: string, creatorEmail: string, creatorUsername: string, tourId: string }> };

export type GetUnreadMessagesQueryVariables = Exact<{
  tourId: Scalars['ID']['input'];
  participantId: Scalars['ID']['input'];
}>;


export type GetUnreadMessagesQuery = { __typename?: 'Query', unreadMessages: Array<{ __typename?: 'Message', id: string, content: string, createdAt: any, updatedAt: any, tourTitle: string, tourId: string, participantId: string, participantEmail: string, participantName?: string | null, from: UserType, to: UserType, creatorId: string, creatorEmail: string, creatorUsername: string }> };

export type MarkMessagesAsReadMutationVariables = Exact<{
  tourId: Scalars['ID']['input'];
  participantId: Scalars['ID']['input'];
}>;


export type MarkMessagesAsReadMutation = { __typename?: 'Mutation', markMessagesAsRead: { __typename?: 'MessageResponse', success: boolean } };

export type MessageSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type MessageSubscription = { __typename?: 'Subscription', messageAdded: { __typename?: 'Message', id: string, content: string, from: UserType, createdAt: any, participantName?: string | null, creatorUsername: string } };

export type SendMessageToAllParticipantsMutationVariables = Exact<{
  tourId: Scalars['ID']['input'];
  content: Scalars['String']['input'];
}>;


export type SendMessageToAllParticipantsMutation = { __typename?: 'Mutation', sendMessage: { __typename?: 'MessageResponse', success: boolean } };

export type SendMessageToParticipantMutationVariables = Exact<{
  tourId: Scalars['ID']['input'];
  content: Scalars['String']['input'];
  participantEmail: Scalars['String']['input'];
}>;


export type SendMessageToParticipantMutation = { __typename?: 'Mutation', sendMessageToParticipant: { __typename?: 'MessageResponse', success: boolean } };

export type EventAddedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type EventAddedSubscription = { __typename?: 'Subscription', eventAdded: { __typename?: 'Event', id: string, type: EventType, content?: string | null, createdAt?: any | null, updatedAt?: any | null, participant?: { __typename?: 'TourParticipant', id: string, email: string, firstName: string, lastName: string, tour: { __typename?: 'ParticipantTour', id: string, title: string } } | null } };

export type GetEventsQueryVariables = Exact<{
  filter?: InputMaybe<EventFilter>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
}>;


export type GetEventsQuery = { __typename?: 'Query', events: Array<{ __typename?: 'Event', id: string, type: EventType, content?: string | null, tourTitle?: string | null, tourId?: string | null, createdAt?: any | null, updatedAt?: any | null, participant?: { __typename?: 'TourParticipant', id: string, email: string, firstName: string, lastName: string, tour: { __typename?: 'ParticipantTour', id: string, title: string } } | null }> };

export type MarkAsReadMutationVariables = Exact<{
  notificationId: Scalars['ID']['input'];
}>;


export type MarkAsReadMutation = { __typename?: 'Mutation', markAsRead: boolean };

export type NotificationAddedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type NotificationAddedSubscription = { __typename?: 'Subscription', notificationAdded: { __typename?: 'Notification', type?: string | null, userId?: string | null, content?: string | null, tourParticipantId?: string | null, title?: string | null, link?: string | null, isRead?: boolean | null, id: string } };

export type NotificationsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;


export type NotificationsQuery = { __typename?: 'Query', notifications: Array<{ __typename?: 'Notification', id: string, type?: string | null, userId?: string | null, content?: string | null, tourParticipantId?: string | null, title?: string | null, link?: string | null, isRead?: boolean | null, createdAt?: any | null, updatedAt?: any | null }> };

export type UnreadNotificationsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;


export type UnreadNotificationsQuery = { __typename?: 'Query', notifications: Array<{ __typename?: 'Notification', id: string, type?: string | null, userId?: string | null, content?: string | null, tourParticipantId?: string | null, title?: string | null, link?: string | null, isRead?: boolean | null, createdAt?: any | null, updatedAt?: any | null }> };

export type CreateBillingPortalSessionMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateBillingPortalSessionMutation = { __typename?: 'Mutation', createBillingPortalSession: string };

export type GetConnectAccountLinkMutationVariables = Exact<{ [key: string]: never; }>;


export type GetConnectAccountLinkMutation = { __typename?: 'Mutation', getConnectAccountLink: string };

export type BlockParticipantMutationVariables = Exact<{
  tourId: Scalars['ID']['input'];
  participantId: Scalars['ID']['input'];
}>;


export type BlockParticipantMutation = { __typename?: 'Mutation', blockParticipant: boolean };

export type CreateLocationMutationVariables = Exact<{
  input: CreateLocationInput;
}>;


export type CreateLocationMutation = { __typename?: 'Mutation', createLocation: { __typename?: 'Location', id: string, title: string, description: string, address: string, createdAt: any, updatedAt: any } };

export type CreateTourMutationVariables = Exact<{
  input: RegisterTourInput;
}>;


export type CreateTourMutation = { __typename?: 'Mutation', registerTour: { __typename?: 'Tour', id: string, title: string, description?: string | null, createdAt: any, updatedAt: any } };

export type EditTourMutationVariables = Exact<{
  input: RegisterTourInput;
}>;


export type EditTourMutation = { __typename?: 'Mutation', registerTour: { __typename?: 'Tour', id: string, title: string, description?: string | null, color?: string | null, startDate: any, endDate: any, address: string, radius: number, isPublic: boolean, allowReviews: boolean, allowRetries: boolean, allowInvites: boolean, slug: string, backgroundMediaId?: string | null, logoMediaId?: string | null, createdAt: any, updatedAt: any } };

export type GetFeedbackQueryVariables = Exact<{
  tourId: Scalars['ID']['input'];
}>;


export type GetFeedbackQuery = { __typename?: 'Query', feedback: Array<{ __typename?: 'Feedback', id: string, participantEmail: string, rating: number, text: string, createdAt: any }> };

export type GetLocationQueryVariables = Exact<{
  locationId: Scalars['ID']['input'];
}>;


export type GetLocationQuery = { __typename?: 'Query', location?: { __typename?: 'Location', id: string, title: string, description: string, address: string, createdAt: any, updatedAt: any } | null };

export type GetLocationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLocationsQuery = { __typename?: 'Query', locations: Array<{ __typename?: 'Location', id: string, title: string, description: string, address: string, createdAt: any, updatedAt: any }> };

export type GetParticipantLocationsQueryVariables = Exact<{
  tourId: Scalars['ID']['input'];
  participantEmail?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetParticipantLocationsQuery = { __typename?: 'Query', locations: Array<{ __typename?: 'Location', id: string, title: string, description: string, address: string, createdAt: any, updatedAt: any }> };

export type GetParticipantsQueryVariables = Exact<{
  input: ParticipantsInput;
}>;


export type GetParticipantsQuery = { __typename?: 'Query', participants: Array<{ __typename?: 'Participant', id: string, email: string, firstName?: string | null, lastName?: string | null }> };

export type GetPublicTourDetailsQueryVariables = Exact<{
  tourId: Scalars['ID']['input'];
}>;


export type GetPublicTourDetailsQuery = { __typename?: 'Query', publicTour?: { __typename?: 'ParticipantTour', id: string, title: string, description?: string | null, image?: string | null, logo?: string | null, color?: string | null, paymentFee?: number | null, startDate?: any | null, endDate?: any | null, address?: string | null, isPublic?: boolean | null, allowInvites?: boolean | null, allowRetries?: boolean | null, allowReviews?: boolean | null, locations?: Array<{ __typename?: 'Location', id: string, title: string, description: string, address: string } | null> | null } | null };

export type GetStatsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStatsQuery = { __typename?: 'Query', stats: { __typename?: 'Stats', activeTours: number, totalParticipants: number, completionRate: number } };

export type GetTourQueryVariables = Exact<{
  tourId: Scalars['ID']['input'];
}>;


export type GetTourQuery = { __typename?: 'Query', tour?: { __typename?: 'Tour', id: string, title: string, description?: string | null, image?: string | null, color?: string | null, startDate: any, paymentFee?: number | null, endDate: any, address: string, radius: number, isPublic: boolean, allowReviews: boolean, allowRetries: boolean, allowInvites: boolean, createdAt: any, updatedAt: any, locationCount: number, participantCount: number, progress: number } | null };

export type GetTourLocationsQueryVariables = Exact<{
  tourId?: InputMaybe<Scalars['ID']['input']>;
}>;


export type GetTourLocationsQuery = { __typename?: 'Query', tourParticipantsAndLocations: Array<{ __typename?: 'TourDetails', id: string, title: string, description?: string | null, image?: string | null, color?: string | null, startDate: any, endDate: any, locations: Array<{ __typename?: 'Location', id: string, title: string, address: string }> }> };

export type GetToursQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetToursQuery = { __typename?: 'Query', tours: Array<{ __typename?: 'Tour', id: string, title: string, description?: string | null, image?: string | null, color?: string | null, startDate: any, endDate: any, address: string, radius: number, isPublic: boolean, allowReviews: boolean, allowRetries: boolean, allowInvites: boolean, createdAt: any, updatedAt: any, locationCount: number, participantCount: number, progress: number }> };

export type GetToursWithParticipantsQueryVariables = Exact<{
  tourId?: InputMaybe<Scalars['ID']['input']>;
}>;


export type GetToursWithParticipantsQuery = { __typename?: 'Query', tourParticipantsAndLocations: Array<{ __typename?: 'TourDetails', id: string, title: string, description?: string | null, image?: string | null, color?: string | null, startDate: any, endDate: any, createdAt: any, updatedAt: any, isPublic: boolean, allowReviews: boolean, allowRetries: boolean, allowInvites: boolean, slug: string, progress: number, backgroundMediaId?: string | null, logoMediaId?: string | null, paymentFee?: number | null, locations: Array<{ __typename?: 'Location', id: string, title: string, description: string, address: string, createdAt: any, updatedAt: any }>, participants: Array<{ __typename?: 'TourParticipant', id: string, email: string, firstName: string, lastName: string, progress: number, startedAt: any, finishedAt?: any | null, isBlocked: boolean, emailNotifications: boolean, tour: { __typename?: 'ParticipantTour', id: string, title: string, description?: string | null, image?: string | null, color?: string | null } }> }> };

export type GetVotesQueryVariables = Exact<{
  tourId: Scalars['ID']['input'];
}>;


export type GetVotesQuery = { __typename?: 'Query', votes: Array<{ __typename?: 'Vote', id: string, locationName: string, locationPoints: number, createdAt: any }> };

export type RemoveTourMutationVariables = Exact<{
  tourId: Scalars['ID']['input'];
}>;


export type RemoveTourMutation = { __typename?: 'Mutation', removeTour: boolean };

export type SendParticipantInviteMutationVariables = Exact<{
  tourId: Scalars['ID']['input'];
  emails: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type SendParticipantInviteMutation = { __typename?: 'Mutation', sendParticipantInvite: Array<{ __typename?: 'TourInvite', id: string, email: string }> };

export type UpdateLocationMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdateLocationInput;
}>;


export type UpdateLocationMutation = { __typename?: 'Mutation', updateLocation: { __typename?: 'Location', id: string, title: string, description: string, address: string, createdAt: any, updatedAt: any } };


export const LoginDocument = gql`
    mutation Login($input: LoginInput!) {
  login(input: $input) {
    message
    success
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout {
    success
  }
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const CurrentUserDocument = gql`
    query CurrentUser {
  me {
    id
    email
    username
    firstName
    userType
    lastName
    profilePicture
    firstLoggedInAt
    onboardingStep
    onboardingCompletedAt
    emailMessageNotifications
    emailFeedbackNotifications
    emailJoinNotifications
    emailLocationNotifications
    emailPaymentNotifications
    emailReportNotifications
    emailPaymentReminderNotifications
  }
}
    `;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
      }
export function useCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
        }
export function useCurrentUserSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserSuspenseQueryHookResult = ReturnType<typeof useCurrentUserSuspenseQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;
export const SignupDocument = gql`
    mutation Signup($input: SignupInput!) {
  signup(input: $input) {
    message
    success
  }
}
    `;
export type SignupMutationFn = Apollo.MutationFunction<SignupMutation, SignupMutationVariables>;

/**
 * __useSignupMutation__
 *
 * To run a mutation, you first call `useSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupMutation, { data, loading, error }] = useSignupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignupMutation(baseOptions?: Apollo.MutationHookOptions<SignupMutation, SignupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignupMutation, SignupMutationVariables>(SignupDocument, options);
      }
export type SignupMutationHookResult = ReturnType<typeof useSignupMutation>;
export type SignupMutationResult = Apollo.MutationResult<SignupMutation>;
export type SignupMutationOptions = Apollo.BaseMutationOptions<SignupMutation, SignupMutationVariables>;
export const UpdateProfileDocument = gql`
    mutation UpdateProfile($input: UpdateProfileInput!) {
  updateProfile(input: $input) {
    id
    username
    email
    mediaId
    firstName
    lastName
    userType
    profilePicture
    onboardingStep
    onboardingCompletedAt
    firstLoggedInAt
    emailMessageNotifications
    emailFeedbackNotifications
    emailJoinNotifications
    emailLocationNotifications
    emailPaymentNotifications
    emailReportNotifications
    emailPaymentReminderNotifications
  }
}
    `;
export type UpdateProfileMutationFn = Apollo.MutationFunction<UpdateProfileMutation, UpdateProfileMutationVariables>;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfileMutation, UpdateProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProfileMutation, UpdateProfileMutationVariables>(UpdateProfileDocument, options);
      }
export type UpdateProfileMutationHookResult = ReturnType<typeof useUpdateProfileMutation>;
export type UpdateProfileMutationResult = Apollo.MutationResult<UpdateProfileMutation>;
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<UpdateProfileMutation, UpdateProfileMutationVariables>;
export const VerifyDocument = gql`
    mutation Verify($input: VerifyInput!) {
  verify(input: $input) {
    token
    user {
      id
      firstLoggedInAt
      onboardingStep
      onboardingCompletedAt
      email
      username
      firstName
      lastName
      profilePicture
    }
  }
}
    `;
export type VerifyMutationFn = Apollo.MutationFunction<VerifyMutation, VerifyMutationVariables>;

/**
 * __useVerifyMutation__
 *
 * To run a mutation, you first call `useVerifyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyMutation, { data, loading, error }] = useVerifyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyMutation(baseOptions?: Apollo.MutationHookOptions<VerifyMutation, VerifyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyMutation, VerifyMutationVariables>(VerifyDocument, options);
      }
export type VerifyMutationHookResult = ReturnType<typeof useVerifyMutation>;
export type VerifyMutationResult = Apollo.MutationResult<VerifyMutation>;
export type VerifyMutationOptions = Apollo.BaseMutationOptions<VerifyMutation, VerifyMutationVariables>;
export const GenerateShortLinkDocument = gql`
    mutation GenerateShortLink($link: String!) {
  generateShortLink(link: $link)
}
    `;
export type GenerateShortLinkMutationFn = Apollo.MutationFunction<GenerateShortLinkMutation, GenerateShortLinkMutationVariables>;

/**
 * __useGenerateShortLinkMutation__
 *
 * To run a mutation, you first call `useGenerateShortLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateShortLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateShortLinkMutation, { data, loading, error }] = useGenerateShortLinkMutation({
 *   variables: {
 *      link: // value for 'link'
 *   },
 * });
 */
export function useGenerateShortLinkMutation(baseOptions?: Apollo.MutationHookOptions<GenerateShortLinkMutation, GenerateShortLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateShortLinkMutation, GenerateShortLinkMutationVariables>(GenerateShortLinkDocument, options);
      }
export type GenerateShortLinkMutationHookResult = ReturnType<typeof useGenerateShortLinkMutation>;
export type GenerateShortLinkMutationResult = Apollo.MutationResult<GenerateShortLinkMutation>;
export type GenerateShortLinkMutationOptions = Apollo.BaseMutationOptions<GenerateShortLinkMutation, GenerateShortLinkMutationVariables>;
export const GenerateTextDocument = gql`
    mutation GenerateText($input: String!) {
  generateText(input: $input)
}
    `;
export type GenerateTextMutationFn = Apollo.MutationFunction<GenerateTextMutation, GenerateTextMutationVariables>;

/**
 * __useGenerateTextMutation__
 *
 * To run a mutation, you first call `useGenerateTextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateTextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateTextMutation, { data, loading, error }] = useGenerateTextMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateTextMutation(baseOptions?: Apollo.MutationHookOptions<GenerateTextMutation, GenerateTextMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateTextMutation, GenerateTextMutationVariables>(GenerateTextDocument, options);
      }
export type GenerateTextMutationHookResult = ReturnType<typeof useGenerateTextMutation>;
export type GenerateTextMutationResult = Apollo.MutationResult<GenerateTextMutation>;
export type GenerateTextMutationOptions = Apollo.BaseMutationOptions<GenerateTextMutation, GenerateTextMutationVariables>;
export const GetGenerationsTodayDocument = gql`
    query GetGenerationsToday {
  generationsToday {
    remaining
    limit
    resetAt
  }
}
    `;

/**
 * __useGetGenerationsTodayQuery__
 *
 * To run a query within a React component, call `useGetGenerationsTodayQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGenerationsTodayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGenerationsTodayQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGenerationsTodayQuery(baseOptions?: Apollo.QueryHookOptions<GetGenerationsTodayQuery, GetGenerationsTodayQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGenerationsTodayQuery, GetGenerationsTodayQueryVariables>(GetGenerationsTodayDocument, options);
      }
export function useGetGenerationsTodayLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGenerationsTodayQuery, GetGenerationsTodayQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGenerationsTodayQuery, GetGenerationsTodayQueryVariables>(GetGenerationsTodayDocument, options);
        }
export function useGetGenerationsTodaySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetGenerationsTodayQuery, GetGenerationsTodayQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetGenerationsTodayQuery, GetGenerationsTodayQueryVariables>(GetGenerationsTodayDocument, options);
        }
export type GetGenerationsTodayQueryHookResult = ReturnType<typeof useGetGenerationsTodayQuery>;
export type GetGenerationsTodayLazyQueryHookResult = ReturnType<typeof useGetGenerationsTodayLazyQuery>;
export type GetGenerationsTodaySuspenseQueryHookResult = ReturnType<typeof useGetGenerationsTodaySuspenseQuery>;
export type GetGenerationsTodayQueryResult = Apollo.QueryResult<GetGenerationsTodayQuery, GetGenerationsTodayQueryVariables>;
export const GetMediaDocument = gql`
    query GetMedia($id: ID!) {
  media(id: $id)
}
    `;

/**
 * __useGetMediaQuery__
 *
 * To run a query within a React component, call `useGetMediaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMediaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMediaQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMediaQuery(baseOptions: Apollo.QueryHookOptions<GetMediaQuery, GetMediaQueryVariables> & ({ variables: GetMediaQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMediaQuery, GetMediaQueryVariables>(GetMediaDocument, options);
      }
export function useGetMediaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMediaQuery, GetMediaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMediaQuery, GetMediaQueryVariables>(GetMediaDocument, options);
        }
export function useGetMediaSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetMediaQuery, GetMediaQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMediaQuery, GetMediaQueryVariables>(GetMediaDocument, options);
        }
export type GetMediaQueryHookResult = ReturnType<typeof useGetMediaQuery>;
export type GetMediaLazyQueryHookResult = ReturnType<typeof useGetMediaLazyQuery>;
export type GetMediaSuspenseQueryHookResult = ReturnType<typeof useGetMediaSuspenseQuery>;
export type GetMediaQueryResult = Apollo.QueryResult<GetMediaQuery, GetMediaQueryVariables>;
export const GetSubmissionsDocument = gql`
    query GetSubmissions($tourId: ID!) {
  submissions(tourId: $tourId) {
    media {
      id
      url
      data
    }
    participantEmail
    locationName
  }
}
    `;

/**
 * __useGetSubmissionsQuery__
 *
 * To run a query within a React component, call `useGetSubmissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubmissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubmissionsQuery({
 *   variables: {
 *      tourId: // value for 'tourId'
 *   },
 * });
 */
export function useGetSubmissionsQuery(baseOptions: Apollo.QueryHookOptions<GetSubmissionsQuery, GetSubmissionsQueryVariables> & ({ variables: GetSubmissionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubmissionsQuery, GetSubmissionsQueryVariables>(GetSubmissionsDocument, options);
      }
export function useGetSubmissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubmissionsQuery, GetSubmissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubmissionsQuery, GetSubmissionsQueryVariables>(GetSubmissionsDocument, options);
        }
export function useGetSubmissionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetSubmissionsQuery, GetSubmissionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSubmissionsQuery, GetSubmissionsQueryVariables>(GetSubmissionsDocument, options);
        }
export type GetSubmissionsQueryHookResult = ReturnType<typeof useGetSubmissionsQuery>;
export type GetSubmissionsLazyQueryHookResult = ReturnType<typeof useGetSubmissionsLazyQuery>;
export type GetSubmissionsSuspenseQueryHookResult = ReturnType<typeof useGetSubmissionsSuspenseQuery>;
export type GetSubmissionsQueryResult = Apollo.QueryResult<GetSubmissionsQuery, GetSubmissionsQueryVariables>;
export const UploadMediaDocument = gql`
    mutation UploadMedia($files: [UploadMediaInput!]!) {
  uploadMedia(files: $files) {
    id
  }
}
    `;
export type UploadMediaMutationFn = Apollo.MutationFunction<UploadMediaMutation, UploadMediaMutationVariables>;

/**
 * __useUploadMediaMutation__
 *
 * To run a mutation, you first call `useUploadMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadMediaMutation, { data, loading, error }] = useUploadMediaMutation({
 *   variables: {
 *      files: // value for 'files'
 *   },
 * });
 */
export function useUploadMediaMutation(baseOptions?: Apollo.MutationHookOptions<UploadMediaMutation, UploadMediaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadMediaMutation, UploadMediaMutationVariables>(UploadMediaDocument, options);
      }
export type UploadMediaMutationHookResult = ReturnType<typeof useUploadMediaMutation>;
export type UploadMediaMutationResult = Apollo.MutationResult<UploadMediaMutation>;
export type UploadMediaMutationOptions = Apollo.BaseMutationOptions<UploadMediaMutation, UploadMediaMutationVariables>;
export const GetMessagesThreadDocument = gql`
    query GetMessagesThread($participantId: ID!, $code: String, $limit: Int = 20, $offset: Int = 0) {
  message(
    participantId: $participantId
    code: $code
    limit: $limit
    offset: $offset
  ) {
    id
    content
    createdAt
    updatedAt
    tourTitle
    participantId
    participantEmail
    participantName
    from
    to
    creatorId
    creatorEmail
    creatorUsername
    tourId
  }
}
    `;

/**
 * __useGetMessagesThreadQuery__
 *
 * To run a query within a React component, call `useGetMessagesThreadQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMessagesThreadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMessagesThreadQuery({
 *   variables: {
 *      participantId: // value for 'participantId'
 *      code: // value for 'code'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetMessagesThreadQuery(baseOptions: Apollo.QueryHookOptions<GetMessagesThreadQuery, GetMessagesThreadQueryVariables> & ({ variables: GetMessagesThreadQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMessagesThreadQuery, GetMessagesThreadQueryVariables>(GetMessagesThreadDocument, options);
      }
export function useGetMessagesThreadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMessagesThreadQuery, GetMessagesThreadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMessagesThreadQuery, GetMessagesThreadQueryVariables>(GetMessagesThreadDocument, options);
        }
export function useGetMessagesThreadSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetMessagesThreadQuery, GetMessagesThreadQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMessagesThreadQuery, GetMessagesThreadQueryVariables>(GetMessagesThreadDocument, options);
        }
export type GetMessagesThreadQueryHookResult = ReturnType<typeof useGetMessagesThreadQuery>;
export type GetMessagesThreadLazyQueryHookResult = ReturnType<typeof useGetMessagesThreadLazyQuery>;
export type GetMessagesThreadSuspenseQueryHookResult = ReturnType<typeof useGetMessagesThreadSuspenseQuery>;
export type GetMessagesThreadQueryResult = Apollo.QueryResult<GetMessagesThreadQuery, GetMessagesThreadQueryVariables>;
export const GetUnreadMessagesDocument = gql`
    query GetUnreadMessages($tourId: ID!, $participantId: ID!) {
  unreadMessages(tourId: $tourId, participantId: $participantId) {
    id
    content
    createdAt
    updatedAt
    tourTitle
    tourId
    participantId
    participantEmail
    participantName
    from
    to
    creatorId
    creatorEmail
    creatorUsername
  }
}
    `;

/**
 * __useGetUnreadMessagesQuery__
 *
 * To run a query within a React component, call `useGetUnreadMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnreadMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnreadMessagesQuery({
 *   variables: {
 *      tourId: // value for 'tourId'
 *      participantId: // value for 'participantId'
 *   },
 * });
 */
export function useGetUnreadMessagesQuery(baseOptions: Apollo.QueryHookOptions<GetUnreadMessagesQuery, GetUnreadMessagesQueryVariables> & ({ variables: GetUnreadMessagesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnreadMessagesQuery, GetUnreadMessagesQueryVariables>(GetUnreadMessagesDocument, options);
      }
export function useGetUnreadMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnreadMessagesQuery, GetUnreadMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnreadMessagesQuery, GetUnreadMessagesQueryVariables>(GetUnreadMessagesDocument, options);
        }
export function useGetUnreadMessagesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetUnreadMessagesQuery, GetUnreadMessagesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUnreadMessagesQuery, GetUnreadMessagesQueryVariables>(GetUnreadMessagesDocument, options);
        }
export type GetUnreadMessagesQueryHookResult = ReturnType<typeof useGetUnreadMessagesQuery>;
export type GetUnreadMessagesLazyQueryHookResult = ReturnType<typeof useGetUnreadMessagesLazyQuery>;
export type GetUnreadMessagesSuspenseQueryHookResult = ReturnType<typeof useGetUnreadMessagesSuspenseQuery>;
export type GetUnreadMessagesQueryResult = Apollo.QueryResult<GetUnreadMessagesQuery, GetUnreadMessagesQueryVariables>;
export const MarkMessagesAsReadDocument = gql`
    mutation MarkMessagesAsRead($tourId: ID!, $participantId: ID!) {
  markMessagesAsRead(tourId: $tourId, participantId: $participantId) {
    success
  }
}
    `;
export type MarkMessagesAsReadMutationFn = Apollo.MutationFunction<MarkMessagesAsReadMutation, MarkMessagesAsReadMutationVariables>;

/**
 * __useMarkMessagesAsReadMutation__
 *
 * To run a mutation, you first call `useMarkMessagesAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkMessagesAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markMessagesAsReadMutation, { data, loading, error }] = useMarkMessagesAsReadMutation({
 *   variables: {
 *      tourId: // value for 'tourId'
 *      participantId: // value for 'participantId'
 *   },
 * });
 */
export function useMarkMessagesAsReadMutation(baseOptions?: Apollo.MutationHookOptions<MarkMessagesAsReadMutation, MarkMessagesAsReadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkMessagesAsReadMutation, MarkMessagesAsReadMutationVariables>(MarkMessagesAsReadDocument, options);
      }
export type MarkMessagesAsReadMutationHookResult = ReturnType<typeof useMarkMessagesAsReadMutation>;
export type MarkMessagesAsReadMutationResult = Apollo.MutationResult<MarkMessagesAsReadMutation>;
export type MarkMessagesAsReadMutationOptions = Apollo.BaseMutationOptions<MarkMessagesAsReadMutation, MarkMessagesAsReadMutationVariables>;
export const MessageDocument = gql`
    subscription Message {
  messageAdded {
    id
    content
    from
    createdAt
    participantName
    creatorUsername
  }
}
    `;

/**
 * __useMessageSubscription__
 *
 * To run a query within a React component, call `useMessageSubscription` and pass it any options that fit your needs.
 * When your component renders, `useMessageSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageSubscription({
 *   variables: {
 *   },
 * });
 */
export function useMessageSubscription(baseOptions?: Apollo.SubscriptionHookOptions<MessageSubscription, MessageSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<MessageSubscription, MessageSubscriptionVariables>(MessageDocument, options);
      }
export type MessageSubscriptionHookResult = ReturnType<typeof useMessageSubscription>;
export type MessageSubscriptionResult = Apollo.SubscriptionResult<MessageSubscription>;
export const SendMessageToAllParticipantsDocument = gql`
    mutation SendMessageToAllParticipants($tourId: ID!, $content: String!) {
  sendMessage(tourId: $tourId, content: $content) {
    success
  }
}
    `;
export type SendMessageToAllParticipantsMutationFn = Apollo.MutationFunction<SendMessageToAllParticipantsMutation, SendMessageToAllParticipantsMutationVariables>;

/**
 * __useSendMessageToAllParticipantsMutation__
 *
 * To run a mutation, you first call `useSendMessageToAllParticipantsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMessageToAllParticipantsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMessageToAllParticipantsMutation, { data, loading, error }] = useSendMessageToAllParticipantsMutation({
 *   variables: {
 *      tourId: // value for 'tourId'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useSendMessageToAllParticipantsMutation(baseOptions?: Apollo.MutationHookOptions<SendMessageToAllParticipantsMutation, SendMessageToAllParticipantsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendMessageToAllParticipantsMutation, SendMessageToAllParticipantsMutationVariables>(SendMessageToAllParticipantsDocument, options);
      }
export type SendMessageToAllParticipantsMutationHookResult = ReturnType<typeof useSendMessageToAllParticipantsMutation>;
export type SendMessageToAllParticipantsMutationResult = Apollo.MutationResult<SendMessageToAllParticipantsMutation>;
export type SendMessageToAllParticipantsMutationOptions = Apollo.BaseMutationOptions<SendMessageToAllParticipantsMutation, SendMessageToAllParticipantsMutationVariables>;
export const SendMessageToParticipantDocument = gql`
    mutation SendMessageToParticipant($tourId: ID!, $content: String!, $participantEmail: String!) {
  sendMessageToParticipant(
    tourId: $tourId
    content: $content
    participantEmail: $participantEmail
  ) {
    success
  }
}
    `;
export type SendMessageToParticipantMutationFn = Apollo.MutationFunction<SendMessageToParticipantMutation, SendMessageToParticipantMutationVariables>;

/**
 * __useSendMessageToParticipantMutation__
 *
 * To run a mutation, you first call `useSendMessageToParticipantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMessageToParticipantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMessageToParticipantMutation, { data, loading, error }] = useSendMessageToParticipantMutation({
 *   variables: {
 *      tourId: // value for 'tourId'
 *      content: // value for 'content'
 *      participantEmail: // value for 'participantEmail'
 *   },
 * });
 */
export function useSendMessageToParticipantMutation(baseOptions?: Apollo.MutationHookOptions<SendMessageToParticipantMutation, SendMessageToParticipantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendMessageToParticipantMutation, SendMessageToParticipantMutationVariables>(SendMessageToParticipantDocument, options);
      }
export type SendMessageToParticipantMutationHookResult = ReturnType<typeof useSendMessageToParticipantMutation>;
export type SendMessageToParticipantMutationResult = Apollo.MutationResult<SendMessageToParticipantMutation>;
export type SendMessageToParticipantMutationOptions = Apollo.BaseMutationOptions<SendMessageToParticipantMutation, SendMessageToParticipantMutationVariables>;
export const EventAddedDocument = gql`
    subscription EventAdded {
  eventAdded {
    id
    type
    content
    createdAt
    updatedAt
    participant {
      id
      email
      firstName
      lastName
      tour {
        id
        title
      }
    }
  }
}
    `;

/**
 * __useEventAddedSubscription__
 *
 * To run a query within a React component, call `useEventAddedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useEventAddedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventAddedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useEventAddedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<EventAddedSubscription, EventAddedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<EventAddedSubscription, EventAddedSubscriptionVariables>(EventAddedDocument, options);
      }
export type EventAddedSubscriptionHookResult = ReturnType<typeof useEventAddedSubscription>;
export type EventAddedSubscriptionResult = Apollo.SubscriptionResult<EventAddedSubscription>;
export const GetEventsDocument = gql`
    query GetEvents($filter: EventFilter, $limit: Int!, $offset: Int!) {
  events(filter: $filter, limit: $limit, offset: $offset) {
    id
    type
    content
    tourTitle
    tourId
    createdAt
    updatedAt
    participant {
      id
      email
      firstName
      lastName
      tour {
        id
        title
      }
    }
  }
}
    `;

/**
 * __useGetEventsQuery__
 *
 * To run a query within a React component, call `useGetEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetEventsQuery(baseOptions: Apollo.QueryHookOptions<GetEventsQuery, GetEventsQueryVariables> & ({ variables: GetEventsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventsQuery, GetEventsQueryVariables>(GetEventsDocument, options);
      }
export function useGetEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventsQuery, GetEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventsQuery, GetEventsQueryVariables>(GetEventsDocument, options);
        }
export function useGetEventsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetEventsQuery, GetEventsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEventsQuery, GetEventsQueryVariables>(GetEventsDocument, options);
        }
export type GetEventsQueryHookResult = ReturnType<typeof useGetEventsQuery>;
export type GetEventsLazyQueryHookResult = ReturnType<typeof useGetEventsLazyQuery>;
export type GetEventsSuspenseQueryHookResult = ReturnType<typeof useGetEventsSuspenseQuery>;
export type GetEventsQueryResult = Apollo.QueryResult<GetEventsQuery, GetEventsQueryVariables>;
export const MarkAsReadDocument = gql`
    mutation MarkAsRead($notificationId: ID!) {
  markAsRead(notificationId: $notificationId)
}
    `;
export type MarkAsReadMutationFn = Apollo.MutationFunction<MarkAsReadMutation, MarkAsReadMutationVariables>;

/**
 * __useMarkAsReadMutation__
 *
 * To run a mutation, you first call `useMarkAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAsReadMutation, { data, loading, error }] = useMarkAsReadMutation({
 *   variables: {
 *      notificationId: // value for 'notificationId'
 *   },
 * });
 */
export function useMarkAsReadMutation(baseOptions?: Apollo.MutationHookOptions<MarkAsReadMutation, MarkAsReadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkAsReadMutation, MarkAsReadMutationVariables>(MarkAsReadDocument, options);
      }
export type MarkAsReadMutationHookResult = ReturnType<typeof useMarkAsReadMutation>;
export type MarkAsReadMutationResult = Apollo.MutationResult<MarkAsReadMutation>;
export type MarkAsReadMutationOptions = Apollo.BaseMutationOptions<MarkAsReadMutation, MarkAsReadMutationVariables>;
export const NotificationAddedDocument = gql`
    subscription NotificationAdded {
  notificationAdded {
    type
    userId
    content
    tourParticipantId
    title
    link
    isRead
    id
  }
}
    `;

/**
 * __useNotificationAddedSubscription__
 *
 * To run a query within a React component, call `useNotificationAddedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNotificationAddedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationAddedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useNotificationAddedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<NotificationAddedSubscription, NotificationAddedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<NotificationAddedSubscription, NotificationAddedSubscriptionVariables>(NotificationAddedDocument, options);
      }
export type NotificationAddedSubscriptionHookResult = ReturnType<typeof useNotificationAddedSubscription>;
export type NotificationAddedSubscriptionResult = Apollo.SubscriptionResult<NotificationAddedSubscription>;
export const NotificationsDocument = gql`
    query Notifications($limit: Int, $offset: Int) {
  notifications(limit: $limit, offset: $offset) {
    id
    type
    userId
    content
    tourParticipantId
    title
    link
    isRead
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
      }
export function useNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
        }
export function useNotificationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
        }
export type NotificationsQueryHookResult = ReturnType<typeof useNotificationsQuery>;
export type NotificationsLazyQueryHookResult = ReturnType<typeof useNotificationsLazyQuery>;
export type NotificationsSuspenseQueryHookResult = ReturnType<typeof useNotificationsSuspenseQuery>;
export type NotificationsQueryResult = Apollo.QueryResult<NotificationsQuery, NotificationsQueryVariables>;
export const UnreadNotificationsDocument = gql`
    query UnreadNotifications($limit: Int, $offset: Int) {
  notifications(notRead: true, limit: $limit, offset: $offset) {
    id
    type
    userId
    content
    tourParticipantId
    title
    link
    isRead
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useUnreadNotificationsQuery__
 *
 * To run a query within a React component, call `useUnreadNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnreadNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnreadNotificationsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useUnreadNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<UnreadNotificationsQuery, UnreadNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnreadNotificationsQuery, UnreadNotificationsQueryVariables>(UnreadNotificationsDocument, options);
      }
export function useUnreadNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnreadNotificationsQuery, UnreadNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnreadNotificationsQuery, UnreadNotificationsQueryVariables>(UnreadNotificationsDocument, options);
        }
export function useUnreadNotificationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UnreadNotificationsQuery, UnreadNotificationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UnreadNotificationsQuery, UnreadNotificationsQueryVariables>(UnreadNotificationsDocument, options);
        }
export type UnreadNotificationsQueryHookResult = ReturnType<typeof useUnreadNotificationsQuery>;
export type UnreadNotificationsLazyQueryHookResult = ReturnType<typeof useUnreadNotificationsLazyQuery>;
export type UnreadNotificationsSuspenseQueryHookResult = ReturnType<typeof useUnreadNotificationsSuspenseQuery>;
export type UnreadNotificationsQueryResult = Apollo.QueryResult<UnreadNotificationsQuery, UnreadNotificationsQueryVariables>;
export const CreateBillingPortalSessionDocument = gql`
    mutation CreateBillingPortalSession {
  createBillingPortalSession
}
    `;
export type CreateBillingPortalSessionMutationFn = Apollo.MutationFunction<CreateBillingPortalSessionMutation, CreateBillingPortalSessionMutationVariables>;

/**
 * __useCreateBillingPortalSessionMutation__
 *
 * To run a mutation, you first call `useCreateBillingPortalSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBillingPortalSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBillingPortalSessionMutation, { data, loading, error }] = useCreateBillingPortalSessionMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateBillingPortalSessionMutation(baseOptions?: Apollo.MutationHookOptions<CreateBillingPortalSessionMutation, CreateBillingPortalSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBillingPortalSessionMutation, CreateBillingPortalSessionMutationVariables>(CreateBillingPortalSessionDocument, options);
      }
export type CreateBillingPortalSessionMutationHookResult = ReturnType<typeof useCreateBillingPortalSessionMutation>;
export type CreateBillingPortalSessionMutationResult = Apollo.MutationResult<CreateBillingPortalSessionMutation>;
export type CreateBillingPortalSessionMutationOptions = Apollo.BaseMutationOptions<CreateBillingPortalSessionMutation, CreateBillingPortalSessionMutationVariables>;
export const GetConnectAccountLinkDocument = gql`
    mutation GetConnectAccountLink {
  getConnectAccountLink
}
    `;
export type GetConnectAccountLinkMutationFn = Apollo.MutationFunction<GetConnectAccountLinkMutation, GetConnectAccountLinkMutationVariables>;

/**
 * __useGetConnectAccountLinkMutation__
 *
 * To run a mutation, you first call `useGetConnectAccountLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetConnectAccountLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getConnectAccountLinkMutation, { data, loading, error }] = useGetConnectAccountLinkMutation({
 *   variables: {
 *   },
 * });
 */
export function useGetConnectAccountLinkMutation(baseOptions?: Apollo.MutationHookOptions<GetConnectAccountLinkMutation, GetConnectAccountLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetConnectAccountLinkMutation, GetConnectAccountLinkMutationVariables>(GetConnectAccountLinkDocument, options);
      }
export type GetConnectAccountLinkMutationHookResult = ReturnType<typeof useGetConnectAccountLinkMutation>;
export type GetConnectAccountLinkMutationResult = Apollo.MutationResult<GetConnectAccountLinkMutation>;
export type GetConnectAccountLinkMutationOptions = Apollo.BaseMutationOptions<GetConnectAccountLinkMutation, GetConnectAccountLinkMutationVariables>;
export const BlockParticipantDocument = gql`
    mutation BlockParticipant($tourId: ID!, $participantId: ID!) {
  blockParticipant(tourId: $tourId, participantId: $participantId)
}
    `;
export type BlockParticipantMutationFn = Apollo.MutationFunction<BlockParticipantMutation, BlockParticipantMutationVariables>;

/**
 * __useBlockParticipantMutation__
 *
 * To run a mutation, you first call `useBlockParticipantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockParticipantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockParticipantMutation, { data, loading, error }] = useBlockParticipantMutation({
 *   variables: {
 *      tourId: // value for 'tourId'
 *      participantId: // value for 'participantId'
 *   },
 * });
 */
export function useBlockParticipantMutation(baseOptions?: Apollo.MutationHookOptions<BlockParticipantMutation, BlockParticipantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BlockParticipantMutation, BlockParticipantMutationVariables>(BlockParticipantDocument, options);
      }
export type BlockParticipantMutationHookResult = ReturnType<typeof useBlockParticipantMutation>;
export type BlockParticipantMutationResult = Apollo.MutationResult<BlockParticipantMutation>;
export type BlockParticipantMutationOptions = Apollo.BaseMutationOptions<BlockParticipantMutation, BlockParticipantMutationVariables>;
export const CreateLocationDocument = gql`
    mutation CreateLocation($input: CreateLocationInput!) {
  createLocation(input: $input) {
    id
    title
    description
    address
    createdAt
    updatedAt
  }
}
    `;
export type CreateLocationMutationFn = Apollo.MutationFunction<CreateLocationMutation, CreateLocationMutationVariables>;

/**
 * __useCreateLocationMutation__
 *
 * To run a mutation, you first call `useCreateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLocationMutation, { data, loading, error }] = useCreateLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLocationMutation(baseOptions?: Apollo.MutationHookOptions<CreateLocationMutation, CreateLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLocationMutation, CreateLocationMutationVariables>(CreateLocationDocument, options);
      }
export type CreateLocationMutationHookResult = ReturnType<typeof useCreateLocationMutation>;
export type CreateLocationMutationResult = Apollo.MutationResult<CreateLocationMutation>;
export type CreateLocationMutationOptions = Apollo.BaseMutationOptions<CreateLocationMutation, CreateLocationMutationVariables>;
export const CreateTourDocument = gql`
    mutation CreateTour($input: RegisterTourInput!) {
  registerTour(input: $input) {
    id
    title
    description
    createdAt
    updatedAt
  }
}
    `;
export type CreateTourMutationFn = Apollo.MutationFunction<CreateTourMutation, CreateTourMutationVariables>;

/**
 * __useCreateTourMutation__
 *
 * To run a mutation, you first call `useCreateTourMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTourMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTourMutation, { data, loading, error }] = useCreateTourMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTourMutation(baseOptions?: Apollo.MutationHookOptions<CreateTourMutation, CreateTourMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTourMutation, CreateTourMutationVariables>(CreateTourDocument, options);
      }
export type CreateTourMutationHookResult = ReturnType<typeof useCreateTourMutation>;
export type CreateTourMutationResult = Apollo.MutationResult<CreateTourMutation>;
export type CreateTourMutationOptions = Apollo.BaseMutationOptions<CreateTourMutation, CreateTourMutationVariables>;
export const EditTourDocument = gql`
    mutation EditTour($input: RegisterTourInput!) {
  registerTour(input: $input) {
    id
    title
    description
    color
    startDate
    endDate
    address
    radius
    isPublic
    allowReviews
    allowRetries
    allowInvites
    slug
    backgroundMediaId
    logoMediaId
    createdAt
    updatedAt
  }
}
    `;
export type EditTourMutationFn = Apollo.MutationFunction<EditTourMutation, EditTourMutationVariables>;

/**
 * __useEditTourMutation__
 *
 * To run a mutation, you first call `useEditTourMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTourMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTourMutation, { data, loading, error }] = useEditTourMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditTourMutation(baseOptions?: Apollo.MutationHookOptions<EditTourMutation, EditTourMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditTourMutation, EditTourMutationVariables>(EditTourDocument, options);
      }
export type EditTourMutationHookResult = ReturnType<typeof useEditTourMutation>;
export type EditTourMutationResult = Apollo.MutationResult<EditTourMutation>;
export type EditTourMutationOptions = Apollo.BaseMutationOptions<EditTourMutation, EditTourMutationVariables>;
export const GetFeedbackDocument = gql`
    query getFeedback($tourId: ID!) {
  feedback(tourId: $tourId) {
    id
    participantEmail
    rating
    text
    createdAt
  }
}
    `;

/**
 * __useGetFeedbackQuery__
 *
 * To run a query within a React component, call `useGetFeedbackQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeedbackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeedbackQuery({
 *   variables: {
 *      tourId: // value for 'tourId'
 *   },
 * });
 */
export function useGetFeedbackQuery(baseOptions: Apollo.QueryHookOptions<GetFeedbackQuery, GetFeedbackQueryVariables> & ({ variables: GetFeedbackQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFeedbackQuery, GetFeedbackQueryVariables>(GetFeedbackDocument, options);
      }
export function useGetFeedbackLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeedbackQuery, GetFeedbackQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFeedbackQuery, GetFeedbackQueryVariables>(GetFeedbackDocument, options);
        }
export function useGetFeedbackSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetFeedbackQuery, GetFeedbackQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetFeedbackQuery, GetFeedbackQueryVariables>(GetFeedbackDocument, options);
        }
export type GetFeedbackQueryHookResult = ReturnType<typeof useGetFeedbackQuery>;
export type GetFeedbackLazyQueryHookResult = ReturnType<typeof useGetFeedbackLazyQuery>;
export type GetFeedbackSuspenseQueryHookResult = ReturnType<typeof useGetFeedbackSuspenseQuery>;
export type GetFeedbackQueryResult = Apollo.QueryResult<GetFeedbackQuery, GetFeedbackQueryVariables>;
export const GetLocationDocument = gql`
    query GetLocation($locationId: ID!) {
  location(id: $locationId) {
    id
    title
    description
    address
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetLocationQuery__
 *
 * To run a query within a React component, call `useGetLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useGetLocationQuery(baseOptions: Apollo.QueryHookOptions<GetLocationQuery, GetLocationQueryVariables> & ({ variables: GetLocationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLocationQuery, GetLocationQueryVariables>(GetLocationDocument, options);
      }
export function useGetLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLocationQuery, GetLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLocationQuery, GetLocationQueryVariables>(GetLocationDocument, options);
        }
export function useGetLocationSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetLocationQuery, GetLocationQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetLocationQuery, GetLocationQueryVariables>(GetLocationDocument, options);
        }
export type GetLocationQueryHookResult = ReturnType<typeof useGetLocationQuery>;
export type GetLocationLazyQueryHookResult = ReturnType<typeof useGetLocationLazyQuery>;
export type GetLocationSuspenseQueryHookResult = ReturnType<typeof useGetLocationSuspenseQuery>;
export type GetLocationQueryResult = Apollo.QueryResult<GetLocationQuery, GetLocationQueryVariables>;
export const GetLocationsDocument = gql`
    query GetLocations {
  locations {
    id
    title
    description
    address
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetLocationsQuery__
 *
 * To run a query within a React component, call `useGetLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLocationsQuery(baseOptions?: Apollo.QueryHookOptions<GetLocationsQuery, GetLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLocationsQuery, GetLocationsQueryVariables>(GetLocationsDocument, options);
      }
export function useGetLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLocationsQuery, GetLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLocationsQuery, GetLocationsQueryVariables>(GetLocationsDocument, options);
        }
export function useGetLocationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetLocationsQuery, GetLocationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetLocationsQuery, GetLocationsQueryVariables>(GetLocationsDocument, options);
        }
export type GetLocationsQueryHookResult = ReturnType<typeof useGetLocationsQuery>;
export type GetLocationsLazyQueryHookResult = ReturnType<typeof useGetLocationsLazyQuery>;
export type GetLocationsSuspenseQueryHookResult = ReturnType<typeof useGetLocationsSuspenseQuery>;
export type GetLocationsQueryResult = Apollo.QueryResult<GetLocationsQuery, GetLocationsQueryVariables>;
export const GetParticipantLocationsDocument = gql`
    query GetParticipantLocations($tourId: ID!, $participantEmail: String) {
  locations(tourId: $tourId, participantEmail: $participantEmail) {
    id
    title
    description
    address
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetParticipantLocationsQuery__
 *
 * To run a query within a React component, call `useGetParticipantLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParticipantLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParticipantLocationsQuery({
 *   variables: {
 *      tourId: // value for 'tourId'
 *      participantEmail: // value for 'participantEmail'
 *   },
 * });
 */
export function useGetParticipantLocationsQuery(baseOptions: Apollo.QueryHookOptions<GetParticipantLocationsQuery, GetParticipantLocationsQueryVariables> & ({ variables: GetParticipantLocationsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetParticipantLocationsQuery, GetParticipantLocationsQueryVariables>(GetParticipantLocationsDocument, options);
      }
export function useGetParticipantLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetParticipantLocationsQuery, GetParticipantLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetParticipantLocationsQuery, GetParticipantLocationsQueryVariables>(GetParticipantLocationsDocument, options);
        }
export function useGetParticipantLocationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetParticipantLocationsQuery, GetParticipantLocationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetParticipantLocationsQuery, GetParticipantLocationsQueryVariables>(GetParticipantLocationsDocument, options);
        }
export type GetParticipantLocationsQueryHookResult = ReturnType<typeof useGetParticipantLocationsQuery>;
export type GetParticipantLocationsLazyQueryHookResult = ReturnType<typeof useGetParticipantLocationsLazyQuery>;
export type GetParticipantLocationsSuspenseQueryHookResult = ReturnType<typeof useGetParticipantLocationsSuspenseQuery>;
export type GetParticipantLocationsQueryResult = Apollo.QueryResult<GetParticipantLocationsQuery, GetParticipantLocationsQueryVariables>;
export const GetParticipantsDocument = gql`
    query GetParticipants($input: ParticipantsInput!) {
  participants(input: $input) {
    id
    email
    firstName
    lastName
  }
}
    `;

/**
 * __useGetParticipantsQuery__
 *
 * To run a query within a React component, call `useGetParticipantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParticipantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParticipantsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetParticipantsQuery(baseOptions: Apollo.QueryHookOptions<GetParticipantsQuery, GetParticipantsQueryVariables> & ({ variables: GetParticipantsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetParticipantsQuery, GetParticipantsQueryVariables>(GetParticipantsDocument, options);
      }
export function useGetParticipantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetParticipantsQuery, GetParticipantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetParticipantsQuery, GetParticipantsQueryVariables>(GetParticipantsDocument, options);
        }
export function useGetParticipantsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetParticipantsQuery, GetParticipantsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetParticipantsQuery, GetParticipantsQueryVariables>(GetParticipantsDocument, options);
        }
export type GetParticipantsQueryHookResult = ReturnType<typeof useGetParticipantsQuery>;
export type GetParticipantsLazyQueryHookResult = ReturnType<typeof useGetParticipantsLazyQuery>;
export type GetParticipantsSuspenseQueryHookResult = ReturnType<typeof useGetParticipantsSuspenseQuery>;
export type GetParticipantsQueryResult = Apollo.QueryResult<GetParticipantsQuery, GetParticipantsQueryVariables>;
export const GetPublicTourDetailsDocument = gql`
    query GetPublicTourDetails($tourId: ID!) {
  publicTour(id: $tourId) {
    id
    title
    description
    image
    logo
    color
    paymentFee
    startDate
    endDate
    address
    isPublic
    allowInvites
    allowRetries
    allowReviews
    locations {
      id
      title
      description
      address
    }
  }
}
    `;

/**
 * __useGetPublicTourDetailsQuery__
 *
 * To run a query within a React component, call `useGetPublicTourDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicTourDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicTourDetailsQuery({
 *   variables: {
 *      tourId: // value for 'tourId'
 *   },
 * });
 */
export function useGetPublicTourDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetPublicTourDetailsQuery, GetPublicTourDetailsQueryVariables> & ({ variables: GetPublicTourDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPublicTourDetailsQuery, GetPublicTourDetailsQueryVariables>(GetPublicTourDetailsDocument, options);
      }
export function useGetPublicTourDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPublicTourDetailsQuery, GetPublicTourDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPublicTourDetailsQuery, GetPublicTourDetailsQueryVariables>(GetPublicTourDetailsDocument, options);
        }
export function useGetPublicTourDetailsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPublicTourDetailsQuery, GetPublicTourDetailsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPublicTourDetailsQuery, GetPublicTourDetailsQueryVariables>(GetPublicTourDetailsDocument, options);
        }
export type GetPublicTourDetailsQueryHookResult = ReturnType<typeof useGetPublicTourDetailsQuery>;
export type GetPublicTourDetailsLazyQueryHookResult = ReturnType<typeof useGetPublicTourDetailsLazyQuery>;
export type GetPublicTourDetailsSuspenseQueryHookResult = ReturnType<typeof useGetPublicTourDetailsSuspenseQuery>;
export type GetPublicTourDetailsQueryResult = Apollo.QueryResult<GetPublicTourDetailsQuery, GetPublicTourDetailsQueryVariables>;
export const GetStatsDocument = gql`
    query GetStats {
  stats {
    activeTours
    totalParticipants
    completionRate
  }
}
    `;

/**
 * __useGetStatsQuery__
 *
 * To run a query within a React component, call `useGetStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStatsQuery(baseOptions?: Apollo.QueryHookOptions<GetStatsQuery, GetStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStatsQuery, GetStatsQueryVariables>(GetStatsDocument, options);
      }
export function useGetStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStatsQuery, GetStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStatsQuery, GetStatsQueryVariables>(GetStatsDocument, options);
        }
export function useGetStatsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetStatsQuery, GetStatsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetStatsQuery, GetStatsQueryVariables>(GetStatsDocument, options);
        }
export type GetStatsQueryHookResult = ReturnType<typeof useGetStatsQuery>;
export type GetStatsLazyQueryHookResult = ReturnType<typeof useGetStatsLazyQuery>;
export type GetStatsSuspenseQueryHookResult = ReturnType<typeof useGetStatsSuspenseQuery>;
export type GetStatsQueryResult = Apollo.QueryResult<GetStatsQuery, GetStatsQueryVariables>;
export const GetTourDocument = gql`
    query GetTour($tourId: ID!) {
  tour(id: $tourId) {
    id
    title
    description
    image
    color
    startDate
    paymentFee
    endDate
    address
    radius
    isPublic
    allowReviews
    allowRetries
    allowInvites
    createdAt
    updatedAt
    locationCount
    participantCount
    progress
  }
}
    `;

/**
 * __useGetTourQuery__
 *
 * To run a query within a React component, call `useGetTourQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTourQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTourQuery({
 *   variables: {
 *      tourId: // value for 'tourId'
 *   },
 * });
 */
export function useGetTourQuery(baseOptions: Apollo.QueryHookOptions<GetTourQuery, GetTourQueryVariables> & ({ variables: GetTourQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTourQuery, GetTourQueryVariables>(GetTourDocument, options);
      }
export function useGetTourLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTourQuery, GetTourQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTourQuery, GetTourQueryVariables>(GetTourDocument, options);
        }
export function useGetTourSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetTourQuery, GetTourQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTourQuery, GetTourQueryVariables>(GetTourDocument, options);
        }
export type GetTourQueryHookResult = ReturnType<typeof useGetTourQuery>;
export type GetTourLazyQueryHookResult = ReturnType<typeof useGetTourLazyQuery>;
export type GetTourSuspenseQueryHookResult = ReturnType<typeof useGetTourSuspenseQuery>;
export type GetTourQueryResult = Apollo.QueryResult<GetTourQuery, GetTourQueryVariables>;
export const GetTourLocationsDocument = gql`
    query GetTourLocations($tourId: ID) {
  tourParticipantsAndLocations(tourId: $tourId) {
    id
    title
    description
    image
    color
    startDate
    endDate
    locations {
      id
      title
      address
    }
  }
}
    `;

/**
 * __useGetTourLocationsQuery__
 *
 * To run a query within a React component, call `useGetTourLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTourLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTourLocationsQuery({
 *   variables: {
 *      tourId: // value for 'tourId'
 *   },
 * });
 */
export function useGetTourLocationsQuery(baseOptions?: Apollo.QueryHookOptions<GetTourLocationsQuery, GetTourLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTourLocationsQuery, GetTourLocationsQueryVariables>(GetTourLocationsDocument, options);
      }
export function useGetTourLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTourLocationsQuery, GetTourLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTourLocationsQuery, GetTourLocationsQueryVariables>(GetTourLocationsDocument, options);
        }
export function useGetTourLocationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetTourLocationsQuery, GetTourLocationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTourLocationsQuery, GetTourLocationsQueryVariables>(GetTourLocationsDocument, options);
        }
export type GetTourLocationsQueryHookResult = ReturnType<typeof useGetTourLocationsQuery>;
export type GetTourLocationsLazyQueryHookResult = ReturnType<typeof useGetTourLocationsLazyQuery>;
export type GetTourLocationsSuspenseQueryHookResult = ReturnType<typeof useGetTourLocationsSuspenseQuery>;
export type GetTourLocationsQueryResult = Apollo.QueryResult<GetTourLocationsQuery, GetTourLocationsQueryVariables>;
export const GetToursDocument = gql`
    query GetTours($limit: Int, $offset: Int) {
  tours(limit: $limit, offset: $offset) {
    id
    title
    description
    image
    color
    startDate
    endDate
    address
    radius
    isPublic
    allowReviews
    allowRetries
    allowInvites
    createdAt
    updatedAt
    locationCount
    participantCount
    progress
  }
}
    `;

/**
 * __useGetToursQuery__
 *
 * To run a query within a React component, call `useGetToursQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetToursQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetToursQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetToursQuery(baseOptions?: Apollo.QueryHookOptions<GetToursQuery, GetToursQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetToursQuery, GetToursQueryVariables>(GetToursDocument, options);
      }
export function useGetToursLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetToursQuery, GetToursQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetToursQuery, GetToursQueryVariables>(GetToursDocument, options);
        }
export function useGetToursSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetToursQuery, GetToursQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetToursQuery, GetToursQueryVariables>(GetToursDocument, options);
        }
export type GetToursQueryHookResult = ReturnType<typeof useGetToursQuery>;
export type GetToursLazyQueryHookResult = ReturnType<typeof useGetToursLazyQuery>;
export type GetToursSuspenseQueryHookResult = ReturnType<typeof useGetToursSuspenseQuery>;
export type GetToursQueryResult = Apollo.QueryResult<GetToursQuery, GetToursQueryVariables>;
export const GetToursWithParticipantsDocument = gql`
    query GetToursWithParticipants($tourId: ID) {
  tourParticipantsAndLocations(tourId: $tourId) {
    id
    title
    description
    image
    color
    startDate
    endDate
    createdAt
    updatedAt
    isPublic
    allowReviews
    allowRetries
    allowInvites
    slug
    progress
    backgroundMediaId
    logoMediaId
    paymentFee
    locations {
      id
      title
      description
      address
      createdAt
      updatedAt
    }
    participants {
      id
      email
      firstName
      lastName
      tour {
        id
        title
        description
        image
        color
      }
      progress
      startedAt
      finishedAt
      isBlocked
      emailNotifications
    }
  }
}
    `;

/**
 * __useGetToursWithParticipantsQuery__
 *
 * To run a query within a React component, call `useGetToursWithParticipantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetToursWithParticipantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetToursWithParticipantsQuery({
 *   variables: {
 *      tourId: // value for 'tourId'
 *   },
 * });
 */
export function useGetToursWithParticipantsQuery(baseOptions?: Apollo.QueryHookOptions<GetToursWithParticipantsQuery, GetToursWithParticipantsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetToursWithParticipantsQuery, GetToursWithParticipantsQueryVariables>(GetToursWithParticipantsDocument, options);
      }
export function useGetToursWithParticipantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetToursWithParticipantsQuery, GetToursWithParticipantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetToursWithParticipantsQuery, GetToursWithParticipantsQueryVariables>(GetToursWithParticipantsDocument, options);
        }
export function useGetToursWithParticipantsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetToursWithParticipantsQuery, GetToursWithParticipantsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetToursWithParticipantsQuery, GetToursWithParticipantsQueryVariables>(GetToursWithParticipantsDocument, options);
        }
export type GetToursWithParticipantsQueryHookResult = ReturnType<typeof useGetToursWithParticipantsQuery>;
export type GetToursWithParticipantsLazyQueryHookResult = ReturnType<typeof useGetToursWithParticipantsLazyQuery>;
export type GetToursWithParticipantsSuspenseQueryHookResult = ReturnType<typeof useGetToursWithParticipantsSuspenseQuery>;
export type GetToursWithParticipantsQueryResult = Apollo.QueryResult<GetToursWithParticipantsQuery, GetToursWithParticipantsQueryVariables>;
export const GetVotesDocument = gql`
    query GetVotes($tourId: ID!) {
  votes(tourId: $tourId) {
    id
    locationName
    locationPoints
    createdAt
  }
}
    `;

/**
 * __useGetVotesQuery__
 *
 * To run a query within a React component, call `useGetVotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVotesQuery({
 *   variables: {
 *      tourId: // value for 'tourId'
 *   },
 * });
 */
export function useGetVotesQuery(baseOptions: Apollo.QueryHookOptions<GetVotesQuery, GetVotesQueryVariables> & ({ variables: GetVotesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVotesQuery, GetVotesQueryVariables>(GetVotesDocument, options);
      }
export function useGetVotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVotesQuery, GetVotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVotesQuery, GetVotesQueryVariables>(GetVotesDocument, options);
        }
export function useGetVotesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetVotesQuery, GetVotesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetVotesQuery, GetVotesQueryVariables>(GetVotesDocument, options);
        }
export type GetVotesQueryHookResult = ReturnType<typeof useGetVotesQuery>;
export type GetVotesLazyQueryHookResult = ReturnType<typeof useGetVotesLazyQuery>;
export type GetVotesSuspenseQueryHookResult = ReturnType<typeof useGetVotesSuspenseQuery>;
export type GetVotesQueryResult = Apollo.QueryResult<GetVotesQuery, GetVotesQueryVariables>;
export const RemoveTourDocument = gql`
    mutation RemoveTour($tourId: ID!) {
  removeTour(tourId: $tourId)
}
    `;
export type RemoveTourMutationFn = Apollo.MutationFunction<RemoveTourMutation, RemoveTourMutationVariables>;

/**
 * __useRemoveTourMutation__
 *
 * To run a mutation, you first call `useRemoveTourMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTourMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTourMutation, { data, loading, error }] = useRemoveTourMutation({
 *   variables: {
 *      tourId: // value for 'tourId'
 *   },
 * });
 */
export function useRemoveTourMutation(baseOptions?: Apollo.MutationHookOptions<RemoveTourMutation, RemoveTourMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveTourMutation, RemoveTourMutationVariables>(RemoveTourDocument, options);
      }
export type RemoveTourMutationHookResult = ReturnType<typeof useRemoveTourMutation>;
export type RemoveTourMutationResult = Apollo.MutationResult<RemoveTourMutation>;
export type RemoveTourMutationOptions = Apollo.BaseMutationOptions<RemoveTourMutation, RemoveTourMutationVariables>;
export const SendParticipantInviteDocument = gql`
    mutation SendParticipantInvite($tourId: ID!, $emails: [String!]!) {
  sendParticipantInvite(tourId: $tourId, emails: $emails) {
    id
    email
  }
}
    `;
export type SendParticipantInviteMutationFn = Apollo.MutationFunction<SendParticipantInviteMutation, SendParticipantInviteMutationVariables>;

/**
 * __useSendParticipantInviteMutation__
 *
 * To run a mutation, you first call `useSendParticipantInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendParticipantInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendParticipantInviteMutation, { data, loading, error }] = useSendParticipantInviteMutation({
 *   variables: {
 *      tourId: // value for 'tourId'
 *      emails: // value for 'emails'
 *   },
 * });
 */
export function useSendParticipantInviteMutation(baseOptions?: Apollo.MutationHookOptions<SendParticipantInviteMutation, SendParticipantInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendParticipantInviteMutation, SendParticipantInviteMutationVariables>(SendParticipantInviteDocument, options);
      }
export type SendParticipantInviteMutationHookResult = ReturnType<typeof useSendParticipantInviteMutation>;
export type SendParticipantInviteMutationResult = Apollo.MutationResult<SendParticipantInviteMutation>;
export type SendParticipantInviteMutationOptions = Apollo.BaseMutationOptions<SendParticipantInviteMutation, SendParticipantInviteMutationVariables>;
export const UpdateLocationDocument = gql`
    mutation UpdateLocation($id: ID!, $input: UpdateLocationInput!) {
  updateLocation(id: $id, input: $input) {
    id
    title
    description
    address
    createdAt
    updatedAt
  }
}
    `;
export type UpdateLocationMutationFn = Apollo.MutationFunction<UpdateLocationMutation, UpdateLocationMutationVariables>;

/**
 * __useUpdateLocationMutation__
 *
 * To run a mutation, you first call `useUpdateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationMutation, { data, loading, error }] = useUpdateLocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLocationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLocationMutation, UpdateLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLocationMutation, UpdateLocationMutationVariables>(UpdateLocationDocument, options);
      }
export type UpdateLocationMutationHookResult = ReturnType<typeof useUpdateLocationMutation>;
export type UpdateLocationMutationResult = Apollo.MutationResult<UpdateLocationMutation>;
export type UpdateLocationMutationOptions = Apollo.BaseMutationOptions<UpdateLocationMutation, UpdateLocationMutationVariables>;