import { Box, Flex, Text, IconButton } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";

type BannerProps = {
  isVisible: boolean;
  onClose: () => void;
  title: string;
  message: string;
  type?: "success" | "error" | "info";
};

const Banner = ({ isVisible, onClose, title, message, type = "success" }: BannerProps) => {
  const getBannerColors = () => {
    switch (type) {
      case "success":
        return {
          bg: "white",
          hoverBg: "white"
        };
      case "error":
        return {
          bg: "red.500",
          hoverBg: "red.600"
        };
      case "info":
        return {
          bg: "blue.500",
          hoverBg: "blue.600"
        };
      default:
        return {
          bg: "purple.500",
          hoverBg: "purple.600"
        };
    }
  };

  const { bg, hoverBg } = getBannerColors();

  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      right="0"
      zIndex="banner"
      transform={isVisible ? "translateY(0)" : "translateY(-100%)"}
      transition="transform 0.3s ease-in-out"
    >
      <Flex
        bg={bg}
        color="white"
        p={4}
        alignItems="center"
        justifyContent="center"
        boxShadow="md"
        borderBottomRadius="md"
        position="relative"
      >
        <Box textAlign="center">
          <Text fontWeight="bold" color="purple.600">{title}</Text>
          <Text fontSize="sm" color="purple.600">{message}</Text>
        </Box>
        <IconButton
          icon={<CloseIcon />}
          aria-label="Close notification"
          size="sm"
          variant="ghost"
          color="purple.600"
          onClick={onClose}
          _hover={{ bg: hoverBg }}
          position="absolute"
          right={2}
          top="50%"
          transform="translateY(-50%)"
        />
      </Flex>
    </Box>
  );
};

export default Banner;
