import React from 'react';
import {
  Box, Flex, Text, Button, VStack, IconButton,
  useColorModeValue, useDisclosure, Alert, AlertIcon,
  useBreakpointValue, Icon
} from "@chakra-ui/react";
import { ChevronRight, MapPin } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Location, GetLocationsDocument } from '../graphql/generated';
import CreateLocationModal from './location-drawer';
import CardSkeleton from './shared/card-skeleton';
import { useLoading } from "../context/loading";
import CardHeader from "./shared/card-header";
import CardWrapper from "./shared/card-wrapper";
import { useSmartPolling } from '../hooks/useSmartPolling';
import { motion } from 'framer-motion';


interface ShowLocationsProps {
  userType: string;
}

const LocationListItem = ({ location, onClick }: { location: Location; onClick: () => void }) => {
  const hoverBgColor = useColorModeValue('gray.50', 'gray.700');
  const textColor = useColorModeValue('purple.800', 'white');
  const secondaryTextColor = useColorModeValue('gray.600', 'gray.300');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Box
      as={motion.div}
      whileHover={{ x: 4 }}
      borderBottomWidth="1px"
      borderColor={borderColor}
      cursor="pointer"
      onClick={onClick}
      _hover={{ bg: hoverBgColor }}
      role="group"
    >
      <Flex
        p={4}
        align="center"
        justify="space-between"
        gap={4}
      >
        <VStack flex="1" align="start" spacing={2}>
          <Text fontWeight="bold" fontSize="md" color={textColor}>
            {location.title}
          </Text>
          <Flex align="center" gap={2}>
            <Icon as={MapPin} color="purple.500" boxSize={4} />
            <Text fontSize="sm" color={secondaryTextColor} noOfLines={1}>
              {location.address}
            </Text>
          </Flex>
        </VStack>

        <IconButton
          aria-label="View location details"
          icon={<ChevronRight size={18} />}
          variant="ghost"
          colorScheme="purple"
          size="sm"
          opacity={isMobile ? 1 : 0}
          _groupHover={{ opacity: 1 }}
          transition="all 0.2s"
          flexShrink={0}
        />
      </Flex>
    </Box>
  );
};

const ShowLocations: React.FC<ShowLocationsProps> = ({ userType }) => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const bgColor = useColorModeValue('white', 'gray.800');

  const maxLocations = userType === 'FREE' ? 10 : userType === 'BASIC' ? 50 : userType === 'PLUS' ? 100 : Infinity;

  const { data, loading, error } = useSmartPolling(GetLocationsDocument, {}, {
    baseInterval: 5000,
  });

  const { isLoading } = useLoading();

  if (isLoading || loading) return <CardSkeleton rows={3} />;
  if (error) return (
    <Alert status="error">
      <AlertIcon />
      <VStack align="start" spacing={1}>
        <Text fontWeight="bold">Oops! Something went wrong</Text>
        <Text>We couldn't load the locations. Please try again later.</Text>
      </VStack>
    </Alert>
  );

  const allLocations = data?.locations || [];
  const displayedLocations = allLocations.slice(0, 3);
  const hasMoreLocations = allLocations.length > 3;

  return (
    <CardWrapper>
      <CardHeader title="Locations">
        <Flex gap={2} direction={isMobile ? "column" : "row"} width={isMobile ? "full" : "auto"}>
          {hasMoreLocations && (
            <Button
              size="sm"
              variant="outline"
              colorScheme="purple"
              onClick={() => navigate("/home/places")}
              width={isMobile ? "full" : "auto"}
            >
              View All
            </Button>
          )}
          {allLocations.length < maxLocations && (
            <Button
              size="sm"
              colorScheme="purple"
              onClick={onOpen}
              width={isMobile ? "full" : "auto"}
            >
              Add New Location
            </Button>
          )}
        </Flex>
      </CardHeader>

      <Box bg={bgColor} borderRadius="xl" overflow="hidden">
        {displayedLocations.map((location: Location, index: number) => (
          <LocationListItem
            key={location.id}
            location={location}
            onClick={() => navigate(`/home/locations/${location.id}`)}
          />
        ))}

        {displayedLocations.length === 0 && (
          <Box textAlign="center" py={8}>
            <Text color="gray.500">No locations registered yet.</Text>
          </Box>
        )}
      </Box>

      <CreateLocationModal isOpen={isOpen} onClose={onClose} />
    </CardWrapper>
  );
};

export default ShowLocations;
