import React, { useState, useMemo, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Box, Heading, Text, Flex, Button, VStack, HStack, Tabs, TabList, TabPanels, Tab, TabPanel,
    Progress, useColorModeValue, Alert, AlertIcon, AlertTitle, AlertDescription,
    Table, Thead, Tbody, Tr, Th, Td, Badge, SimpleGrid, Card, CardHeader, CardBody,
    Skeleton, Icon, Tooltip, useMediaQuery, Input, useToast, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter,
    ButtonGroup,
    Link,
    Container,
    Menu, MenuButton, MenuList, MenuItem, IconButton, useBreakpointValue,
    Textarea,
    Image,
    AccordionIcon,
    AccordionButton,
    AccordionPanel,
    AccordionItem,
    Accordion,
    useDisclosure,
    Center,
} from '@chakra-ui/react';
import { useBlockParticipantMutation, useGetToursWithParticipantsQuery, useSendParticipantInviteMutation, useGetEventsQuery, EventType, useGetVotesQuery, GetToursWithParticipantsDocument, useGetSubmissionsQuery, useSendMessageToParticipantMutation, useSendMessageToAllParticipantsMutation, useGetFeedbackQuery, useGetUnreadMessagesQuery, useMarkMessagesAsReadMutation, useGenerateShortLinkMutation, useGetParticipantsQuery, TourIncludeOrExclude } from '../../graphql/generated';
import MapModal from '../../components/map-modal';
import { FaCalendarCheck, FaCalendarTimes, FaEye, FaStar as Star } from 'react-icons/fa';
import { MdMenu } from 'react-icons/md';
import TourDrawer from '../../components/tour-drawer';
import TourDetailSkeleton from '../../components/shared/tour-detail-skeleton';
import { Trophy, Target, Users, CheckCircle, Activity, MapIcon, UserPlus2Icon as UserPlusIcon, AwardIcon, MailIcon, UploadIcon as Upload, MapPinIcon, MessageSquare, FileText, LockIcon, UnlockIcon, ChevronLeftIcon, SettingsIcon, CalendarIcon, EditIcon, X as CloseIcon, CheckIcon, Download, X, MessageCircle, ChevronUpIcon, ChevronDownIcon, LinkIcon } from "lucide-react";
import { Avatar, Tag, TagLabel, TagLeftIcon } from '@chakra-ui/react';
import { formatDistance, format } from 'date-fns';
import { useAuth } from "../../context/auth";
import { useApolloClient } from '@apollo/client';
import { Image as ChakraImage } from '@chakra-ui/react';
import { chakraComponents, CreatableSelect } from "chakra-react-select";
import env from '../../env';

type ParticipantOption = {
  label: string;
  value: string;
  isExisting?: boolean;
};

const ErrorAlert = ({ message, ...rest }: { message: string }) => (
    <Alert
        status="error"
        variant="subtle"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        py={8}
        px={6}
        borderRadius="xl"
        bg={useColorModeValue("white", "gray.800")}
        borderWidth="1px"
        borderColor={useColorModeValue("red.100", "red.700")}
        shadow="sm"
        maxW="xl"
        mx="auto"
        role="alert"
        {...rest}
    >
        <Box
            mb={4}
            p={2}
            borderRadius="full"
            bg={useColorModeValue("red.50", "red.900")}
        >
            <AlertIcon 
                boxSize="6"
                color={useColorModeValue("red.500", "red.200")}
            />
        </Box>
        <AlertTitle 
            mb={2}
            fontSize={{ base: "lg", md: "xl" }}
            fontWeight="semibold"
            color={useColorModeValue("black", "white")}
        >
            Oops! Something went wrong
        </AlertTitle>
        <AlertDescription
            maxWidth="md"
            fontSize={{ base: "sm", md: "md" }}
            color={useColorModeValue("gray.800", "gray.300")}
            whiteSpace="pre-wrap"
        >
            {message}
        </AlertDescription>
    </Alert>
);

const TabContent = ({ children, title }: { children: React.ReactNode; title: string }) => {
    const bgColor = useColorModeValue('white', 'gray.800');
    const borderColor = useColorModeValue('purple.200', 'purple.700');

    return (
        <Box
            borderRadius="xl"
            p={{ base: 3, md: 6 }}
            shadow="lg"
            bg={bgColor}
            borderWidth="1px"
            borderColor={borderColor}
            mt={4}
        >
            {children}
        </Box>
    );
};

const ParticipantRow = ({ participant, totalLocations, onRemove, onMessage, tourId }: { participant: any; totalLocations: number; onRemove: (id: string) => void; onMessage: (email: string) => void; tourId: string }) => {
    const textColor = useColorModeValue('purple.800', 'purple.100');
    const stopsLeft = totalLocations - Math.round((participant.progress / 100) * totalLocations);
    const navigate = useNavigate();
    const [markMessagesAsRead] = useMarkMessagesAsReadMutation();

    const handleChatOpen = async () => {
        try {
            await markMessagesAsRead({
                variables: {
                    tourId: tourId,
                    participantId: participant.id
                }
            });

            navigate(`/home/messages/${tourId}/${participant.id}`, {
                state: {
                    participantEmail: participant.email,
                    tourColor: "purple.500"
                }
            });
        } catch (error) {
            console.error("Error marking messages as read:", error);
        }
    };

    return (
        <Tr>
            <Td>
                <Flex align="center">
                    <Text fontWeight="medium" color={textColor}>
                        {participant.firstName ? participant.firstName : "Anonymous"} {participant.lastName ? participant.lastName : "User"}
                    </Text>
                </Flex>
            </Td>
            <Td color={textColor}>{participant.email}</Td>
            <Td>
                <Progress
                    value={participant.progress}
                    size="sm"
                    colorScheme="purple"
                    width="100%"
                    borderRadius="full"
                    hasStripe
                />
            </Td>
            <Td>
                <Badge
                    colorScheme={participant.isBlocked ? "red" : "green"}
                    borderRadius="full"
                    px={2}
                    py={1}
                >
                    {participant.isBlocked ? "Blocked" : "Active"}
                </Badge>
            </Td>
            <Td color={textColor}>{stopsLeft}</Td>
            <Td>
                <ButtonGroup size="sm" spacing={2}>
                    <Tooltip label={participant.isBlocked ? "Unblock participant" : "Block participant"}>
                        <IconButton aria-label={participant.isBlocked ? "Unblock participant" : "Block participant"}
                            colorScheme={participant.isBlocked ? "green" : "red"}
                            variant="ghost"
                            onClick={() => onRemove(participant.id)}
                            icon={participant.isBlocked ? <UnlockIcon size={18} /> : <LockIcon size={18} />} />
                    </Tooltip>
                    <Tooltip label="Send message">
                        <IconButton
                            aria-label="Send message"
                            icon={<MessageSquare size={16} />}
                            colorScheme="purple"
                            variant="ghost"
                            onClick={() => onMessage(participant.email)}
                        />
                    </Tooltip>
                    <Box position="relative">
                        <Tooltip label="Open chat">
                            <IconButton
                                aria-label="Open chat with participant"
                                icon={<MessageCircle size={16} />}
                                colorScheme="purple"
                                variant="solid"
                                onClick={handleChatOpen}
                            />
                        </Tooltip>
                        <MessageBadge participantId={participant.id} tourId={tourId} />
                    </Box>
                </ButtonGroup>
            </Td>
        </Tr>
    );
};

const MessageBadge = ({ participantId, tourId }: { participantId: string; tourId: string }) => {
    const { data } = useGetUnreadMessagesQuery({
        variables: {
            tourId,
            participantId
        },
        pollInterval: 1000
    });

    const unreadCount = data?.unreadMessages?.length || 0;

    if (unreadCount === 0) return null;

    return (
        <Badge
            position="absolute"
            top="-8px"
            right="-8px"
            colorScheme="red"
            borderRadius="full"
            minW="18px"
            height="18px"
            textAlign="center"
            fontSize="xs"
        >
            {unreadCount}
        </Badge>
    );
};

const LocationsCard = ({ location }: { location: any }) => {
    const navigate = useNavigate();
    const isMobile = useBreakpointValue({ base: true, md: false });
    const bgColor = useColorModeValue('white', 'gray.800');
    const headerBg = useColorModeValue('purple.100', 'purple.900');
    const borderColor = useColorModeValue('purple.200', 'purple.700');
    const headerColor = useColorModeValue('purple.700', 'purple.100');

    return (
        <Card
            borderRadius="xl"
            overflow="hidden"
            transition="all 0.3s"
            _hover={{ transform: 'translateY(-5px)', shadow: 'lg', cursor: 'pointer' }}
            bg={bgColor}
            borderWidth="1px"
            borderColor={borderColor}
            onClick={() => navigate(`/home/locations/${location.id}`)}
        >
            <CardHeader bg={headerBg} py={isMobile ? 2 : 3}>
                <Heading size={isMobile ? "sm" : "md"} color={headerColor}>
                    {location.title}
                </Heading>
            </CardHeader>
            <CardBody p={isMobile ? 3 : 5}>
                <VStack align="start" spacing={3}>
                    <Text
                        fontSize={isMobile ? "xs" : "sm"}
                        color="purple.600"
                        noOfLines={2}
                    >
                        {location.description}
                    </Text>
                    <Link
                        href={`https://maps.google.com/?q=${encodeURIComponent(location.address)}`}
                        isExternal
                        fontSize={isMobile ? "xs" : "sm"}
                        color="purple.500"
                        display="flex"
                        alignItems="center"
                        _hover={{ color: "purple.600", textDecoration: "none" }}
                    >
                        <Icon as={MapPinIcon} boxSize={isMobile ? 3 : 4} mr={2} />
                        <Text noOfLines={1}>{location.address}</Text>
                    </Link>
                </VStack>
            </CardBody>
        </Card>
    );
};

const SettingsCard = ({ tourData }: { tourData: any }) => {
    const bgColor = useColorModeValue('white', 'gray.800');
    const headerBg = useColorModeValue('purple.100', 'purple.900');
    const headerColor = useColorModeValue('purple.700', 'purple.100');
    const borderColor = useColorModeValue('purple.200', 'purple.700');
    const { isOpen, onToggle } = useDisclosure();

    const shouldShowToggle = tourData.description.length > 150;
    const displayedDescription = isOpen
        ? tourData.description
        : tourData.description.slice(0, 150) + (shouldShowToggle ? "..." : "");

    return (
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
            <Card
                borderRadius="lg"
                overflow="hidden"
                transition="all 0.3s"
                bg={bgColor}
                borderWidth="1px"
                borderColor={borderColor}
            >
                <CardHeader bg={headerBg} py={3}>
                    <Heading size="md" color={headerColor}>Details</Heading>
                </CardHeader>
                <CardBody>
                    <VStack align="start" spacing={4}>
                        <Box position="relative" width="100%">
                            <Text
                                fontSize="sm"
                                color="purple.600"
                                whiteSpace="pre-wrap"
                                lineHeight="tall"
                            >
                                <Text as="span" fontWeight="bold">Description: </Text>
                                {displayedDescription}
                            </Text>
                            {shouldShowToggle && (
                                <Button
                                    size="xs"
                                    variant="ghost"
                                    colorScheme="purple"
                                    onClick={onToggle}
                                    mt={2}
                                    leftIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                                >
                                    {isOpen ? "Show Less" : "Show More"}
                                </Button>
                            )}
                        </Box>
                        <HStack>
                            <Icon as={CalendarIcon} color="green.400" />
                            <Text fontSize="sm" color="purple.600">
                                <Text as="span" fontWeight="bold">Start: </Text>
                                {new Date(tourData.startDate).toLocaleDateString()}
                            </Text>
                        </HStack>
                        <HStack>
                            <Icon as={CalendarIcon} color="red.400" />
                            <Text fontSize="sm" color="purple.600">
                                <Text as="span" fontWeight="bold">End: </Text>
                                {new Date(tourData.endDate).toLocaleDateString()}
                            </Text>
                        </HStack>
                    </VStack>
                </CardBody>
            </Card>
            <Card
                borderRadius="lg"
                overflow="hidden"
                transition="all 0.3s"
                bg={bgColor}
                borderWidth="1px"
                borderColor={borderColor}
            >
                <CardHeader bg={headerBg} py={3}>
                    <Heading size="md" color={headerColor}>Settings</Heading>
                </CardHeader>
                <CardBody>
                    <SimpleGrid columns={1} spacing={4}>
                        {[
                            { label: "Public", value: tourData.isPublic },
                            { label: "Allow Reviews", value: tourData.allowReviews },
                            { label: "Allow Invites", value: tourData.allowInvites }
                        ].map(({ label, value }) => (
                            <Box
                                key={label}
                                p={4}
                                borderWidth="1px"
                                borderRadius="md"
                                borderColor="purple.200"
                                bg={"gray.50"}
                                transition="all 0.2s"
                                _hover={{ shadow: 'md' }}
                            >
                                <Flex align="center">
                                    <Icon as={value ? CheckIcon : CloseIcon} color={value ? 'green.500' : 'red.500'} />
                                    <Text ml={3} fontSize="sm" color="purple.600">
                                        <Text as="span" fontWeight="bold">{label}: </Text>
                                        {value ? "Yes" : "No"}
                                    </Text>
                                </Flex>
                            </Box>
                        ))}
                    </SimpleGrid>
                </CardBody>
            </Card>
        </SimpleGrid>
    );
};

const ScheduleCard = ({ tourData }: { tourData: any }) => {
    return (
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
            <Card
                borderRadius="lg"
                overflow="hidden"
                transition="all 0.3s"
                bg={useColorModeValue("white", "gray.800")}
                borderWidth="1px"
                borderColor={useColorModeValue("purple.200", "purple.700")}
            >
                <CardHeader bg={useColorModeValue("purple.100", "purple.900")} py={3}>
                    <Heading size="md" color={useColorModeValue("purple.700", "purple.100")}>Start Date</Heading>
                </CardHeader>
                <CardBody>
                    <VStack align="start" spacing={2}>
                        <Text fontSize="xl" fontWeight="bold" color="purple.600">
                            {new Date(tourData.startDate).toLocaleDateString()}
                        </Text>
                        <HStack color="green.500">
                            <Icon as={FaCalendarCheck} />
                            <Text fontSize="sm" color="purple.600">Tour Begins</Text>
                        </HStack>
                    </VStack>
                </CardBody>
            </Card>
            <Card
                borderRadius="lg"
                overflow="hidden"
                transition="all 0.3s"
                bg={useColorModeValue("white", "gray.800")}
                borderWidth="1px"
                borderColor={useColorModeValue("purple.200", "purple.700")}
            >
                <CardHeader bg={useColorModeValue("purple.100", "purple.900")} py={3}>
                    <Heading size="md" color={useColorModeValue("purple.700", "purple.100")}>End Date</Heading>
                </CardHeader>
                <CardBody>
                    <VStack align="start" spacing={2}>
                        <Text fontSize="xl" fontWeight="bold" color="purple.600">
                            {new Date(tourData.endDate).toLocaleDateString()}
                        </Text>
                        <HStack color="red.500">
                            <Icon as={FaCalendarTimes} />
                            <Text fontSize="sm" color="purple.600">Tour Ends</Text>
                        </HStack>
                    </VStack>
                </CardBody>
            </Card>
        </SimpleGrid>
    );
};

const InviteModal = ({ isOpen, onClose, tourId }: { isOpen: boolean; onClose: () => void; tourId: string }) => {
    const [selectedOptions, setSelectedOptions] = useState<ParticipantOption[]>([]);
    const [csvFile, setCsvFile] = useState<File | null>(null);
    const toast = useToast();
    const searchIconColor = useColorModeValue('purple.400', 'purple.300');
    const borderColor = useColorModeValue('gray.200', 'gray.600');
    const bgColor = useColorModeValue('white', 'gray.800');

    const { data: participantsData } = useGetParticipantsQuery({
        variables: {
            input: {
                tourId: tourId,
                includeOrExclude: TourIncludeOrExclude.Exclude,
                includeBlocked: false
            }
        }
    });

    const [sendParticipantInvite, { loading: isSendingInvite }] = useSendParticipantInviteMutation();

    // Convert participants data to options format
    const participantOptions = useMemo(() => {
        if (!participantsData?.participants) return [];
        
        return participantsData.participants.map(participant => ({
            label: `${participant.firstName} ${participant.lastName} (${participant.email})`,
            value: participant.email,
            isExisting: true
        }));
    }, [participantsData]);

    const validateEmail = (email: string) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    const handleCreateOption = (inputValue: string) => {
        if (!validateEmail(inputValue)) {
            toast({
                title: "Invalid email",
                description: "Please enter a valid email address",
                status: "error",
                duration: 3000,
                isClosable: true
            });
            return;
        }

        const newOption = {
            label: inputValue,
            value: inputValue,
            isExisting: false
        };

        setSelectedOptions(prev => [...prev, newOption]);
    };

    const handleInvite = async () => {
        const emailList = selectedOptions.map(option => option.value);

        if (!emailList.length) {
            toast({
                title: "No recipients selected",
                description: "Please select participants or enter email addresses",
                status: "error",
                duration: 3000,
                isClosable: true
            });
            return;
        }

        try {
            const response = await sendParticipantInvite({
                variables: {
                    tourId: tourId,
                    emails: emailList
                }
            });

            if (response.data?.sendParticipantInvite) {
                toast({
                    title: "Invitations sent",
                    description: `Successfully sent ${emailList.length} invitation(s)`,
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
                setSelectedOptions([]);
                onClose();
            }
        } catch (error) {
            toast({
                title: "Error sending invitations",
                description: error instanceof Error ? error.message : "An unknown error occurred",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const customComponents = {
        DropdownIndicator: (props: any) => (
            <chakraComponents.DropdownIndicator {...props}>
                <Icon as={MailIcon} color={searchIconColor} />
            </chakraComponents.DropdownIndicator>
        )
    };

    const [isMobile] = useMediaQuery("(max-width: 48em)");

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            isCentered
            motionPreset="slideInBottom"
            size={isMobile ? "sm" : "2xl"}
        >
            <ModalOverlay backdropFilter="blur(10px)" />
            <ModalContent bg={bgColor} borderRadius="xl" mx={4}>
                <ModalHeader
                    borderBottom="1px"
                    borderColor={borderColor}
                    py={isMobile ? 3 : 4}
                    px={isMobile ? 4 : 6}
                >
                    <Flex align="center">
                        <Icon as={UserPlusIcon} mr={2} color="purple.500" />
                        <Text fontSize={isMobile ? "md" : "xl"}>Invite Participants</Text>
                    </Flex>
                </ModalHeader>
                <ModalCloseButton />

                <ModalBody py={isMobile ? 4 : 6} px={isMobile ? 4 : 6}>
                    <VStack spacing={6} align="stretch">
                        <Box>
                            <Text mb={2} fontWeight="medium" color="purple.500" fontSize={isMobile ? "sm" : "md"}>
                                Select Participants or Enter Emails
                            </Text>
                            <CreatableSelect<ParticipantOption, true>
                                isMulti
                                options={participantOptions}
                                value={selectedOptions}
                                onChange={(newValue) => setSelectedOptions(newValue as ParticipantOption[])}
                                onCreateOption={handleCreateOption}
                                placeholder="Search participants or enter email addresses..."
                                components={customComponents}
                                chakraStyles={{
                                    container: (provided) => ({
                                        ...provided,
                                        borderColor: borderColor,
                                        _hover: { borderColor: 'purple.400' },
                                        _focus: { borderColor: 'purple.500', boxShadow: '0 0 0 1px var(--chakra-colors-purple-500)' }
                                    }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        bg: state.isSelected ? 'purple.500' : state.isFocused ? 'purple.50' : 'transparent',
                                        color: state.isSelected ? 'white' : 'inherit'
                                    })
                                }}
                            />
                            <Text fontSize="xs" color="gray.500" mt={2}>
                                Type email addresses or search for existing participants
                            </Text>
                        </Box>

                        <Box>
                            <Text mb={3} fontWeight="medium" color="purple.500" fontSize={isMobile ? "sm" : "md"}>
                                Or Upload CSV File
                            </Text>
                            <Flex
                                direction="column"
                                align="center"
                                p={isMobile ? 4 : 6}
                                border="2px dashed"
                                borderColor={borderColor}
                                borderRadius="lg"
                                bg={useColorModeValue('gray.50', 'gray.700')}
                                _hover={{ borderColor: 'purple.400' }}
                                transition="all 0.2s"
                            >
                                <Input
                                    type="file"
                                    accept=".csv"
                                    onChange={(e) => {
                                        const file = e.target.files?.[0];
                                        if (file) {
                                            setCsvFile(file);
                                            toast({
                                                title: "File uploaded",
                                                description: `CSV file uploaded: ${file.name}`,
                                                status: "success",
                                                duration: 3000,
                                                isClosable: true,
                                            });
                                        }
                                    }}
                                    display="none"
                                    id="csv-upload"
                                />
                                <Button
                                    as="label"
                                    htmlFor="csv-upload"
                                    leftIcon={<Icon as={Upload} />}
                                    colorScheme="purple"
                                    variant="ghost"
                                    cursor="pointer"
                                    size={isMobile ? "md" : "lg"}
                                    mb={2}
                                >
                                    Choose CSV File
                                </Button>
                                <Text fontSize="sm" color="gray.500">
                                    Maximum file size: 5MB
                                </Text>
                            </Flex>
                            {csvFile && (
                                <Alert status="success" mt={2} borderRadius="md">
                                    <AlertIcon />
                                    <Text fontSize="sm">File uploaded: {csvFile.name}</Text>
                                </Alert>
                            )}
                        </Box>
                    </VStack>
                </ModalBody>

                <ModalFooter
                    borderTop="1px"
                    borderColor={borderColor}
                    py={isMobile ? 3 : 4}
                    px={isMobile ? 4 : 6}
                >
                    <Button
                        variant="outline"
                        mr={3}
                        onClick={onClose}
                        size={isMobile ? "md" : "lg"}
                    >
                        Cancel
                    </Button>
                    <Button
                        colorScheme="purple"
                        onClick={handleInvite}
                        isLoading={isSendingInvite}
                        loadingText="Sending..."
                        isDisabled={(!selectedOptions.length && !csvFile) || isSendingInvite}
                        size={isMobile ? "md" : "lg"}
                        leftIcon={<Icon as={MailIcon} />}
                    >
                        {isMobile ? "Send" : "Send Invitations"}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

const LeaderboardCard = ({ tourData }: { tourData: any }) => {
    const textColor = useColorModeValue('purple.800', 'purple.100');
    const secondaryTextColor = useColorModeValue('purple.600', 'purple.300');
    const borderColor = useColorModeValue('gray.200', 'gray.700');
    const hoverBgColor = useColorModeValue('gray.50', 'gray.900')
    const navigate = useNavigate();

    const { data: votesData, loading: votesLoading } = useGetVotesQuery({
        variables: {
            tourId: tourData.id
        },
        pollInterval: 1_000
    });

    // Calculate vote counts for each location
    const locationVotes = useMemo(() => {
        if (!votesData?.votes) return [];

        const voteCounts = tourData.locations.map((location: any) => {
            const voteCount = votesData.votes.filter(
                vote => vote.locationName === location.title
            ).length;

            return {
                ...location,
                voteCount,
                percentage: tourData.participants.length > 0
                    ? Math.round((voteCount / tourData.participants.length) * 100)
                    : 0
            };
        });

        return voteCounts.sort((a: any, b: any) => b.voteCount - a.voteCount);
    }, [votesData?.votes, tourData.locations, tourData.participants.length]);

    const [isMobile] = useMediaQuery("(max-width: 48em)");

    if (votesLoading) {
        return (
            <VStack spacing={4} align="stretch">
                {[1, 2, 3].map((i) => (
                    <Skeleton key={i} height="60px" borderRadius="lg" />
                ))}
            </VStack>
        );
    }

    if (locationVotes.length === 0 || locationVotes.every((location: any) => location.voteCount === 0)) {
        return (
            <Box
                textAlign="center"
                py={8}
                px={4}
            >
                <Icon as={AwardIcon} boxSize={8} color="purple.400" mb={3} />

                <Text color="purple.600" fontSize="lg" mb={2} fontWeight="medium">
                    No Votes Yet
                </Text>
                <Text color="purple.400">
                    Rankings will appear here once participants start voting for their favorite locations
                </Text>
            </Box>
        );
    }

    return (
        <Box overflowX="auto">
            {locationVotes.length > 0 ? (
                <Table variant="simple" size={isMobile ? "sm" : "md"}>
                    <Thead>
                        <Tr>
                            <Th color={secondaryTextColor}>Rank</Th>
                            <Th color={secondaryTextColor}>Location</Th>
                            <Th color={secondaryTextColor} isNumeric>Votes</Th>
                            <Th color={secondaryTextColor}>Popularity</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {locationVotes.map((location: any, index: number) => (
                            <Tr
                                key={location.id}
                                _hover={{ bg: hoverBgColor }}
                                cursor="pointer"
                                onClick={() => navigate(`/home/locations/${location.id}`)}
                            >
                                <Td>
                                    <HStack spacing={2}>
                                        {index < 3 && (
                                            <Icon
                                                as={Trophy}
                                                color={
                                                    index === 0 ? "yellow.400" :
                                                        index === 1 ? "gray.600" :
                                                            "orange.400"
                                                }
                                                boxSize={4}
                                            />
                                        )}
                                        <Text fontWeight={"bold"}>
                                            #{index + 1}
                                        </Text>
                                    </HStack>
                                </Td>
                                <Td>
                                    <VStack align="start" spacing={0}>
                                        <Text fontWeight="bold">{location.title}</Text>
                                        <Text fontSize="xs" color="purple.400">{location.address}</Text>
                                    </VStack>
                                </Td>
                                <Td isNumeric>
                                    <Badge
                                        colorScheme="purple"
                                        variant="subtle"
                                        borderRadius="full"
                                    >
                                        {location.voteCount}
                                    </Badge>
                                </Td>
                                <Td>
                                    <Box w="100%">
                                        <Progress
                                            value={location.percentage}
                                            size="sm"
                                            colorScheme="purple"
                                            borderRadius="full"
                                            hasStripe
                                        />
                                        <Text fontSize="xs" color="purple.400" mt={1}>
                                            {location.percentage}% of participants
                                        </Text>
                                    </Box>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            ) : (
                <Box
                    textAlign="center"
                    py={8}
                    px={4}
                    borderWidth="1px"
                    borderStyle="dashed"
                    borderColor={borderColor}
                    borderRadius="lg"
                >
                    <Icon as={AwardIcon} boxSize={8} color="purple.400" mb={3} />
                    <Text color={textColor} fontSize="lg" mb={2}>
                        No Votes Yet
                    </Text>
                    <Text color="purple.400" fontSize="sm">
                        Rankings will appear here once participants start voting for their favorite locations
                    </Text>
                </Box>
            )}
        </Box>
    );
};

const MessageModal = ({ isOpen, onClose, recipientEmail, isBulk = false, tourId }: {
    isOpen: boolean;
    onClose: () => void;
    recipientEmail?: string;
    isBulk?: boolean;
    tourId: string;
}) => {
    const [message, setMessage] = useState("");
    const toast = useToast();
    const [sendMessage] = useSendMessageToParticipantMutation();
    const [sendBulkMessage] = useSendMessageToAllParticipantsMutation();
    const [isMobile] = useMediaQuery("(max-width: 48em)");

    const bgColor = useColorModeValue('white', 'gray.800');
    const borderColor = useColorModeValue('gray.200', 'gray.600');

    const handleSend = async () => {
        if (!message.trim()) {
            toast({
                title: "Invalid input",
                description: "Please enter a message.",
                status: "error",
                duration: 3000,
                isClosable: true
            });
            return;
        }

        try {
            if (isBulk) {
                await sendBulkMessage({
                    variables: {
                        tourId,
                        content: message.trim()
                    }
                });
            } else {
                await sendMessage({
                    variables: {
                        tourId,
                        participantEmail: recipientEmail!,
                        content: message.trim()
                    }
                });
            }

            toast({
                title: "Message sent",
                description: isBulk ? "Message sent to all participants" : "Message sent successfully",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            setMessage("");
            onClose();
        } catch (error) {
            toast({
                title: "Error sending message",
                description: error instanceof Error ? error.message : "An unknown error occurred",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            isCentered
            motionPreset="slideInBottom"
            size={isMobile ? "sm" : "2xl"}
        >
            <ModalOverlay
                bg="blackAlpha.300"
                backdropFilter="blur(10px)"
            />
            <ModalContent
                bg={bgColor}
                borderRadius="xl"
                boxShadow="xl"
                mx={isMobile ? 4 : "auto"}
            >
                <ModalHeader
                    borderBottom="1px"
                    borderColor={borderColor}
                    py={isMobile ? 3 : 4}
                    px={isMobile ? 4 : 6}
                >
                    <Flex align="center">
                        <Icon as={MessageSquare} mr={2} color="purple.500" />
                        <Text fontSize={isMobile ? "md" : "xl"}>
                            {isBulk ? "Message All Participants" : "Send Message"}
                        </Text>
                    </Flex>
                </ModalHeader>
                <ModalCloseButton />

                <ModalBody py={isMobile ? 4 : 6} px={isMobile ? 4 : 6}>
                    <VStack spacing={6} align="stretch">
                        <Box>
                            <Text mb={2} fontWeight="medium" color="purple.500" fontSize={isMobile ? "sm" : "md"}>
                                {isBulk ? "Send a message to all participants" : `Send a message to ${recipientEmail}`}
                            </Text>
                            <Textarea
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                placeholder="Type your message here..."
                                size={isMobile ? "md" : "lg"}
                                minH="150px"
                                borderColor={borderColor}
                                _hover={{ borderColor: 'purple.400' }}
                                _focus={{ borderColor: 'purple.500', boxShadow: '0 0 0 1px var(--chakra-colors-purple-500)' }}
                            />
                            <Text fontSize="xs" color="gray.500" mt={2}>
                                {isBulk
                                    ? "This message will be sent to all active participants of the tour"
                                    : "This message will be sent directly to the selected participant"}
                            </Text>
                        </Box>

                        {/* Optional: Add message templates or quick responses */}
                        <Box>
                            <Text mb={3} fontWeight="medium" color="purple.500" fontSize={isMobile ? "sm" : "md"}>
                                Quick Responses
                            </Text>
                            <SimpleGrid columns={2} spacing={2}>
                                <Button
                                    size="sm"
                                    variant="outline"
                                    colorScheme="purple"
                                    onClick={() => setMessage("Thank you for participating in our food tour!")}
                                >
                                    Thank You
                                </Button>
                                <Button
                                    size="sm"
                                    variant="outline"
                                    colorScheme="purple"
                                    onClick={() => setMessage("How are you enjoying the food tour so far?")}
                                >
                                    Check-in
                                </Button>
                                <Button
                                    size="sm"
                                    variant="outline"
                                    colorScheme="purple"
                                    onClick={() => setMessage("Don't forget to complete the remaining stops to complete the tour and vote for your favorite location!")}
                                >
                                    Reminder
                                </Button>
                                <Button
                                    size="sm"
                                    variant="outline"
                                    colorScheme="purple"
                                    onClick={() => setMessage("Please let us know if you need any assistance.")}
                                >
                                    Support
                                </Button>
                            </SimpleGrid>
                        </Box>
                    </VStack>
                </ModalBody>

                <ModalFooter
                    borderTop="1px"
                    borderColor={borderColor}
                    py={isMobile ? 3 : 4}
                    px={isMobile ? 4 : 6}
                >
                    <Button
                        variant="outline"
                        mr={3}
                        onClick={onClose}
                        size={isMobile ? "md" : "lg"}
                    >
                        Cancel
                    </Button>
                    <Button
                        colorScheme="purple"
                        onClick={handleSend}
                        isDisabled={!message.trim()}
                        size={isMobile ? "md" : "lg"}
                        leftIcon={<Icon as={MessageSquare} />}
                    >
                        {isMobile ? "Send" : "Send Message"}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

const ParticipantsTab = ({ tourData, onRemoveParticipant }: { tourData: any; onRemoveParticipant: (id: string) => void }) => {
    const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
    const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
    const [selectedParticipant, setSelectedParticipant] = useState<string | null>(null);
    const navigate = useNavigate();
    const [isBulkMessage, setIsBulkMessage] = useState(false);
    const [markMessagesAsRead] = useMarkMessagesAsReadMutation();

    const isTourEnded = useMemo(() => {
        const currentDate = new Date();
        const endDate = new Date(tourData.endDate);
        return currentDate > endDate;
    }, [tourData.endDate]);

    const handleMessageClick = (email: string) => {
        setSelectedParticipant(email);
        setIsBulkMessage(false);
        setIsMessageModalOpen(true);
    };

    const handleBulkMessage = () => {
        setSelectedParticipant(null);
        setIsBulkMessage(true);
        setIsMessageModalOpen(true);
    };
    const secondaryTextColor = useColorModeValue('purple.600', 'purple.300');
    const totalLocations = tourData.locations.length;
    const stopsLeft = (progress: number) => {
        return totalLocations - Math.round((progress / 100) * totalLocations);
    };
    const [isMobile] = useMediaQuery("(max-width: 48em)");

    const handleChatOpen = async (participant: any) => {
        try {
            await markMessagesAsRead({
                variables: {
                    tourId: tourData.id,
                    participantId: participant.id
                }
            });

            navigate(`/home/messages/${tourData.id}/${participant.id}`, {
                state: {
                    participantEmail: participant.email,
                    tourColor: "purple.500"
                }
            });
        } catch (error) {
            console.error("Error marking messages as read:", error);
        }
    };

    return (
        <TabContent title="Participants">
            <VStack spacing={4} align="stretch">
                {/* Header with Invite and Message All Buttons */}
                <Flex
                    justify="space-between"
                    align="center"
                    flexDirection={{ base: "column", sm: "row" }}
                    gap={{ base: 4, sm: 0 }}
                >
                    <Text
                        color={secondaryTextColor}
                        fontSize={{ base: "sm", md: "md" }}
                        mb={{ base: 2, sm: 0 }}
                    >
                        {tourData.participants.length} Participant{tourData.participants.length !== 1 ? 's' : ''}
                    </Text>
                    <ButtonGroup
                        spacing={{ base: 0, sm: 2 }}
                        width={{ base: "100%", sm: "auto" }}
                        display="flex"
                        flexDirection={{ base: "column", sm: "row" }}
                    >
                        <Tooltip 
                            label={isTourEnded ? "Cannot invite participants to an ended tour" : ""}
                            isDisabled={!isTourEnded}
                        >
                            <Button
                                leftIcon={<Icon as={UserPlusIcon} />}
                                colorScheme="purple"
                                size={{ base: "sm", md: "md" }}
                                onClick={() => setIsInviteModalOpen(true)}
                                width={{ base: "100%", sm: "auto" }}
                                mb={{ base: 2, sm: 0 }}
                                isDisabled={isTourEnded}
                                _disabled={{
                                    opacity: 0.6,
                                    cursor: "not-allowed",
                                    _hover: { bg: "purple.500" }
                                }}
                            >
                                {isMobile ? "Invite" : "Invite Participants"}
                            </Button>
                        </Tooltip>
                        <Button
                            leftIcon={<MessageSquare size={16} />}
                            colorScheme="purple"
                            variant="outline"
                            size={{ base: "sm", md: "md" }}
                            onClick={handleBulkMessage}
                            isDisabled={tourData.participants.length === 0}
                            width={{ base: "100%", sm: "auto" }}
                            mb={{ base: 2, sm: 0 }}
                        >
                            {isMobile ? "Message All" : "Message All Participants"}
                        </Button>
                    </ButtonGroup>
                </Flex>

                {/* Existing Participants Table/Cards code remains unchanged */}
                <Box overflowX="auto">
                    {tourData.participants.length > 0 ? (
                        <>
                            <Table variant="simple" display={{ base: "none", md: "table" }}>
                                <Thead>
                                    <Tr>
                                        <Th color={secondaryTextColor}>Name</Th>
                                        <Th color={secondaryTextColor}>Email</Th>
                                        <Th color={secondaryTextColor}>Progress</Th>
                                        <Th color={secondaryTextColor}>Status</Th>
                                        <Th color={secondaryTextColor}>Stops Left</Th>
                                        <Th color={secondaryTextColor}>Actions</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {tourData.participants.map((participant: any) => (
                                        <ParticipantRow
                                            key={participant.id}
                                            participant={participant}
                                            totalLocations={totalLocations}
                                            onRemove={onRemoveParticipant}
                                            onMessage={handleMessageClick}
                                            tourId={tourData.id}
                                        />
                                    ))}
                                </Tbody>
                            </Table>

                            <VStack spacing={4} display={{ base: "flex", md: "none" }}>
                                {tourData.participants.map((participant: any) => (
                                    <Card key={participant.id} width="100%" variant="outline">
                                        <CardBody>
                                            <VStack align="stretch" spacing={3}>
                                                <HStack justify="space-between">
                                                    <VStack align="start" spacing={0}>
                                                        <Text fontWeight="bold" color="purple.500">
                                                            {participant.firstName ? participant.firstName : "Anonymous"} {participant.lastName ? participant.lastName : "User"}
                                                        </Text>
                                                        <Text fontSize="sm" color="gray.800">
                                                            {participant.email}
                                                        </Text>
                                                    </VStack>
                                                    <Badge
                                                        colorScheme={participant.isBlocked ? "red" : "green"}
                                                        borderRadius="full"
                                                    >
                                                        {participant.isBlocked ? "Blocked" : "Active"}
                                                    </Badge>
                                                </HStack>
                                                <Progress
                                                    value={participant.progress}
                                                    size="sm"
                                                    colorScheme="purple"
                                                    borderRadius="full"
                                                />
                                                <Text fontSize="sm" color="purple.600">
                                                    {stopsLeft(participant.progress)} Stop{stopsLeft(participant.progress) !== 1 ? 's' : ''} Left
                                                </Text>
                                                <ButtonGroup size="sm" spacing={2} width="100%">
                                                    <Button
                                                        flex={1}
                                                        colorScheme={participant.isBlocked ? "green" : "red"}
                                                        variant="ghost"
                                                        onClick={() => onRemoveParticipant(participant.id)}
                                                        leftIcon={participant.isBlocked ? <UnlockIcon /> : <LockIcon />}
                                                        fontSize="sm"
                                                    >
                                                        {participant.isBlocked ? "Unblock" : "Block"}
                                                    </Button>
                                                    <Box position="relative">
                                                        <Button
                                                            flex={1}
                                                            colorScheme="purple"
                                                            variant="outline"
                                                            leftIcon={<Icon as={MessageCircle} size={16} />}
                                                            onClick={() => handleChatOpen(participant)}
                                                            fontSize="sm"
                                                        >
                                                            Open Chat
                                                        </Button>
                                                        <MessageBadge participantId={participant.id} tourId={tourData.id} />
                                                    </Box>
                                                </ButtonGroup>
                                            </VStack>
                                        </CardBody>
                                    </Card>
                                ))}
                            </VStack>
                        </>
                    ) : (
                        <Box
                            textAlign="center"
                            py={8}
                            px={4}
                            borderWidth="1px"
                            borderStyle="dashed"
                            borderColor="gray.200"
                            borderRadius="lg"
                        >
                            <Icon as={Users} boxSize={8} color="purple.400" mb={3} />
                            <Text color="gray.600" fontSize="lg" mb={2}>
                                No Participants Yet
                            </Text>
                            <Text color="gray.500" fontSize="sm">
                                Invite participants to start your food tour adventure
                            </Text>
                        </Box>
                    )}
                </Box>
            </VStack>

            <InviteModal
                isOpen={isInviteModalOpen}
                onClose={() => setIsInviteModalOpen(false)}
                tourId={tourData.id}
            />
            <MessageModal
                isOpen={isMessageModalOpen}
                onClose={() => setIsMessageModalOpen(false)}
                recipientEmail={selectedParticipant || undefined}
                isBulk={isBulkMessage}
                tourId={tourData.id}
            />
        </TabContent>
    );
};

const ActivityTab = ({ tourId }: { tourId: string }) => {
    const bgColor = useColorModeValue('gray.50', 'gray.900');
    const borderColor = useColorModeValue(`purple.200`, `purple.700`);
    const { user } = useAuth();

    const { data, loading, error } = useGetEventsQuery({
        variables: {
            filter: {
                tourId: tourId
            },
            limit: 20,
            offset: 0
        },
        pollInterval: 1_000,
        fetchPolicy: 'network-only'
    });

    const getActionDetails = (type: EventType) => {
        switch (type) {
            case EventType.LocationCreated:
                return {
                    color: 'green',
                    icon: MapIcon,
                    label: 'Location Added',
                };
            case EventType.LocationUpdated:
                return {
                    color: 'orange',
                    icon: EditIcon,
                    label: 'Location Updated',
                };
            case EventType.LocationDeleted:
                return {
                    color: 'red',
                    icon: CloseIcon,
                    label: 'Location Removed',
                };
            case EventType.InviteSent:
                return {
                    color: 'purple',
                    icon: MailIcon,
                    label: 'Invite Sent',
                };
            case EventType.MessageSent:
                return {
                    color: 'blue',
                    icon: MailIcon,
                    label: 'Message Sent',
                };
            case EventType.TourCompleted:
                return {
                    color: 'green',
                    icon: Trophy,
                    label: 'Tour Completed',
                };
            case EventType.ParticipantJoined:
                return {
                    color: 'purple',
                    icon: Users,
                    label: 'New Explorer',
                };
            case EventType.ResponseSubmitted:
                return {
                    color: 'orange',
                    icon: CheckCircle,
                    label: 'Location Explored',
                };
            case EventType.VoteSubmitted:
                return {
                    color: 'blue',
                    icon: Star,
                    label: 'New Rating',
                };
            case EventType.PaymentSuccessful:
                return {
                    color: 'green',
                    icon: CheckCircle,
                    label: 'Payment Success',
                };
            case EventType.PaymentFailed:
                return {
                    color: 'red',
                    icon: CloseIcon,
                    label: 'Payment Failed',
                };
            default:
                return {
                    color: 'gray',
                    icon: Target,
                    label: 'Activity',
                };
        }
    };

    if (loading) {
        return (
            <VStack spacing={4} align="stretch">
                {[1, 2, 3].map((i) => (
                    <Skeleton key={i} height="80px" borderRadius="md" />
                ))}
            </VStack>
        );
    }

    if (error) {
        return (
            <Alert status="error" borderRadius="md">
                <AlertIcon />
                <AlertTitle>Error loading activities</AlertTitle>
                <AlertDescription>{error.message}</AlertDescription>
            </Alert>
        );
    }

    const events = data?.events || [];

    if (events.length === 0) {
        return (
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                minH="200px"
                p={8}
                textAlign="center"
            >
                <Icon as={Activity} boxSize={12} color="purple.300" mb={4} />
                <Text fontSize="xl" fontWeight="medium" color="purple.500" mb={2}>
                    No Activities Yet
                </Text>
                <Text color="purple.500">
                    Activities will appear here as you manage your tour and participants engage with it
                </Text>
            </Box>
        );
    }

    return (
        <VStack spacing={4} align="stretch">
            {events.map((event) => {
                const { color, icon: ActionIcon, label } = getActionDetails(event.type);
                const isAdminAction = !event.participant;
                const isUserAction = event.content?.startsWith('You');

                return (
                    <Box
                        key={event.id}
                        p={4}
                        borderWidth="1px"
                        borderRadius="lg"
                        borderColor={borderColor}
                        bg={bgColor}
                        transition="all 0.2s"
                        _hover={{ transform: 'translateY(-2px)', shadow: 'sm' }}
                    >
                        <Flex gap={4} align="center">
                            <Avatar
                                size="sm"
                                name={isAdminAction ? 'Admin' : event.participant?.email}
                                src={isUserAction && user?.profilePicture ?
                                    `${user.profilePicture}` :
                                    undefined}
                                bg={isAdminAction ? 'purple.500' : `${color}.500`}
                            />
                            <Box flex={1}>
                                <Flex
                                    justify="space-between"
                                    align="center"
                                    mb={2}
                                    flexWrap="wrap"
                                    gap={2}
                                >
                                    <Text fontWeight="bold" fontSize="sm">
                                        {event.content}
                                    </Text>
                                    <Tag
                                        size="sm"
                                        variant="subtle"
                                        colorScheme={color}
                                        borderRadius="full"
                                    >
                                        <TagLeftIcon boxSize="12px" as={ActionIcon} />
                                        <TagLabel>{label}</TagLabel>
                                    </Tag>
                                </Flex>
                                <Text fontSize="sm" color="purple.400">
                                    {/* show date and time */}
                                    {formatDistance(new Date(event.createdAt), new Date(), { addSuffix: true })}
                                </Text>
                            </Box>
                        </Flex>
                    </Box>
                );
            })}
        </VStack>
    );
};

const SubmissionsTab = ({ tourData }: { tourData: any }) => {
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const { data: submissionsData } = useGetSubmissionsQuery({
        variables: { tourId: tourData.id },
    });
    const [isMobile] = useMediaQuery("(max-width: 48em)");
    const bgColor = useColorModeValue('white', 'gray.800');
    const borderColor = useColorModeValue('purple.200', 'purple.700');

    // Group submissions by location
    const submissionsByLocation = useMemo(() => {
        if (!submissionsData?.submissions) return {};

        return submissionsData.submissions.reduce((acc: any, submission: any) => {
            if (!acc[submission.locationName]) {
                acc[submission.locationName] = [];
            }
            acc[submission.locationName].push(submission);
            return acc;
        }, {});
    }, [submissionsData?.submissions]);

    const handleDownload = (imageUrl: string, locationName: string) => {
        const link = document.createElement("a");
        link.href = imageUrl;
        link.download = `${locationName}-submission.jpg`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const renderSubmissionImage = (media: any, locationName: string, participantEmail: string) => {
        const imageSource = media.url || `data:${media.mimeType};base64,${media.data}`;

        return (
            <Box
                key={`${participantEmail}-${media.id}`}
                position="relative"
                minW={{ base: "250px", md: "auto" }}
                h={{ base: "250px", md: "200px" }}
                borderRadius="xl"
                overflow="hidden"
                boxShadow="md"
                transition="all 0.2s"
                _hover={{ transform: "scale(1.02)" }}
            >
                <Image
                    src={imageSource}
                    alt={`Submission by ${participantEmail}`}
                    objectFit="cover"
                    w="100%"
                    h="100%"
                    fallback={<Skeleton height="100%" />}
                    onClick={() => setSelectedImage(imageSource)}
                    cursor="pointer"
                />
                <Box
                    position="absolute"
                    top={2}
                    left={2}
                    bg="blackAlpha.700"
                    color="white"
                    fontSize="xs"
                    px={2}
                    py={1}
                    borderRadius="full"
                >
                    {participantEmail}
                </Box>
                <ButtonGroup
                    position="absolute"
                    bottom={2}
                    right={2}
                    size="sm"
                    isAttached
                    variant="solid"
                    colorScheme="purple"
                    opacity={0.9}
                    shadow="md"
                >
                    <IconButton
                        aria-label="View image"
                        icon={<FaEye />}
                        onClick={() => setSelectedImage(imageSource)}
                    />
                    <IconButton
                        aria-label="Download image"
                        icon={<Download size={16} />}
                        onClick={(e) => {
                            e.stopPropagation();
                            handleDownload(imageSource, locationName);
                        }}
                    />
                </ButtonGroup>
            </Box>
        );
    };

    const renderLocationSubmissions = (locationName: string, submissions: any[]) => {
        const totalSubmissions = submissions.reduce((sum, sub) => sum + sub.media.length, 0);

        return (
            <AccordionItem
                key={locationName}
                border="1px solid"
                borderColor={borderColor}
                borderRadius="lg"
                mb={4}
                bg={bgColor}
                overflow="hidden"
            >
                <AccordionButton
                    py={4}
                    px={6}
                    _hover={{ bg: 'purple.50' }}
                >
                    <Box flex="1">
                        <HStack justify="space-between">
                            <VStack align="start" spacing={1}>
                                <Text fontWeight="bold" fontSize="lg">{locationName}</Text>
                                <HStack spacing={2}>
                                    <Badge colorScheme="purple">
                                        {submissions.length} Participants
                                    </Badge>
                                    <Badge colorScheme="green">
                                        {totalSubmissions} Photos
                                    </Badge>
                                </HStack>
                            </VStack>
                        </HStack>
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={6} px={6}>
                    <VStack spacing={6} align="stretch">
                        {submissions.map((submission) => (
                            <Box key={submission.participantEmail}>
                                <Text
                                    fontSize="sm"
                                    fontWeight="medium"
                                    color="purple.500"
                                    mb={2}
                                >
                                    Submitted by {submission.participantEmail}
                                </Text>
                                <Box
                                    overflowX="auto"
                                    css={{
                                        '&::-webkit-scrollbar': {
                                            height: '8px',
                                        },
                                        '&::-webkit-scrollbar-track': {
                                            background: 'gray.100',
                                            borderRadius: '4px',
                                        },
                                        '&::-webkit-scrollbar-thumb': {
                                            background: 'purple.300',
                                            borderRadius: '4px',
                                        },
                                    }}
                                >
                                    <Flex
                                        gap={4}
                                        pb={4}
                                        flexWrap={isMobile ? "nowrap" : "wrap"}
                                    >
                                        {submission.media.map((media: any) =>
                                            renderSubmissionImage(
                                                media,
                                                locationName,
                                                submission.participantEmail
                                            )
                                        )}
                                    </Flex>
                                </Box>
                            </Box>
                        ))}
                    </VStack>
                </AccordionPanel>
            </AccordionItem>
        );
    };

    if (!submissionsData || Object.keys(submissionsByLocation).length === 0) {
        return (
            <Box
                textAlign="center"
                py={8}
                px={4}
            >
                <Icon as={FileText} boxSize={8} color="purple.400" mb={3} />
                <Text color="purple.600" fontSize="lg" mb={2} fontWeight="medium">
                    No Submissions Yet
                </Text>
                <Text color="purple.400">
                    Submissions will appear here once participants start submitting photos at locations
                </Text>
            </Box>

        );
    }

    return (
        <TabContent title="Submissions">
            <VStack spacing={4} align="stretch">
                <Accordion allowMultiple>
                    {Object.entries(submissionsByLocation).map(([locationName, submissions]) =>
                        renderLocationSubmissions(locationName, submissions as any[])
                    )}
                </Accordion>

                <ImageViewerModal
                    isOpen={!!selectedImage}
                    onClose={() => setSelectedImage(null)}
                    imageUrl={selectedImage || undefined}
                />
            </VStack>
        </TabContent>
    );
};

const ImageViewerModal = ({ isOpen, onClose, imageUrl, imageData }: {
    isOpen: boolean;
    onClose: () => void;
    imageUrl?: string;
    imageData?: string;
}) => {
    const handleDownload = () => {
        if (imageUrl) {
            const link = document.createElement("a");
            link.href = imageUrl;
            link.download = "submission-image.jpg";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const getImageSource = () => {
        if (imageUrl && imageUrl.length > 0) {
            return imageUrl;
        }
        if (imageData && imageData.length > 0) {
            const base64Data = typeof imageData === 'string'
                ? imageData
                : Buffer.from(imageData).toString('base64');
            return `${base64Data}`;
        }
        return null;
    };

    const imgSrc = getImageSource();
    if (!imgSrc) return null;

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="full" isCentered>
            <ModalOverlay backdropFilter="blur(10px)" />
            <ModalContent margin="4" bg="transparent" shadow="none">
                <Flex justify="flex-end" position="absolute" top={4} right={4} zIndex="modal">
                    <ButtonGroup size="sm" isAttached colorScheme="purple" shadow="lg">
                        <IconButton
                            aria-label="Download image"
                            icon={<Download size={16} />}
                            onClick={handleDownload}
                        />
                        <IconButton
                            aria-label="Close modal"
                            icon={<X size={16} />}
                            onClick={onClose}
                        />
                    </ButtonGroup>
                </Flex>
                <ModalBody display="flex" alignItems="center" justifyContent="center" p={0}>
                    <ChakraImage
                        src={imgSrc}
                        alt="Submission"
                        maxH="90vh"
                        maxW="90vw"
                        objectFit="contain"
                        fallback={<Skeleton height="90vh" width="90vw" />}
                        borderRadius="xl"
                        shadow="2xl"
                    />
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

const FeedbackTab = ({ tourId }: { tourId: string }) => {
    const { data: feedbackData, loading, error } = useGetFeedbackQuery({
        variables: { tourId: tourId },
        pollInterval: 5000,
    });

    const bgColor = useColorModeValue('white', 'gray.800');
    const borderColor = useColorModeValue('purple.200', 'purple.700');

    if (loading) {
        return (
            <VStack spacing={4} align="stretch">
                {[1, 2, 3].map((i) => (
                    <Skeleton key={i} height="120px" borderRadius="lg" />
                ))}
            </VStack>
        );
    }

    if (error) {
        return (
            <Alert status="error" borderRadius="lg">
                <AlertIcon />
                <AlertTitle>Error loading feedback</AlertTitle>
                <AlertDescription>{error.message}</AlertDescription>
            </Alert>
        );
    }

    if (!feedbackData?.feedback || feedbackData.feedback.length === 0) {
        return (
            <Box
                textAlign="center"
                py={8}
                px={4}
            >
                <Icon as={MessageSquare} boxSize={8} color="purple.400" mb={3} />
                <Text color="purple.600" fontSize="lg" mb={2} fontWeight="medium">
                    No Feedback Yet
                </Text>
                <Text color="purple.400">
                    Feedback will appear here once participants submit their reviews
                </Text>
            </Box>
        );
    }

    return (
        <VStack spacing={6} align="stretch" px={{ base: 2, md: 4 }} py={4}>
            {feedbackData.feedback.map((feedback: any) => (
                <Box
                    key={feedback.id}
                    p={{ base: 4, md: 6 }}
                    borderWidth="1px"
                    borderRadius="xl"
                    borderColor={borderColor}
                    bg={bgColor}
                    transition="all 0.2s"
                    _hover={{ transform: 'translateY(-2px)', shadow: 'md' }}
                    position="relative"
                >
                    <Flex
                        direction={{ base: "column", md: "row" }}
                        justify="space-between"
                        align={{ base: "start", md: "center" }}
                        gap={{ base: 3, md: 6 }}
                    >
                        <VStack align="start" spacing={3} flex={1}>
                            <HStack spacing={2}>
                                {[...Array(5)].map((_, index) => (
                                    <Icon
                                        key={index}
                                        as={Star}
                                        color={index < (feedback?.rating || 0) ? "yellow.300" : "gray.200"}
                                        boxSize={{ base: 5, md: 6 }}
                                        transition="color 0.2s"
                                    />
                                ))}
                            </HStack>
                            <Text
                                fontSize={{ base: "md", md: "lg" }}
                                color="purple.500"
                                lineHeight="tall"
                                fontWeight="medium"
                            >
                                {feedback.text}
                            </Text>
                        </VStack>

                        <VStack
                            align={{ base: "start", md: "end" }}
                            spacing={2}
                            minW={{ base: "auto", md: "200px" }}
                        >
                            <Text
                                fontSize={{ base: "sm", md: "md" }}
                                color="purple.500"
                                fontWeight="semibold"
                            >
                                {feedback?.participantEmail || ''}
                            </Text>
                            <Text
                                fontSize={{ base: "xs", md: "sm" }}
                                color="gray.500"
                                fontStyle="italic"
                            >
                                {formatDistance(new Date(feedback?.createdAt || ''), new Date(), { addSuffix: true })}
                            </Text>
                        </VStack>
                    </Flex>
                </Box>
            ))}
        </VStack>
    );
};

const ActionMenu = ({ 
    onCopyLink, 
    onEdit, 
    onPreview, 
    onMap, 
    onMessage, 
    hasParticipants 
  }: {
    onCopyLink: () => void;
    onEdit: () => void;
    onPreview: () => void;
    onMap: () => void;
    onMessage: () => void;
    hasParticipants: boolean;
  }) => {
    return (
      <Menu>
        <MenuButton
          as={IconButton}
          icon={<MdMenu />}
          variant="outline"
          colorScheme="purple"
          aria-label="Tour actions"
          size="lg"
          borderRadius="xl"
          _hover={{
            transform: "translateY(-1px)",
            shadow: "md",
            bg: "purple.50"
          }}
        />
        <MenuList borderRadius="xl" shadow="xl" p={2}>
          <MenuItem
            icon={<LinkIcon size={16} />}
            onClick={onCopyLink}
            borderRadius="lg"
            p={3}
          >
            Share
          </MenuItem>
          <MenuItem
            icon={<EditIcon size={16} />}
            onClick={onEdit}
            borderRadius="lg"
            p={3}
          >
            Edit
          </MenuItem>
          <MenuItem
            icon={<FaEye size={16} />}
            onClick={onPreview}
            borderRadius="lg"
            p={3}
          >
            Preview
          </MenuItem>
          <MenuItem
            icon={<MapPinIcon size={16} />}
            onClick={onMap}
            borderRadius="lg"
            p={3}
            hidden={true}
          >
            View Map
          </MenuItem>
          <MenuItem
            icon={<MessageSquare size={16} />}
            onClick={onMessage}
            isDisabled={!hasParticipants}
            borderRadius="lg"
            p={3}
          >
            Message All
          </MenuItem>
        </MenuList>
      </Menu>
    );
  };

const PreviewAsModal = ({ isOpen, onClose, participants, onSelect }: {
    isOpen: boolean;
    onClose: () => void;
    participants: any[];
    onSelect: (participantId: string) => void;
}) => {
    const bgColor = useColorModeValue("white", "gray.800");
    const borderColor = useColorModeValue("purple.200", "purple.700");

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay backdropFilter="blur(10px)" />
            <ModalContent bg={bgColor} borderRadius="xl" mx={4}>
                <ModalHeader borderBottom="1px" borderColor={borderColor}>
                    <Flex align="center" gap={2}>
                        <Icon as={FaEye} color="purple.500" />
                        <Text>Preview As Participant</Text>
                    </Flex>
                </ModalHeader>
                <ModalCloseButton />

                <ModalBody py={6}>
                    {participants.length > 0 ? (
                        <VStack spacing={3} align="stretch">
                            {participants.map((participant) => (
                                <Button
                                    key={participant.id}
                                    onClick={() => onSelect(participant.id)}
                                    variant="outline"
                                    colorScheme="purple"
                                    justifyContent="start"
                                    height="auto"
                                    py={3}
                                    px={4}
                                    whiteSpace="normal"
                                    textAlign="left"
                                >
                                    <VStack align="start" spacing={1}>
                                        <Text fontWeight="bold">
                                            {participant.firstName ? `${participant.firstName} ${participant.lastName}` : "Anonymous User"}
                                        </Text>
                                        <Text fontSize="sm" color="gray.600">
                                            {participant.email}
                                        </Text>
                                        <Badge colorScheme="purple">
                                            Progress: {participant.progress}%
                                        </Badge>
                                    </VStack>
                                </Button>
                            ))}
                        </VStack>
                    ) : (
                        <VStack spacing={4} py={4}>
                            <Icon as={Users} boxSize={8} color="purple.400" />
                            <Text color="purple.600" textAlign="center">
                                No participants available for preview. Add participants to preview their experience.
                            </Text>
                        </VStack>
                    )}
                </ModalBody>

                <ModalFooter borderTop="1px" borderColor={borderColor}>
                    <Button onClick={onClose}>Cancel</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

const TourDetailPage: React.FC = () => {
    const navigate = useNavigate();
    const { tourId } = useParams<{ tourId: string }>();
    const [isMapModalOpen, setIsMapModalOpen] = useState(false);
    const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
    const client = useApolloClient();

    const { data, loading, error, refetch } = useGetToursWithParticipantsQuery({
        variables: { tourId: tourId || '' },
        skip: !tourId,
        pollInterval: 1_000,
        fetchPolicy: 'cache-and-network',
    });

    const tourData = data?.tourParticipantsAndLocations.find((tour: any) => tour.id === tourId);

    const updateParticipantData = useCallback((participantId: string, isBlocked: boolean) => {
        const cachedData = client.readQuery({
            query: GetToursWithParticipantsDocument,
            variables: { tourId: tourId || '' },
        });

        if (cachedData) {
            const updatedTours = cachedData.tourParticipantsAndLocations.map((tour: any) => {
                if (tour.id === tourId) {
                    return {
                        ...tour,
                        participants: tour.participants.map((participant: any) =>
                            participant.id === participantId ? { ...participant, isBlocked } : participant
                        ),
                    };
                }
                return tour;
            });

            client.writeQuery({
                query: GetToursWithParticipantsDocument,
                variables: { tourId: tourId || '' },
                data: { tourParticipantsAndLocations: updatedTours },
            });
        }
    }, [client, tourId]);

    const handleRemoveParticipant = async (participantId: string) => {
        try {
            await removeParticipant({
                variables: {
                    tourId: `${tourData?.id}`,
                    participantId,
                },
            });
            updateParticipantData(participantId, true);
            setBannerProps({
                type: "success",
                title: "Participants Updated",
                message: "Participants have been updated successfully",
            });
            setShowBanner(true);
            refetch();
        } catch (error) {
            setBannerProps({
                type: "error",
                title: "Error",
                message: "Failed to update participants. Please try again.",
            });
            setShowBanner(true);
        }
    };

    const bgColor = useColorModeValue('gray.50', 'gray.900');
    const textColor = useColorModeValue('purple.800', 'purple.100');
    const secondaryTextColor = useColorModeValue('purple.600', 'purple.300');

    const [isMobile] = useMediaQuery("(max-width: 48em)");
    const [activeTab, setActiveTab] = useState(0);
    const toast = useToast();

    const formatDateForInput = (dateString: string) => {
        const date = new Date(dateString);
        return format(date, "yyyy-MM-dd'T'HH:mm");
    };

    const [removeParticipant] = useBlockParticipantMutation();

    const [, setShowBanner] = useState(false);
    const [, setBannerProps] = useState<{
        type: "success" | "error" | "info";
        title: string;
        message: string;
    }>({
        type: "success",
        title: "",
        message: ""
    });

    const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);

    const handleMessageAll = () => {
        setIsMessageModalOpen(true);
    };

    const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);

    const handlePreview = (participantId: string) => {
        setIsPreviewModalOpen(false);
        localStorage.setItem('participantId', participantId);
        window.location.href = `${env.REACT_APP_EXPLORER_URL}/${tourData?.id}/${participantId}`;
    };

    const tabData = [
        { icon: UserPlusIcon, label: "Participants", content: <ParticipantsTab tourData={tourData} onRemoveParticipant={handleRemoveParticipant} /> },
        {
            icon: MapIcon, label: "Locations", content: (
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                    {tourData?.locations && tourData.locations.length > 0 ? (
                        tourData?.locations.map((location: any) => (
                            <LocationsCard key={location.id} location={location} />
                        ))
                    ) : (
                        <Text color={textColor} fontSize="lg" textAlign="center" py={4}>
                            No locations have been added to this tour yet.
                        </Text>
                    )}
                </SimpleGrid>
            )
        },
        { icon: SettingsIcon, label: "Settings", content: <SettingsCard tourData={tourData} /> },
        { icon: CalendarIcon, label: "Schedule", content: <ScheduleCard tourData={tourData} /> },
        { icon: Trophy, label: "Leaderboard", content: <LeaderboardCard tourData={tourData} /> },
        { icon: FileText, label: "Submissions", content: <SubmissionsTab tourData={tourData} /> },
        { icon: MessageSquare, label: "Feedback", content: <FeedbackTab tourId={tourData ? tourData.id : ''} /> },
        { icon: Activity, label: "Activity", content: <ActivityTab tourId={`${tourData?.id}`} /> }
    ];

    const [generateShortLink] = useGenerateShortLinkMutation();

    const handleCopyJoinLink = async () => {
        try {
            const response = await generateShortLink({
                variables: {
                    link: `${env.REACT_APP_EXPLORER_URL}/${tourData?.id}/join`
                }
            });

            const shortLink = response.data?.generateShortLink;

            if (shortLink) {
                navigator.clipboard.writeText(shortLink);
                toast({
                    title: "Link copied",
                    description: "Short tour link has been copied to clipboard",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
            } else {
                throw new Error("Failed to generate short link");
            }
        } catch (error) {
            toast({
                title: "Error",
                description: error instanceof Error ? error.message : "An unknown error occurred",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    if (loading) return <TourDetailSkeleton />;
    if (error) return <TourDetailSkeleton />;
    if (!tourData) {
      return (
        <Center m={12}>
          <VStack spacing={8}>
            <ErrorAlert message="Tour not found. Please try again or contact support." />
          </VStack>
        </Center>
      );
    }

    const handleOpenMap = () => {
        setIsMapModalOpen(true);
    };

    const handleCloseMap = () => {
        setIsMapModalOpen(false);
    };

    return (
        <Box bg={bgColor} minH="100vh" py={isMobile ? 4 : 8}>
            <Container maxW="7xl" px={{ base: 2, md: 8 }}>
                <VStack spacing={isMobile ? 4 : 8} align="stretch">
                    {/* Header Section */}
                    <Flex
                        direction={{ base: "column", md: "row" }}
                        justify="space-between"
                        align={{ base: "start", md: "center" }}
                        gap={isMobile ? 2 : 4}
                    >
                        <VStack align="start" spacing={2}>
                            <Button
                                leftIcon={<ChevronLeftIcon />}
                                onClick={() => navigate('/home/tours')}
                                variant="ghost"
                                color="purple.500"
                                size={isMobile ? "sm" : "md"}
                                _hover={{ bg: 'purple.50' }}
                            >
                                Back to Tours
                            </Button>
                            <Heading
                                as="h1"
                                size={{ base: "lg", md: "2xl" }}
                                bgGradient="linear(to-r, purple.600, pink.500)"
                                bgClip="text"
                            >
                                {tourData.title}
                            </Heading>
                            <Text
                                color={secondaryTextColor}
                                fontSize={{ base: "sm", md: "md" }}
                            >
                                {format(new Date(tourData.startDate), 'MMM dd, yyyy')} - {format(new Date(tourData.endDate), 'MMM dd, yyyy')}
                            </Text>
                        </VStack>

                        {/* Mobile Action Menu */}
                        <ActionMenu
                            onPreview={() => setIsPreviewModalOpen(true)}
                            onMap={handleOpenMap}
                            onMessage={handleMessageAll}
                            hasParticipants={tourData.participants.length > 0}
                            onCopyLink={handleCopyJoinLink}
                            onEdit={() => setIsEditDrawerOpen(true)}
                        />
                    </Flex>

                    {/* Stats Grid */}
                    <SimpleGrid
                        columns={{ base: 1, sm: 2, md: 3 }}
                        spacing={{ base: 3, md: 6 }}
                    >
                        <StatCard
                            label="Total Participants"
                            value={tourData.participants.length}
                            icon={Users}
                            color="purple"
                        />
                        <StatCard
                            label="Locations"
                            value={tourData.locations.length}
                            icon={MapIcon}
                            color="blue"
                        />
                        <StatCard
                            label="Completion Rate"
                            value={`${tourData.progress}%`}
                            icon={Target}
                            color="green"
                        />
                    </SimpleGrid>

                    {/* Fixed Tabs Section */}
                    <Tabs
                        colorScheme="purple"
                        variant="enclosed"
                        isLazy
                        onChange={(index) => setActiveTab(index)}
                        w="100%"
                        index={activeTab}
                        display="flex"
                        flexDirection="column"
                        flex={1}
                    >
                        <TabList
                            overflowX="auto"
                            overflowY="hidden"
                            borderBottom="2px"
                            borderColor="purple.200"
                            whiteSpace="nowrap"
                            display="flex"
                            width="100%"
                            css={{
                                scrollbarWidth: 'none',
                                '&::-webkit-scrollbar': { display: 'none' },
                                msOverflowStyle: 'none',
                                '& > button': {
                                    flex: '1 1 0px'
                                }
                            }}
                        >
                            {tabData.map((tab, index) => (
                                <Tab
                                    key={index}
                                    _selected={{
                                        color: "white",
                                        bg: "purple.500",
                                        borderColor: "purple.500",
                                    }}
                                    fontSize={isMobile ? "xs" : "md"}
                                    py={isMobile ? 2 : 3}
                                    px={isMobile ? 3 : 4}
                                    borderRadius="md"
                                    mb="-2px"
                                    display="flex"
                                    alignItems="center"
                                    gap={2}
                                    flexShrink={0}
                                    _hover={{
                                        bg: "purple.50"
                                    }}
                                >
                                    <Icon
                                        as={tab.icon}
                                        boxSize={isMobile ? 4 : 5}
                                    />
                                    <Text display={{ base: "none", sm: "block" }}>
                                        {tab.label}
                                    </Text>
                                </Tab>
                            ))}
                        </TabList>

                        <TabPanels>
                            {tabData.map((tab, index) => (
                                <TabPanel key={index} p={0}>
                                    <TabContent title={tab.label}>
                                        {tab.content}
                                    </TabContent>
                                </TabPanel>
                            ))}
                        </TabPanels>
                    </Tabs>
                </VStack>
            </Container>

            {/* Modals */}
            <MapModal
                isOpen={isMapModalOpen}
                onClose={handleCloseMap}
                locations={tourData.locations}
            />
            <TourDrawer
                isOpen={isEditDrawerOpen}
                onClose={() => setIsEditDrawerOpen(false)}
                isEditing={true}
                initialData={{
                    ...tourData,
                    id: tourData.id,
                    address: "Virtual",
                    color: tourData.color || "#886ad4",
                    description: tourData.description || "",
                    image: tourData.image || "",
                    locations: tourData.locations.map((location: any) => location.id),
                    radius: 0,
                    startDate: formatDateForInput(tourData.startDate),
                    endDate: formatDateForInput(tourData.endDate),
                    backgroundMediaId: tourData.backgroundMediaId || undefined,
                    logoMediaId: tourData.logoMediaId || undefined,
                    paymentFee: tourData.paymentFee || 0
                }}
            />
            <MessageModal
                isOpen={isMessageModalOpen}
                onClose={() => setIsMessageModalOpen(false)}
                isBulk={true}
                tourId={tourData.id}
            />
            <PreviewAsModal
                isOpen={isPreviewModalOpen}
                onClose={() => setIsPreviewModalOpen(false)}
                participants={tourData?.participants || []}
                onSelect={handlePreview}
            />
        </Box>
    );
};

const StatCard = ({ label, value, icon: Icon, color }: any) => {
    const isMobile = useBreakpointValue({ base: true, md: false });
    const bgGradient = useColorModeValue(
        `linear(to-r, ${color}.50, ${color}.100)`,
        `linear(to-r, ${color}.900, ${color}.800)`
    );

    return (
        <Card
            borderRadius="xl"
            overflow="hidden"
            transition="all 0.3s"
            _hover={{ transform: 'translateY(-2px)', shadow: 'md' }}
            bg={bgGradient}
        >
            <CardBody p={isMobile ? 3 : 5}>
                <HStack spacing={isMobile ? 3 : 4}>
                    <Icon size={isMobile ? 20 : 24} color={`var(--chakra-colors-${color}-500)`} />
                    <VStack align="start" spacing={0}>
                        <Text fontSize={isMobile ? "xs" : "sm"} color={`${color}.600`}>{label}</Text>
                        <Text fontSize={isMobile ? "xl" : "2xl"} fontWeight="bold" color={`${color}.500`}>
                            {value}
                        </Text>
                    </VStack>
                </HStack>
            </CardBody>
        </Card>
    );
};

export default TourDetailPage;

