import React, { useEffect, useCallback } from 'react';
import {
    Box,
    Flex,
    Text,
    Button,
    VStack,
    Progress,
    IconButton,
    useColorModeValue,
    useDisclosure,
    useBreakpointValue,
    Divider,
} from "@chakra-ui/react";
import { ChevronRight } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { GetToursDocument } from '../graphql/generated';
import { useApolloClient } from '@apollo/client';
import CreateTourModal from './tour-drawer';
import CardSkeleton from './shared/card-skeleton';
import { useLoading } from "../context/loading";
import CardHeader from "./shared/card-header";
import CardWrapper from "./shared/card-wrapper";
import { useSmartPolling } from '../hooks/useSmartPolling';
import { motion } from 'framer-motion';

interface ActiveToursProps {
    userType: string;
}

const ActiveTours: React.FC<ActiveToursProps> = ({ userType }) => {
    const navigate = useNavigate();
    const client = useApolloClient();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isLoading } = useLoading();



    const {data, loading, error} = useSmartPolling(GetToursDocument, {limit: userType === 'FREE' ? 3 : userType === 'BASIC' ? 3 : 5}, {
        baseInterval: 5000,
    })
    const tourCount = data?.tours?.length || 0;
  const maxTours = userType === 'FREE' ? 3 : userType === 'BASIC' ? 10 : userType === 'PLUS' ? 20 : 100;

    const hoverBgColor = useColorModeValue("gray.50", "gray.700");
    const textColor = useColorModeValue("purple.800", "white");
    const secondaryTextColor = useColorModeValue("gray.600", "gray.300");

    const isMobile = useBreakpointValue({ base: true, md: false });

    const handleTourClick = (tourId: string) => {
        navigate(`/home/tours/${tourId}`);
    };

    const activeTours = data?.tours.filter((tour: any) => new Date(tour.endDate) > new Date()) || [];
    const displayedTours = activeTours.slice(0, 5);
    const hasMoreTours = activeTours.length > 5;
    const dividerColor = useColorModeValue("purple.200", "gray.700");


    const updateTourData = useCallback((cachedData: any, tourId: string, newParticipantCount: number) => {
        if (cachedData) {
            return cachedData.tours.map((tour: any) =>
                tour.id === tourId ? { ...tour, participantCount: newParticipantCount } : tour
            );
        }
        return [];
    }, []);

    useEffect(() => {
        if (data) {
            const tours = data.tours;
            tours.forEach((tour: any) => {
                const cachedData = client.readQuery({
                    query: GetToursDocument,
                    variables: { limit: 5 },
                });
                const updatedtours = updateTourData(cachedData, tour.id, tour.participantCount);
                client.writeQuery({
                    query: GetToursDocument,
                    variables: { limit: 5 },
                    data: { tours: updatedtours },
                });
            });
        }
    }, [updateTourData, data, client]);

    if (isLoading || loading) return <CardSkeleton rows={3} />;
    if (error) return <Text p={4}>Error loading tours</Text>;

    return (
        <CardWrapper>
            <CardHeader title="Active Tours">
                {hasMoreTours ? (
                    <Button
                        size="sm"
                        colorScheme="purple"
                        variant="outline"
                        onClick={() => navigate('/home/tours')}
                        width={isMobile ? "full" : "auto"}
                    >
                        View All
                    </Button>
                ) : null}
                {tourCount < maxTours ? (
                    <Button
                        size="sm"
                        colorScheme="purple"
                        onClick={onOpen}
                        width={isMobile ? "full" : "auto"}
                    >
                        Add New Tour
                    </Button>
                ) : null}
            </CardHeader>
            {displayedTours.length === 0 ? (
                <Box p={4} textAlign="center">
                    <Text color={secondaryTextColor}>No active tours.</Text>
                </Box>
            ) : (
                <VStack spacing={0} align="stretch" divider={<Divider borderColor={dividerColor} />}>
                    {displayedTours.map((tour: any) => (
                        <Flex
                            key={tour.id}
                            p={4}
                            as={motion.div}
                            whileHover={{ x: 4 }}
                            direction={isMobile ? "column" : "row"}
                            gap={isMobile ? 3 : 0}
                            _hover={{ bg: hoverBgColor }}
                            cursor="pointer"
                            onClick={() => handleTourClick(tour.id)}
                            role="group"
                        >
                            <Box flex="1">
                                <Text fontWeight="bold" fontSize="md" color={textColor}>
                                    {tour.title}
                                </Text>
                                <Text fontSize="sm" color={secondaryTextColor}>
                                    {tour.participantCount} participants
                                </Text>
                            </Box>
                            <Flex 
                                width={isMobile ? "100%" : "auto"}
                                justify={isMobile ? "space-between" : "flex-end"}
                                align="center"
                                gap={3}
                            >
                                <Flex align="center" gap={2}>
                                    <Progress
                                        value={tour.progress}
                                        size="xs"
                                        width="60px"
                                        colorScheme="purple"
                                        borderRadius="full"
                                    />
                                    <Text fontSize="xs" fontWeight="medium" color={textColor}>
                                        {tour.progress}%
                                    </Text>
                                </Flex>
                                <IconButton
                                    aria-label="View tour details"
                                    icon={<ChevronRight size={18} />}
                                    variant="ghost"
                                    colorScheme="purple"
                                    size="sm"
                                    opacity={isMobile ? 1 : 0}
                                    _groupHover={{ opacity: 1 }}
                                    transition="all 0.2s"
                                    flexShrink={0}
                                />
                            </Flex>
                        </Flex>
                    ))}
                </VStack>
            )}
            <CreateTourModal isOpen={isOpen} onClose={onClose} />
        </CardWrapper>
    );
};

export default ActiveTours;
