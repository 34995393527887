import React, { useEffect, useState } from 'react';
import {
  Box,
  VStack,
  Heading,
  Text,
  Spinner,
  Alert,
  AlertIcon,
  useColorModeValue,
  Flex,
  Icon,
  Button,
  Container,
  useBreakpointValue,
  Card,
  CardBody,
  Divider,
  AspectRatio,
  HStack,
  Link,
  Grid,
} from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';
import { useGetLocationQuery } from '../../graphql/generated';
import { MapPin, ArrowLeft } from 'lucide-react';
import { EditIcon } from '@chakra-ui/icons';
import CreateLocationDrawer from '../../components/location-drawer';
import Radar from 'radar-sdk-js';
import 'radar-sdk-js/dist/radar.css';
import env from '../../env';
import { geocodeAddress } from '../../helpers/geocoding';

const LocationDetailPage: React.FC = () => {
  const { locationId } = useParams<{ locationId: string }>();
  const navigate = useNavigate();
  const mapContainer = React.useRef<HTMLDivElement>(null);
  const map = React.useRef<any>(null);
  const [coordinates, setCoordinates] = useState<[number, number] | null>(null);
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);

  const { data, loading, error } = useGetLocationQuery({
    variables: { locationId: locationId || '' },
    skip: !locationId,
  });

  const bgColor = useColorModeValue('gray.50', 'gray.900');
  const cardBg = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('purple.200', 'purple.700');
  const textColor = useColorModeValue('purple.900', 'white');
  const secondaryTextColor = useColorModeValue('purple.600', 'purple.300');
  const isMobile = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    if (!data?.location?.address) return;

    const initializeCoordinates = async () => {
      try {
        const { lat, lon } = await geocodeAddress(data?.location?.address || '');
        setCoordinates([lon, lat]);
      } catch (error) {
        console.error('Error geocoding address:', error);
      }
    };

    initializeCoordinates();
  }, [data?.location?.address]);

  useEffect(() => {
    if (!coordinates || !mapContainer.current) return;

    Radar.initialize(env.REACT_APP_RADAR_TOKEN);

    const mapOptions = {
      container: mapContainer.current,
      style: 'radar-default-v1',
      center: coordinates,
      zoom: 12,
      showZoomControls: true,
    };

    map.current = Radar.ui.map(mapOptions);

    const marker = Radar.ui.marker({ 
      color: '#805AD5',
      popup: {
        html: `
          <div style="padding: 8px;">
            <h3 style="font-weight: bold; margin-bottom: 4px; color: #805AD5;">${data?.location?.title}</h3>
            <p style="color: #666;">${data?.location?.address}</p>
          </div>
        `
      }
    })
    .setLngLat(coordinates)
    .addTo(map.current);

    return () => {
      if (map.current) {
        map.current.remove();
      }
    };
  }, [coordinates, data?.location]);

  if (loading) return (
    <Flex justify="center" align="center" minHeight="100vh" bg={bgColor}>
      <VStack spacing={4}>
        <Spinner size="xl" color="purple.500" thickness="4px" speed="0.65s" />
        <Text color={secondaryTextColor} fontSize="lg">Loading location details...</Text>
      </VStack>
    </Flex>
  );

  if (error) return (
    <Container maxW="4xl" p={8}>
      <Alert status="error" variant="left-accent" borderRadius="lg">
        <AlertIcon />
        <VStack align="start" spacing={2}>
          <Text fontWeight="medium">Error loading location</Text>
          <Text fontSize="sm">{error.message}</Text>
        </VStack>
      </Alert>
    </Container>
  );

  const location = data?.location;
  if (!location) return null;

  const renderEditButton = () => (
    <Button
      leftIcon={<EditIcon />}
      colorScheme="purple"
      variant="outline"
      onClick={() => setIsEditDrawerOpen(true)}
      size="sm"
    >
      Edit Location
    </Button>
  );

  return (
    <Box bg={bgColor} minH="100vh" py={8}>
      <Container maxW="4xl">
        <VStack spacing={8} align="stretch">
          <Flex
            direction={isMobile ? "column" : "row"}
            justify="space-between"
            align={isMobile ? "flex-start" : "center"}
            gap={4}
          >
            <VStack align="start" spacing={2}>
              <Button
                leftIcon={<Icon as={ArrowLeft} />}
                variant="ghost"
                color="purple.500"
                onClick={() => navigate(-1)}
                size="sm"
                _hover={{ bg: 'purple.50' }}
              >
                Back
              </Button>
              <Heading 
                as="h1" 
                size={{ base: "lg", md: "xl" }} 
                bgGradient="linear(to-r, purple.600, pink.500)"
                bgClip="text"
              >
                {location.title}
              </Heading>
            </VStack>
            <HStack spacing={4}>
              {renderEditButton()}
            </HStack>
          </Flex>

          <Card 
            bg={cardBg} 
            borderWidth="1px" 
            borderColor={borderColor} 
            borderRadius="xl"
            overflow="hidden"
            boxShadow="lg"
            transition="all 0.3s"
          >
            <CardBody>
              <VStack spacing={6} align="stretch">
                <Box>
                  <Text color={secondaryTextColor} fontSize="lg" fontWeight="medium" mb={3}>
                    Description
                  </Text>
                  <Text color={textColor} fontSize="md" lineHeight="tall">
                    {location.description}
                  </Text>
                </Box>

                <Divider />

                <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={6}>
                  <VStack align="start" spacing={4}>
                    <Flex align="center" width="100%">
                      <Icon as={MapPin} color="purple.500" boxSize={5} mr={3} />
                      <Link 
                        href={`https://maps.google.com/?q=${encodeURIComponent(location.address)}`}
                        isExternal
                        color={textColor}
                        fontSize="md"
                        _hover={{ color: "purple.500", textDecoration: "none" }}
                      >
                        {location.address}
                      </Link>
                    </Flex>
                  </VStack>
                </Grid>

                <Divider />

                <Box>
                  <Text color={secondaryTextColor} fontSize="lg" fontWeight="medium" mb={3}>
                    Location
                  </Text>
                  <Box 
                    ref={mapContainer} 
                    h="400px" 
                    w="100%" 
                    borderRadius="xl" 
                    overflow="hidden"
                    border="1px solid"
                    borderColor={borderColor}
                  />
                </Box>
              </VStack>
            </CardBody>
          </Card>

          {location && (
            <CreateLocationDrawer
              isOpen={isEditDrawerOpen}
              onClose={() => setIsEditDrawerOpen(false)}
              initialData={location}
              isEditing={true}
            />
          )}
        </VStack>
      </Container>
    </Box>
  );
};

export default LocationDetailPage;
